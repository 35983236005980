import { RootState } from "@/store";
import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

interface IUseClickSoundProps {
  src?: string;
}

export const SOUND = {
  CLICK: "click.mp3",
  SHIP: "ship.mp3",
  COLLECT: "collect.mp3",
  UPGRADE: "upgrade.mp3",
  X5:"X5.mp3",
  X4:"X4.mp3"
};

const audioRefs: { [key: string]: HTMLAudioElement | null } = {};

const useClickSound = ({ src }: IUseClickSoundProps = { src: SOUND.CLICK }) => {
  const isSoundDisabled = useSelector(
    (state: RootState) => state.app.isSoundDisabled
  );

  if (!src || src == SOUND.CLICK) return ()=>{};

  const soundSrc = `/sounds/${src}?t=${Date.now()}`;

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!audioRefs[src]) {
        audioRefs[src] = new Audio(soundSrc);
      }
    }

    return () => {
      
    };
  }, [src, soundSrc]);

  const handleClick = useCallback(() => {
    if (isSoundDisabled || !audioRefs[src]) return;

    audioRefs[src]!.currentTime = 0;
    audioRefs[src]!.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  }, [isSoundDisabled, src]);

  return handleClick;
};

export default useClickSound;
