import { createSlice } from "@reduxjs/toolkit";
import { ISocketsState } from "./interface";

const initialState: ISocketsState = {
  ws: null,
  wsIsOpened: false,
};
export const socketsSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    setWs: (state, action) => {
      state.ws = action.payload;
    },
    setWsOpened: (state, action) => {
      state.wsIsOpened = action.payload;
    },
  },
});

export const { setWs, setWsOpened } = socketsSlice.actions;
export default socketsSlice.reducer;
