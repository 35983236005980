import { StaticImageData } from "next/image";
import styles from "./styles.module.scss";

type CardBonusProps = {
  children: React.ReactNode;
  image: StaticImageData;
  className?: string;
};

const CardBonus = ({ className, children, image }: CardBonusProps) => {
  return (
    <div
      className={`${styles.sale__wrapper}  ${className} `}
      style={{ backgroundImage: "url(" + image.src + ")" }}
    >
      <div className={`${styles.sale__content} `}>{children}</div>
    </div>
  );
};

export default CardBonus;
