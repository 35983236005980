import coin from "@/assets/icons/coin.png";
import pearl from "@/assets/icons/pearls.png";
import key from "@/assets/icons/key.png";

import done from "@/assets/icons/done.png";

export const formatPrize = (coins: string, pearl: string, keys: string) => {
  const zeroCoinsText = "0 золота";
  const zeroKeysText = "0 ключ";
  const zeroPearlsText = "0 жемчуга";

  if (coins == zeroCoinsText && pearl == zeroPearlsText) {
    return keys;
  }

  if (keys == zeroKeysText && pearl == zeroPearlsText) {
    return coins;
  }

  return pearl;
};

export const formatImagePrize = (prize: string, status: boolean) => {
  // if (status) return done;
  return prize.toLowerCase().includes("жемчуга") ? pearl : key;
};
