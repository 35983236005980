import { createSlice } from "@reduxjs/toolkit";
import { ITutorialSlice } from "./interfaces";
import { TEXTS, TUTORIALS_OTHERS_PAGE } from "./tutorial.constants";
import { RootState } from "..";
import { HOUSES } from "../island/island.mockData";

const initialState: ITutorialSlice = {
  step: 0,
  isActive: false,
  refs: [
    { name: HOUSES[4].house_type_name, ref: null },
    { name: HOUSES[0].house_type_name, ref: null },
    { name: "ship", ref: null },
  ],
  texts: TEXTS,
  activeTutorial: "main",
  othersTutorials: TUTORIALS_OTHERS_PAGE,
};

export const tutorialSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    setState: (state, action) => {
      state = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setActiveTutorial: (state, action) => {
      state.activeTutorial = action.payload;
    },
    setStepOther: (state, action) => {
      const name = action.payload.name;
      const currentTutorial =
        state.othersTutorials.find((tutorial) => tutorial.name == name) ||
        state.othersTutorials[0];
      currentTutorial.step = action.payload.step;
    },
    setIsActive: (state, action) => {
      state.isActive = action.payload;
    },
    increaseStep: (state) => {
      state.step += 1;
    },
    setIsActiveOther: (state, action) => {
      const name = action.payload.name;
      const currentTutorial =
        state.othersTutorials.find((tutorial) => tutorial.name == name) ||
        state.othersTutorials[0];
      currentTutorial.isActive = action.payload.isActive;
    },
    increaseStepOther: (state, action) => {
      const name = action.payload.name;
      
      const currentTutorial =
        state.othersTutorials.find((tutorial) => tutorial.name == name) ||
        state.othersTutorials[0];
      currentTutorial.step += 1;
    },
    setRef: (state, action) => {
      const name = action.payload.name;
      const currentItem = state.refs.find((item) => item.name == name);
      if (currentItem) {
        currentItem.ref = action.payload.ref;
      }
    },
    setRefOther: (state, action) => {
      const name_tutorial = action.payload.name_tutorial;
      const currentSection = state.othersTutorials.find(
        (item) => item.name == name_tutorial
      );

      const name = action.payload.name;
      const currentItem = currentSection?.refs.find(
        (item) => item.name == name
      );

      if (currentItem) {
        currentItem.ref = action.payload.ref;
      }
    },
    setZIndexOther: (state, action) => {
      const name_tutorial = action.payload.name_tutorial;
      const cardNum = action.payload.cardNum;
      const zIndex = action.payload.zIndex;
      const name = action.payload.name;
      
      const currentSection = state.othersTutorials.find(
        (item) => item.name == name_tutorial
      );
      const currentItem = currentSection?.refs.find(
        (item) => item.name == name
      );

      if (currentItem && currentItem.ref) {
        const children = currentItem.ref.children;
        if (
          children.length > 0 &&
          cardNum === 1 &&
          children[2] instanceof HTMLElement
        ) {
          children[2].style.zIndex = zIndex;
        } else if (
          children.length > 4 &&
          cardNum === 2 &&
          children[10] instanceof HTMLElement
        ) {
          children[10].style.zIndex = zIndex;
        } else if(
          children.length > 0 &&
          cardNum=='chest_1' && 
          children[0] instanceof HTMLElement
        ){
          children[0].style.zIndex = zIndex;
        }
      }
    },
    setZIndex: (state, action) => {
      const name = action.payload.name;
      const currentItem = state.refs.find((item) => item.name == name);
      if (currentItem && currentItem.ref) {
        currentItem.ref.style.zIndex = action.payload.zIndex;
      }
    },
  },
});

export const getOptionsTutorialByName = (state: RootState, name: string) => {
  if (name == "main")
    return {
      texts: state.tutorial.texts,
      step: state.tutorial.step,
      isActive: state.tutorial.isActive,
    };

  const currentTutorial =
    state.tutorial.othersTutorials.find((tutorial) => tutorial.name == name) ||
    state.tutorial.othersTutorials[0];

  return currentTutorial;
};

export const {
  setState,
  setStep,
  setIsActive,
  increaseStep,
  setIsActiveOther,
  setActiveTutorial,
  setStepOther,
  setZIndex,
  setRef,
  setRefOther,
  setZIndexOther,
  increaseStepOther,
} = tutorialSlice.actions;
export default tutorialSlice.reducer;
