import React, { useEffect, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { TOKEN } from "@/constants/cookies";
import { PIXI_ERRORS, WS_ERRORS } from "@/constants/errors";
import { usePopup } from "@/hooks/usePopUp";
import { getCookie } from "@/utils/cookies";
import {
  ACTIONS,
  getPopUpDataByNames,
} from "@/utils/getters/getPopUpDataByName";
import { createWs } from "@/utils/ws";

const wsUrl = `${process.env.WS_URL}`;

const GlobalErrorHandler: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { modal } = usePopup();
  const dispatch = useDispatch();

  useEffect(() => {
    window.onerror = function (message) {
      console.error(message);
      const formatedMessage = message.toString().split(": ")[1];
      const token = getCookie(TOKEN);
      const url = `${wsUrl}?token=${token}`;

      const SKIP = "skip";
      let actionName;
      let modalData;

      switch (formatedMessage) {
        case WS_ERRORS.NETWORK_ERROR:
          actionName = ACTIONS.ERROR;
          break;
        case WS_ERRORS.NOT_FOUND:
          actionName = ACTIONS.ERROR;
          break;
        case PIXI_ERRORS.SCALE:
          actionName = SKIP;
          break;
        default:
          actionName = SKIP;
          break;
      }

      if (actionName == SKIP) return;

      modalData = getPopUpDataByNames(actionName);

      modal(
        modalData.title,
        modalData.textButton,
        formatedMessage,
        modalData.icon,
        modalData.values
      ).then((answer: boolean) => {
        if (answer) {
          createWs(url, modal, dispatch);
          window.location.reload();
        } else {
          window.location.reload();
        }
      });
    };
  }, [dispatch, wsUrl]);

  return <>{children}</>;
};

export default GlobalErrorHandler;
