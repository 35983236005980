import { createSlice } from "@reduxjs/toolkit";
import { IPopUpState } from "./interface";
import { getShopItemImage } from "@/utils/shop";

const initialState: IPopUpState = {
  popup: null,
  item: null,
  user_popup_id: null,
  notification:null
};
export const popUpSlice = createSlice({
  name: "popUp",
  initialState,
  reducers: {
    setPopup: (state, action) => {
      state.popup = action.payload.popup;
      if (!state.popup) return;
      state.popup.reward = Object.values(state.popup.reward).filter(
        (item) => item.quantity > 0
      );
      state.item = action.payload.item;

      if (state.item) {
        state.item.image = getShopItemImage(action.payload.item.image);
      }
      state.user_popup_id = action.payload.user_popup_id;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    clearNotification: (state) => {
      state.notification = null;
    },
    clearPopup: (state) => {
      state.popup = null;
      state.user_popup_id = null;
    },
  },
});

export const { setPopup, setNotification, clearNotification, clearPopup } =
  popUpSlice.actions;
export default popUpSlice.reducer;
