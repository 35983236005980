import { IValue } from "@/hooks/usePopUp";
import { StaticImageData } from "next/image";

export type ID = string | number;
export type Leagues = "Bronze" | "Silver" | "Gold";

export type UpgradeCost = {
  coins: number;
  pearls: number;
};

export const LANGUAGES = {
  ru: "ru",
  en: "en",
  fr: "fr",
  "es-l": "es-l",
  es: "es",
  id: "id",
  "pt-br":"pt-br"
} as const;

export type Language = keyof typeof LANGUAGES;

export type CostToUpgrade = {
  [level: number]: UpgradeCost;
};

export type ModalFunction = (
  title: string,
  textButton: string,
  text?: string,
  icon?: StaticImageData,
  values?: IValue,
  bonus?:string
) => Promise<boolean>;
