import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppSlice, ImagesByDirectory, IOptions } from "./interfaces";
import { ACTIONS_BULDINGS } from "@/components/Containers/ActionsHouseContainer/actions.constants";
import { ACTIONS_WHARF } from "@/components/Containers/ActionsWharfContainer/actions.constants";
import { RootState } from "..";
import { Language, LANGUAGES } from "@/constants/types";
import { setLocalStorageItem } from "@/utils/localStorage";

export const SOUND = 'sound'

const initialState: IAppSlice = {
  appRef: null,
  canvasRef: null,
  isLoading: false,
  language: LANGUAGES.ru,
  webApp: null,
  coinContainerRef: null,
  isSoundDisabled: false,
  progressLoading: 30,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setApp: (state, action) => {
      state.appRef = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setWebApp: (state, action) => {
      state.webApp = action.payload;
    },
    setCanvas: (state, action) => {
      state.canvasRef = action.payload;
    },
    handleSound: (state, action) => {
      setLocalStorageItem(SOUND, action.payload);
      state.isSoundDisabled = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProgressLoading: (state, action) => {
      state.progressLoading = action.payload;
    },
  },
});

export const {
  setApp,
  handleSound,
  setIsLoading,
  setProgressLoading,
  setCanvas,
  setWebApp,
  setLanguage,
} = appSlice.actions;
export default appSlice.reducer;
