import speed1 from "@/assets/screens/shop/icon_speedUp_1.png";
import speed2 from "@/assets/screens/shop/icon_speedUp_2.png";
import speed3 from "@/assets/screens/shop/icon_speedUp_3.png";
import speed4 from "@/assets/screens/shop/icon_speedUp_4.png";
import speed5 from "@/assets/screens/shop/icon_speedUp_5.png";
import speed6 from "@/assets/screens/shop/icon_speedUp_6.png";

import buster1 from "@/assets/screens/shop/icon_autoGathering_1.png";
import buster2 from "@/assets/screens/shop/icon_autoGathering_2.png";
import buster3 from "@/assets/screens/shop/icon_autoGathering_3.png";
import buster4 from "@/assets/screens/shop/icon_autoGathering_4.png";
import buster5 from "@/assets/screens/shop/icon_autoGathering_5.png";
import buster6 from "@/assets/screens/shop/icon_autoGathering_6.png";

import pearl1 from "@/assets/screens/shop/icon_pearl_1.png";
import pearl2 from "@/assets/screens/shop/icon_pearl_2.png";
import pearl3 from "@/assets/screens/shop/icon_pearl_3.png";
import pearl4 from "@/assets/screens/shop/icon_pearl_4.png";
import pearl5 from "@/assets/screens/shop/icon_pearl_5.png";
import pearl6 from "@/assets/screens/shop/icon_pearl_6.png";

import key1 from "@/assets/screens/shop/icon_key_1.png";
import key2 from "@/assets/screens/shop/icon_key_2.png";
import key3 from "@/assets/screens/shop/icon_key_3.png";
import key4 from "@/assets/screens/shop/icon_key_4.png";
import key5 from "@/assets/screens/shop/icon_key_5.png";
import key6 from "@/assets/screens/shop/icon_key_6.png";

import busterChest1 from "@/assets/screens/shop/icon_autoGathering_chest_1.png";
import busterChest2 from "@/assets/screens/shop/icon_autoGathering_chest_2.png";

import speedUpChest1 from "@/assets/screens/shop/icon_chest_speedUp_1.png";
import speedUpChest2 from "@/assets/screens/shop/icon_chest_speedUp_2.png";

import keyPearl1 from "@/assets/screens/shop/icon_key_pearl_1.png";
import keyPearl2 from "@/assets/screens/shop/icon_key_pearl_2.png";

import sale_1 from "@/assets/screens/shop/sale_1.png";
import sale_2 from "@/assets/screens/shop/sale_2.png";
import sale_3 from "@/assets/screens/shop/sale_3.png";
import sale_4 from "@/assets/screens/shop/sale_4.png";

import { IShopItem, IShopItemDTO } from "@/store/shop/interfaces";

export const getShopItemImage = (image: string) => {
  switch (image) {
    case "icon_speedUp_1":
      return speed1;
    case "icon_speedUp_2":
      return speed2;
    case "icon_speedUp_3":
      return speed3;
    case "icon_speedUp_4":
      return speed4;
    case "icon_speedUp_5":
      return speed5;
    case "icon_speedUp_6":
      return speed6;
    case "icon_autoGathering_1":
      return buster1;
    case "icon_autoGathering_2":
      return buster2;
    case "icon_autoGathering_3":
      return buster3;
    case "icon_autoGathering_4":
      return buster4;
    case "icon_autoGathering_5":
      return buster5;
    case "icon_autoGathering_6":
      return buster6;
    case "icon_pearl_1":
      return pearl1;
    case "icon_pearl_2":
      return pearl2;
    case "icon_pearl_3":
      return pearl3;
    case "icon_pearl_4":
      return pearl4;
    case "icon_pearl_5":
      return pearl5;
    case "icon_pearl_6":
      return pearl6;
    case "icon_key_1":
      return key1;
    case "icon_key_2":
      return key2;
    case "icon_key_3":
      return key3;
    case "icon_key_4":
      return key4;
    case "icon_key_5":
      return key5;
    case "icon_key_6":
      return key6;
    case "icon_autoGathering_chest_1":
      return busterChest1;
    case "icon_autoGathering_chest_2":
      return busterChest2;
    case "icon_chest_speedUp_1":
      return speedUpChest1;
    case "icon_chest_speedUp_2":
      return speedUpChest2;
    case "icon_key_pearl_1":
      return keyPearl1;
    case "icon_key_pearl_2":
      return keyPearl2;
    case "sale_1":
      return sale_1;
    case "sale_2":
      return sale_2;
    case "sale_3":
      return sale_3;
    case "sale_4":
      return sale_4;
    default:
      return speed1;
  }
};

export const getSection = (currentPage: string) => {
  switch (currentPage) {
    case "ЖЕМЧУГ":
      return "pearls";
    case "БУСТЕРЫ":
      return "boosters";
    default:
      return "boosters";
  }
};

export const proccessShopItems = (items: IShopItemDTO[]): IShopItem[] => {
  return items.map((item) => ({
    ...item,
    image: getShopItemImage(item.image),
  }));
};
