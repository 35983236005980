import { ICoordinate } from "@/store/island/interfaces";
import { CostToUpgrade } from "../types";

export const HouseName: { [key: string]: string } = {
  saloon: "Салун",
  sale: "Мастерскую",
  fort: "Форт",
  dock: "Порт",
  pearl: "Магазин",
};

export const houseCoordinates: Record<
  string,
  Record<number, { selected: ICoordinate; notSelected: ICoordinate }>
> = {
  dock: {
    0: { selected: { x: 35, y: 60 }, notSelected: { x: 35, y: 60 } },
    1: { selected: { x: 35, y: 60 }, notSelected: { x: 35, y: 60 } },
    2: { selected: { x: 35, y: 60 }, notSelected: { x: 35, y: 60 } },
    3: { selected: { x: 35, y: 60 }, notSelected: { x: 35, y: 60 } },
    4: { selected: { x: 35, y: 60 }, notSelected: { x: 35, y: 60 } },
    5: { selected: { x: 35, y: 60 }, notSelected: { x: 35, y: 60 } },
  },
  fort: {
    0: { selected: { x: 54, y: 40 }, notSelected: { x: 54, y: 40 } },
    1: { selected: { x: 54, y: 40 }, notSelected: { x: 54, y: 40 } },
    2: { selected: { x: 54, y: 40 }, notSelected: { x: 54, y: 40 } },
    3: { selected: { x: 54, y: 40 }, notSelected: { x: 54, y: 40 } },
    4: { selected: { x: 54, y: 40 }, notSelected: { x: 54, y: 40 } },
    5: { selected: { x: 54, y: 40 }, notSelected: { x: 54, y: 40 } },
  },
  pearl: {
    0: { selected: { x: 35, y: 48 }, notSelected: { x: 35, y: 48 } },
    1: { selected: { x: 35, y: 48 }, notSelected: { x: 35, y: 48 } },
    2: { selected: { x: 35, y: 48 }, notSelected: { x: 35, y: 48 } },
    3: { selected: { x: 35, y: 48 }, notSelected: { x: 35, y: 48 } },
    4: { selected: { x: 35, y: 48 }, notSelected: { x: 35, y: 48 } },
    5: { selected: { x: 35, y: 48 }, notSelected: { x: 35, y: 48 } },
  },
  sale: {
    0: { selected: { x: 54, y: 52 }, notSelected: { x: 54, y: 52 } },
    1: { selected: { x: 54, y: 52 }, notSelected: { x: 54, y: 52 } },
    2: { selected: { x: 54, y: 52 }, notSelected: { x: 54, y: 52 } },
    3: { selected: { x: 54, y: 52 }, notSelected: { x: 54, y: 52 } },
    4: { selected: { x: 54, y: 52 }, notSelected: { x: 54, y: 52 } },
    5: { selected: { x: 54, y: 52 }, notSelected: { x: 54, y: 52 } },
  },
  saloon: {
    0: { selected: { x: 12, y: 48 }, notSelected: { x: 12, y: 48 } },
    1: { selected: { x: 12, y: 48 }, notSelected: { x: 12, y: 48 } },
    2: { selected: { x: 12, y: 48 }, notSelected: { x: 12, y: 48 } },
    3: { selected: { x: 12, y: 48 }, notSelected: { x: 12, y: 48 } },
    4: { selected: { x: 12, y: 48 }, notSelected: { x: 12, y: 48 } },
    5: { selected: { x: 12, y: 48 }, notSelected: { x: 12, y: 48 } },
  },
};

export const COST_TO_UPGRADE: CostToUpgrade = {
  2: { coins: 72000, pearls: 0 },
  3: { coins: 144000, pearls: 0 },
  4: { coins: 288000, pearls: 1440 },
  5: { coins: 576000, pearls: 2880 },
};

export const SHIP_COORDINATES = { x: 25, y: 65 };
export const SHIP_IMAGE = require("@/assets/buildings/ship/ship_in_dock.png");
