export const USER_ACTIONS_ERRORS = {
  NOT_FOUND: "Пользователь не найден",
  INSUFFICIENT_FUNDS: "Недостаточно средств на счету",
  TOO_MANY_REQUESTS: "Слишком много запросов, попробуйте позже",
  UNAUTHORIZED: "Вы не авторизованы для выполнения этого действия",
  INVALID_INPUT: "Некорректные данные, проверьте введенные значения",
  ITEM_NOT_FOUND: "Предмет не найден",
  ACTION_NOT_ALLOWED: "Действие не разрешено для данного пользователя",
  NETWORK_ERROR: "Ошибка сети, попробуйте еще раз",
  SKIP: "skip",
};

export const QUESTS_ERRORS = {
  REQUEST: {
    NOT_FOUND: "Ошибка при загрузке квестов",
  },
};

export const LEADERBOARD_ERRORS = {
  REQUEST: {
    NOT_FOUND: "Ошибка при загрузке лидеров",
  },
};

export const WS_ERRORS = {
  NOT_FOUND: "СОЕДИНЕНИЕ НЕ УСТАНОВЛЕНО",
  NETWORK_ERROR: "ОШИБКА СОЕДИНЕНИЯ! ПОЖАЛУЙСТА ПЕРЕЗАЙДИТЕ!",
};

export const PIXI_ERRORS = {
  SCALE: "Cannot read properties of null (reading 'scale')",
};
