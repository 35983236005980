import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ILeader, ILeaderboardState } from "./interface";
import { LEAGUES } from "@/constants";
import { LEADERS } from "./leaderboard.mockData";

const initialState: ILeaderboardState = {
    leaders: null,
    league: LEAGUES[0],
    page: 1,
    count: 0,
    next: "",
    isLoading: false,
    size: 100,
    previous: "",
};

export const leaderboardSLice = createSlice({
    name: "leaderboard",
    initialState,
    reducers: {
        setStateLeaderboard: (state, action) => {
            if (action.payload.results.length < 3) {
                state.leaders = LEADERS;
                state.count = action.payload.count;
                state.next = action.payload.next;
                state.previous = action.payload.previous;
                return;
            }
            state.leaders = action.payload.results;
            state.count = action.payload.count;
            state.next = action.payload.next;
            state.previous = action.payload.previous;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLeaders: (state, action) => {
            state.leaders = action.payload;
        },
        setLeague: (state, action) => {
            
            state.league = action.payload;
        },
        decreasePage: (state) => {
            state.page -= 1;
        },
        increasePage: (state) => {
            state.page += 1;
        },
    },
});

export const selectLeaders = (state: {
    leaderboard: ILeaderboardState;
}): ILeader[] => {
    if (!state.leaderboard.leaders) return [];
    return state.leaderboard.leaders;
};

export const selectWinners = createSelector([selectLeaders], (leaders) => {
    const winners = leaders.slice(1, 4);
    const reorderedWinners = [winners[1], winners[0], winners[2]];
    return reorderedWinners;
});

export const selectNominators = createSelector([selectLeaders], (leaders) => {
    return leaders.slice(4, leaders.length).filter((leader) => {
        return leader.position !== 999999;
    });
});

export const selectPerson = createSelector([selectLeaders], (leaders) => {
    const person = leaders[0];

    if (
        !leaders
            .slice(1, leaders.length)
            .filter((person) => person.position != 999999)
            .some((nominator) => nominator.id === person.id)
    ) {
        return {
            person,
            position: "100+",
            isPersonInNominators: false,
        };
    }

    const position = (
        leaders.findIndex((leader) => leader.id === person.id) + 1
    ).toString();

    return {
        person,
        position,
        isPersonInNominators: true,
    };
});

export const {
    setLeaders,
    decreasePage,
    increasePage,
    setIsLoading,
    setLeague,
    setStateLeaderboard,
} = leaderboardSLice.actions;
export default leaderboardSLice.reducer;
