import avatar from "@/assets/hud/header/avatar.png";

export const USER = {
  wallet: null,
  id: 1,
  url: avatar.src,
  balance: {
    ton: 0,
    coins: 0,
    pearls: 0,
    multipliers_boosters: {
      booster: "",
      time_remaing: 0,
      boosterID: null,
      count:0
    },
    super_boster: null,
    auto_boosters: {
      quantity: 10,
    },
    keys_cheast: 0,
    lastOpenedChestIndex: null,
  },
  chests: [],
  username: "User",
  ref: 0,
  is_premium: false,
  stats: {
    count_ref_lvl1: 1,
    count_ref_lvl2: 1,
    earned_from_lvl1: 1,
    earned_from_lvl2: 1,
    rewards_pearl: {
      earned_pearls: 1,
    },
    rewards_keys: {
      earned_keys: 1,
    },

    total_gold: 0,
    total_pearls: 0,
    league: "Bronze",
  },
  firstExperience: null,
};
