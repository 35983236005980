import React, { useCallback, useEffect, useState } from "react";
import { ImodalModalProps } from "./interfaces";
import ActionButton from "../ActionButton";
import pearls from "@/assets/icons/pearls.png";
import coins from "@/assets/icons/coin.png";
import Image from "next/image";
import { formatCoin } from "@/utils/formatters/formatCoin";
import { RootState } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import { POP_UP_TEXT } from "./popup.constants";
import close from "@/assets/icons/close.png";
import useClickSound from "@/hooks/useClickSound";
import CardBonus from "@/components/CardBonus";
import DoubleBackground from "@/assets/screens/shop/doubleBackground.png";
import { handleSound } from "@/store/app/appSlice";
import { getTranslation } from "@/utils/location";
import Selector from "@/components/Selector";
import { useRouter } from "next/router";
import { setCurrentPage } from "@/store/shop/shopSlice";

import styles from "./styles.module.scss";

const Popup = ({
  isOpen,
  onClose,
  title,
  textButton,
  icon,
  values,
  bonus,
  text,
}: ImodalModalProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [enough, setEnough] = useState("");
  const formattedCoins = formatCoin(values?.values?.gold || 0);
  const formattedPears = formatCoin(values?.values?.pearl || 0);
  const playSound = useClickSound();
  const isSoundDisabled = useSelector(
    (state: RootState) => state.app.isSoundDisabled
  );
  const language = useSelector((state: RootState) => state.app.language);
  const router = useRouter();
  const GOLD = "золота";
  const PEARLS = "жемчуга";
  const GOLD_AND_PEARLS = "золота и жемчуга";
  const dispatch = useDispatch();
  const balance = useSelector((state: RootState) => state.user.balance);
  const settings = "НАСТРОЙКИ";
  const handleClose = () => {
    playSound();
    onClose(false);
  };

  useEffect(() => {
    handleNotEnough();
  }, []);

  const handleNotEnough = useCallback(() => {
    if (!values) return;
    const isPrize = values?.textValue.includes(POP_UP_TEXT.PRIZE);

    if (isPrize) return;

    const hasEnoughCoins = balance?.coins >= values?.values?.gold;

    const hasEnoughPearls = balance?.pearls >= values?.values?.pearl;

    if (!hasEnoughCoins && !hasEnoughPearls) {
      setEnough(GOLD_AND_PEARLS);
      setIsDisabled(true);
    } else {
      if (!hasEnoughCoins) {
        setEnough(GOLD);
        setIsDisabled(true);
      }
      if (!hasEnoughPearls) {
        setEnough(PEARLS);
        setIsDisabled(true);
      }
    }
  }, [values, balance.coins, balance.pearls]);

  if (!isOpen) return null;

  const handleClickYes = () => {
    onClose(true);
    playSound();
  };

  const handleClickSettingsSound = () => {
    dispatch(handleSound(!isSoundDisabled));
    playSound();
  };

  const handleShopOpen = () => {
    if (enough == GOLD) {
      router.push("/shop");
      dispatch(setCurrentPage("БУСТЕРЫ"));
    }
    if (enough == PEARLS) {
      router.push("/shop");
      dispatch(setCurrentPage("ЖЕМЧУГ"));
    } else {
      router.push("/shop");
      dispatch(setCurrentPage("БУСТЕРЫ"));
    }
    onClose(false);
  };

  if (title == settings)
    return (
      <div className={`${styles.container} `}>
        <div className={`${styles.modal} ${styles.modal_settings}`}>
          <Image
            onClick={handleClose}
            className={styles.modal__close}
            src={close}
            alt="close"
          />
          <div className={styles.modal__title}>
            {getTranslation(title, language)}
          </div>
          <div className={styles.modal__content}>
            <div className={styles.modal__actions__settings}>
              <div className={styles.modal__actions__settings__option}>
                <span>{getTranslation("ЗВУК", language)}</span>
                <button
                  onClick={handleClickSettingsSound}
                  className={`${styles.modal__btn} ${
                    isSoundDisabled && styles.modal__btn_disabled
                  }`}
                >
                  {isSoundDisabled
                    ? getTranslation("ВЫКЛ", language)
                    : getTranslation("ВКЛ", language)}
                </button>
              </div>
              <div className={styles.modal__actions__settings__option}>
                <Selector />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <Image
          onClick={handleClose}
          className={styles.modal__close}
          src={close}
          alt="close"
        />
        <div className={styles.modal__title}>{getTranslation(title)}</div>
        {bonus && (
          <CardBonus className={styles.modal__bonus} image={DoubleBackground}>
            <p className={`${styles.bonus__text} ${styles.green}`}>
              {getTranslation(bonus.split(" ")[0])}
            </p>
            <p className={` ${styles.green} ${styles.big}`}>
              {bonus.split(" ")[1]}
            </p>
          </CardBonus>
        )}
        <div className={styles.modal__content}>
          {icon && (
            <Image
              src={icon}
              alt="icon"
              width={title == "ПОКУПКА" ? 128 : 32}
            />
          )}
          {text && (
            <h2 className={styles.modal__text}>{getTranslation(text)}</h2>
          )}
          {values && (
            <div className={styles.modal__value}>
              {values.values.gold > 0 ||
                (values.values.pearl > 0 && (
                  <span className={styles.modal__value__text}>
                    {values.textValue}
                  </span>
                ))}

              <div className={styles.modal__value__items}>
                {values.values.pearl > 0 && (
                  <div className={styles.modal__value__item}>
                    <Image width={32} height={32} src={pearls} alt="pearls" />
                    <span>{formattedPears}</span>
                  </div>
                )}
                {values.values.gold > 0 && (
                  <div className={styles.modal__value__item}>
                    <Image width={32} height={32} src={coins} alt="pearls" />
                    <span>{formattedCoins}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {isDisabled && enough && (
            <>
              <span className={styles.modal__text__error}>
                {getTranslation("Не хватает ресурсов!")}
              </span>
            </>
          )}
          <div className={styles.modal__actions}>
            {!enough ? (
              <ActionButton onClick={handleClickYes} isDisabled={isDisabled}>
                {getTranslation(textButton)}
              </ActionButton>
            ) : (
              <ActionButton onClick={handleShopOpen} isDisabled={false}>
                {getTranslation("Перейти в магазин")}
              </ActionButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Popup);
