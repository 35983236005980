import { StaticImageData } from "next/image";

export const generateImagePath = (
    buildingName: string,
    level: number,
    type: "default" | "select"
  ): StaticImageData => {
    if (level == 0 && type == "default") {
      return require(`@/assets/buildings/${buildingName}/${type}/${buildingName}_base.png`)
        .default;
    } else if (level == 0 && type == "select") {
      return require(`@/assets/buildings/${buildingName}/${type}/${buildingName}_base_${type}.png`)
        .default;
    }
    if (type == "default") {
      return require(`@/assets/buildings/${buildingName}/${type}/${buildingName}_lvl_${level}.png`)
        .default;
    }
    return require(`@/assets/buildings/${buildingName}/${type}/${buildingName}_lvl_${level}_${type}.png`)
      .default;
  };