import { createSlice, createSelector, Dispatch } from "@reduxjs/toolkit";
import { IHouse, IIslandState } from "./interfaces";
import { processData } from "@/utils/houses/processData";
import { RootState } from "..";

const initialState: IIslandState = {
  houses: [],
  ship: { isTraveling: true, resourses: 0 },
  activeNameHouse: "",
  timers: {},
};

export const islandSlice = createSlice({
  name: "island",
  initialState,
  reducers: {
    setHouses: (state, action) => {
      state.houses = action.payload;
    },
    setNameActiveHouse: (state, action) => {
      state.activeNameHouse = action.payload;
    },
    upgradeHouse: (state, action) => {
      if (!state.houses) return;

      const name = action.payload;
      const house = state.houses.find(
        (house) => house.house_type_name === name
      );

      if (house) {
        house.level += 1;
        house.images = processData([house])[0].images;
        house.coordinates = processData([house])[0].coordinates;
      }
    },
    setTimer: (state, action) => {
      const { name, timerId } = action.payload;
      if (state.timers[name]) {
        clearInterval(state.timers[name]); 
      }
      state.timers[name] = timerId; 
    },
    clearTimer: (state, action) => {
      const name = action.payload;
      if (state.timers[name]) {
        clearInterval(state.timers[name]!);
        state.timers[name] = null; 
      }
    },
    setIsTraveling(state, action) {
      state.ship.isTraveling = action.payload;
    },
    resetTimeRemaining: (state, action) => {
      const name = action.payload;
      const house = state.houses.find((h) => h.house_type_name === name);
      if (house) {
        house.time_remaining_to_full_resources = 60;
      }
    },
    setBoostTimeRemaing: (state, action) => {
      if (!state.houses) return;

      const name = action.payload.name;
      const house = state.houses.find(
        (house) => house.house_type_name === name
      );

      if (!house) throw new Error("Не удалось найти дом");
      house.boost_time_remaining = action.payload.time;
    },
    decreaseBoostTimeRemaing: (state, action) => {
      if (!state.houses) return;

      const name = action.payload.name;
      const house = state.houses.find(
        (house) => house.house_type_name === name
      );
      if (!house) throw new Error("Не удалось найти дом");

      house.boost_time_remaining -= 1;
    },
    decreaseTimeRemaingToCollect: (state, action) => {
      if (!state.houses) return;

      const name = action.payload.name;
      const house = state.houses.find(
        (house) => house.house_type_name === name
      );
      
      if (!house) throw new Error("Не удалось найти дом");
      
      house.time_remaining_to_full_resources -= 1;
    },
    increaseAutoBoosters: (state, action) => {
      if (!state.houses) return;

      const name = action.payload.name;
      const house = state.houses.find(
        (house) => house.house_type_name === name
      );
      if (!house) throw new Error("Не удалось найти дом");

      house.quantity_auto_boosters += 1;
    },
    decreaseAutoBoosters: (state, action) => {
      if (!state.houses) return;

      const name = action.payload.name;
      const house = state.houses.find(
        (house) => house.house_type_name === name
      );
      if (!house) throw new Error("Не удалось найти дом");

      house.quantity_auto_boosters -= 1;
    },
    collectResourses: (state, action) => {
      if (!state.houses) return;

      const name = action.payload;
      const house = state.houses.find(
        (house) => house.house_type_name === name
      );

      if (house) {
        house.resource_amount_gold = 0;
      }
    },

    setCanCollect: (state, action) => {
      const { name, canCollect } = action.payload;

      const house = state.houses.find(
        (house) => house.house_type_name === name
      );
      if (house) {
        house.is_can_collect = canCollect;
      }
    },
  },
});

const selectHouses = (state: RootState) => state.island.houses;
export const selectHouseByName = (state: RootState, name: string) =>
  state.island.houses.find((house) => house.house_type_name === name);

export const selectIsBoostActive = createSelector([selectHouses], (houses) =>
  houses.some((house) => house.boost_time_remaining > 0)
);

export const {
  setHouses,
  setBoostTimeRemaing,
  setNameActiveHouse,
  upgradeHouse,
  resetTimeRemaining,
  setIsTraveling,
  increaseAutoBoosters,
  setCanCollect,
  decreaseAutoBoosters,
  setTimer,
  clearTimer,
  decreaseTimeRemaingToCollect,
  collectResourses,
  decreaseBoostTimeRemaing,
} = islandSlice.actions;

export default islandSlice.reducer;
