import done from "@/assets/icons/quests/done.png";
import tikTok from "@/assets/icons/quests/tik-tok.png";
import twitter from "@/assets/icons/quests/twitter.png";
import telegram from "@/assets/icons/quests/telegram.png";
import ads from "@/assets/icons/quests/ads.png";
import instagram from "@/assets/icons/quests/instagram.png";
import not_done from "@/assets/icons/quests/not_done.png";
import youtube from "@/assets/icons/quests/youtube.png";

export const IMAGES_TYPES = {
  DONE: "done",
  TIK_TOK: "tiktok",
  TWITTER: "twitter",
  TELEGRAM: "telegram",
  ADS: "ads",
  INSTAGRAM: "IG",
  NOT_DONE: "not_done",
  YOUTUBE: "youtube",
};

export const getImageByType = (type: string) => {
  switch (type) {
    case IMAGES_TYPES.TIK_TOK:
      return tikTok;
    case IMAGES_TYPES.TWITTER:
      return twitter;
    case IMAGES_TYPES.TELEGRAM:
      return telegram;
    case IMAGES_TYPES.INSTAGRAM:
      return instagram;
    case IMAGES_TYPES.YOUTUBE:
      return youtube;
    case IMAGES_TYPES.ADS:
      return ads;
    default:
      return not_done;
  }
};
