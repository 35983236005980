import React, { useEffect } from "react";
import Head from "next/head";
import { Provider } from "react-redux";
import { store } from "@/store";
import { PopupProvider } from "@/hooks/usePopUp";
import ErrorBoundary from "@/components/ErrorBoundary";
import type { AppProps } from "next/app";
import "react-toastify/dist/ReactToastify.css";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import dynamic from "next/dynamic";

const AppInitializer = dynamic(() => import("@/components/AppInitializer"), {
  ssr: false,
  loading: () => <Loader />,
});

const Tutorial = dynamic(() => import("@/components/Tutorial"), {
  ssr: false,
  loading: () => <Loader />,
});

const ToastContainer = dynamic(
  () => import("react-toastify").then((mod) => mod.ToastContainer),
  {
    ssr: false,
    loading: () => <Loader />,
  }
);

import "@/styles/globals.css";
import Loader from "@/components/Loader";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
        />
      </Head>
      <Provider store={store}>
        <PopupProvider>
          <ErrorBoundary>
            <TonConnectUIProvider
              manifestUrl={`${
                process.env.WEB_APP_LINK + "tonconnect-manifest.json"
              }`}
            >
                <AppInitializer>
                  <Tutorial />
                  <Component {...pageProps} />
                  <ToastContainer
                    className={"toast-container"}
                    position="top-right"
                  />
                  <div id="modals" />
                </AppInitializer>
            </TonConnectUIProvider>
          </ErrorBoundary>
        </PopupProvider>
      </Provider>
    </>
  );
}
