import { createSlice } from "@reduxjs/toolkit";
import { IShopSlice } from "./interfaces";

const initialState: IShopSlice = {
  currentPage: "БУСТЕРЫ",
  shopItems: [],
  isValidate: false,
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setShopItems: (state, action) => {
      state.shopItems = action.payload;
    },
    setIsValidate: (state, action) => {
      state.isValidate = action.payload;
    },
  },
});

export const { setCurrentPage, setIsValidate, setShopItems } =
  shopSlice.actions;
export default shopSlice.reducer;
