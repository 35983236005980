export const formatCoin = (value: number,isTon?:boolean): string => {
  if (isTon) {
    return value.toString();
  }

  if (value < 1000000) {
    return Math.round(value).toString();
  }
  if (value >= 1000000) {
    return Math.round(Math.floor(value / 1000)) + "К";
  }
  return Math.round(Math.floor(value / 1000)) + "К";
};
