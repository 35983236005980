import { StaticImageData } from "next/image";
import { getLocalStorageItem } from "./localStorage";
import tg from "@/assets/screens/friends/tg-base.png";
import tg_en from "@/assets/screens/friends/tg_base_en.png";
import tg_fr from "@/assets/screens/friends/tg_base_fr.png";
import tg_es from "@/assets/screens/friends/tg_base_es.png";
import tg_id from "@/assets/screens/friends/tg_base_id.png";
import tg_pt from "@/assets/screens/friends/tg_base_pt.png";
import tgPremium from "@/assets/screens/friends/tg-prem.png";
import tgPremium_en from "@/assets/screens/friends/tg_prem_en.png";
import tgPremium_fr from "@/assets/screens/friends/tg_prem_fr.png";
import tgPremium_es from "@/assets/screens/friends/tg_prem_es.png";
import tgPremium_id from "@/assets/screens/friends/tg_prem_id.png";
import tgPremium_pt from "@/assets/screens/friends/tg_prem_pt.png";
import { LANGUAGES } from "@/constants/types";
import islandSoon from "@/assets/screens/map/island_soon_open.png";
import islandSoon_en from "@/assets/screens/map/island_soon_open_en.png";
import islandSoon_fr from "@/assets/screens/map/island_soon_open_fr.png";
import islandSoon_es from "@/assets/screens/map/island_soon_open_es.png";
import islandSoon_id from "@/assets/screens/map/island_soon_open_id.png";
import islandSoon_pt from "@/assets/screens/map/island_soon_open_pt.png";
import prizeFond from "@/assets/screens/friends/prizeFond.png";
import prizeFond_en from "@/assets/screens/friends/prizeFond_en.png";

interface Translations {
  [key: string]: {
    [lang in keyof typeof LANGUAGES]: string;
  };
}

const translations: Translations = {
  "Этот тип устройства не поддерживается. Пожалуйста, отсканируйте QR-код на своем телефоне!":
    {
      ru: "Этот тип устройства не поддерживается. Пожалуйста, отсканируйте QR-код на своем телефоне!",
      en: "This type of device is not supported. Please scan the QR code on your phone!",
      fr: "Ce type d'appareil n'est pas pris en charge. Veuillez scanner le code QR sur votre téléphone !",
      es: "Este tipo de dispositivo no es compatible. ¡Por favor, escanea el código QR en tu teléfono!",
      "es-l":
        "Este tipo de dispositivo no es compatible. ¡Por favor, escanea el código QR en tu teléfono!",
      id: "Jenis perangkat ini tidak didukung. Silakan pindai kode QR di ponsel Anda!",
      ["pt-br"]:
        "Este tipo de dispositivo não é compatível. Por favor, escaneie o código QR no seu telefone!",
    },
  БУСТЕРЫ: {
    ru: "БУСТЕРЫ",
    en: "BUSTERS",
    fr: "BOOSTERS",
    es: "BOOSTER",
    "es-l": "POTENCIADORES",
    id: "PENGUAT",
    ["pt-br"]: "PACOTES BÔNUS",
  },
  ЯЗЫК: {
    ru: "ЯЗЫК",
    en: "LANGUAGE",
    fr: "Langue",
    es: "IDIOMA",
    "es-l": "IDIOMA",
    id: "BAHASA",
    ["pt-br"]: "IDIOMA",
  },
  ЖЕМЧУГ: {
    ru: "ЖЕМЧУГ",
    en: "PEARLS",
    fr: "PERLES",
    es: "PERLAS",
    "es-l": "PERLAS",
    id: "MUTIARA",
    ["pt-br"]: "PÉROLAS",
  },
  Бустеры: {
    ru: "Бустеры",
    en: "Boosters",
    fr: "Boosters",
    es: "Boosters",
    "es-l": "Boosters",
    id: "Penguat",
    ["pt-br"]: "Pacotes bonus",
  },
  "2х БУСТЕР НА 12 ЧАСОВ": {
    ru: "2Х БУСТЕР НА 12 ЧАСОВ",
    en: "2X BOOSTERS FOR 12 HOURS",
    fr: "X2 PENDANT 2 HEURES",
    es: "2X BOOSTER PARA 12 HORAS",
    "es-l": "2X POTENCIADORES PARA 12 HORAS",
    id: "2X BOOSTER SELAMA 12 JAM",
    ["pt-br"]: "2X PACOTES BÔNUS POR 12 HORAS",
  },
  ПОКУПКА: {
    ru: "ПОКУПКА",
    en: "PURCHASE",
    fr: "ACHAT",
    es: "COMPRA",
    "es-l": "COMPRA",
    id: "BELI",
    ["pt-br"]: "COMPRAR",
  },
  "2Х БУСТЕР НА 9 ЧАСОВ": {
    ru: "2Х БУСТЕР НА 9 ЧАСОВ",
    en: "2X BOOSTERS FOR 9 HOURS",
    fr: "X2 PENDANT 9 HEURES",
    es: "2X BOOSTER PARA 9 HORAS",
    "es-l": "2X POTENCIADORES PARA 9 HORAS",
    id: "2X BOOSTER SELAMA 9 JAM",
    ["pt-br"]: "2X PACOTES BÔNUS POR 9 HORAS",
  },
  "2Х БУСТЕР НА 6 ЧАСОВ": {
    ru: "2Х БУСТЕР НА 6 ЧАСОВ",
    en: "2X BOOSTERS FOR 6 HOURS",
    fr: "X2 PENDANT 6 HEURES",
    es: "2X BOOSTER PARA 6 HORAS",
    "es-l": "2X POTENCIADORES PARA 6 HORAS",
    id: "2X BOOSTER SELAMA 6 JAM",
    ["pt-br"]: "2X PACOTES BÔNUS POR 6 HORAS",
  },
  "2Х БУСТЕР НА 3 ЧАСА": {
    ru: "2Х БУСТЕР НА 3 ЧАСА",
    en: "2X BOOSTERS FOR 3 HOURS",
    fr: "X2 PENDANT 3 HEURES",
    es: "2X BOOSTER PARA 3 HORAS",
    "es-l": "2X POTENCIADORES PARA 3 HORAS",
    id: "2X BOOSTER SELAMA 3 JAM",
    ["pt-br"]: "2X PACOTES BÔNUS POR 3 HORAS",
  },
  "20 БУСТЕРОВ АВТОСБОРА": {
    ru: "20 БУСТЕРОВ АВТОСБОРА",
    en: "20 BOOSTERS AUTOCOLLECT",
    fr: "20 BOOSTERS DE COLLECTE AUTOMATIQUE",
    es: "20 BOOSTER AUTOMATIQUE",
    "es-l": "20 BOOSTER AUTOCOLECCIÓN",
    id: "20 BOOSTER KOLEKSI OTOMATIS",
    ["pt-br"]: "20 PACOTES BÔNUS AUTOMÁTICOS",
  },
  "15 БУСТЕРОВ АВТОСБОРА": {
    ru: "15 БУСТЕРОВ АВТОСБОРА",
    en: "15 BOOSTERS AUTOCOLLECT",
    fr: "15 BOOSTERS DE COLLECTE AUTOMATIQUE",
    es: "15 BOOSTER AUTOCOLECCIÓN",
    "es-l": "15 BOOSTER AUTOCOLECCIÓN",
    id: "15 BOOSTER KOLEKSI OTOMATIS",
    ["pt-br"]: "15 PACOTES BÔNUS COLETA AUTOMATICA",
  },
  "9 БУСТЕРОВ АВТОСБОРА": {
    ru: "9 БУСТЕРОВ АВТОСБОРА",
    en: "9 BOOSTERS AUTOCOLLECT",
    fr: "9 BOOSTERS DE COLLECTE AUTOMATIQUE",
    es: "9 BOOSTER AUTOCOLECCIÓN",
    "es-l": "9 BOOSTER AUTOCOLECCIÓN",
    id: "9 BOOSTER KOLEKSI OTOMATIS",
    ["pt-br"]: "9 PACOTES BÔNUS COLETA AUTOMATICA",
  },
  "3 БУСТЕРОВ АВТОСБОРА": {
    ru: "3 БУСТЕРА АВТОСБОРА",
    en: "3 BOOSTERS AUTOCOLLECT",
    fr: "3 BOOSTERS DE COLLECTE AUTOMATIQUE",
    es: "3 BOOSTER AUTOCOLECCIÓN",
    "es-l": "3 BOOSTER AUTOCOLECCIÓN",
    id: "3 BOOSTER KOLEKSI OTOMATIS",
    ["pt-br"]: "3 PACOTES BÔNUS COLETA AUTOMATICA",
  },
  "1200 ЖЕМЧУГА": {
    ru: "1200 ЖЕМЧУГА",
    en: "1200 PEARLS",
    fr: "1200 PERLES",
    es: "1200 PERLAS",
    "es-l": "1200 PERLAS",
    id: "1200 MUTIARA",
    ["pt-br"]: "1200 PÉROLAS",
  },
  "3999 ЖЕМЧУГА": {
    ru: "3999 ЖЕМЧУГА",
    en: "3999 PEARLS",
    fr: "3999 PERLES",
    es: "3999 PERLAS",
    "es-l": "3999 PERLAS",
    id: "3999 MUTIARA",
    ["pt-br"]: "3999 PÉROLAS",
  },
  "8999 ЖЕМЧУГА": {
    ru: "8999 ЖЕМЧУГА",
    en: "8999 PEARLS",
    fr: "8999 PERLES",
    es: "8999 PERLAS",
    "es-l": "8999 PERLAS",
    id: "8999 MUTIARA",
    ["pt-br"]: "8999 PÉROLAS",
  },
  "15999 ЖЕМЧУГА": {
    ru: "15999 ЖЕМЧУГА",
    en: "15999 PEARLS",
    fr: "15999 PERLES",
    es: "15999 PERLAS",
    "es-l": "15999 PERLAS",
    id: "15999 MUTIARA",
    ["pt-br"]: "15999 PÉROLAS",
  },
  МАГАЗИН: {
    ru: "МАГАЗИН",
    en: "SHOP",
    fr: "BOUTIQUE",
    es: "TIENDA",
    "es-l": "TIENDA",
    id: "TOKO",
    ["pt-br"]: "LOJA",
  },
  ДРУЗЬЯ: {
    ru: "ДРУЗЬЯ",
    en: "FRIENDS",
    fr: "AMIS",
    es: "AMIGOS",
    "es-l": "AMIGOS",
    id: "TEMAN",
    ["pt-br"]: "AMIGOS",
  },
  МЕНЮ: {
    ru: "МЕНЮ",
    en: "MENU",
    fr: "MENU",
    es: "MENÚ",
    "es-l": "MENÚ",
    id: "MENU",
    ["pt-br"]: "MENU",
  },
  ЗАДАЧИ: {
    ru: "ЗАДАЧИ",
    en: "QUESTS",
    fr: "TÂCHES",
    es: "TAREAS",
    "es-l": "TAREAS",
    id: "TUGAS",
    ["pt-br"]: "OBJETIVOS",
  },
  ДРОП: {
    ru: "ДРОП",
    en: "DROP",
    fr: "DROP",
    es: "DROP",
    "es-l": "DROP",
    id: "DROP",
    ["pt-br"]: "PRÊMIO",
  },
  "Присоединяйтесь к нашему Telegram-каналу": {
    ru: "Присоединяйтесь к нашему Telegram-каналу",
    en: "Join our Telegram channel",
    fr: "Rejoignez notre chaîne Telegram",
    es: "Unirse a nuestro canal de Telegram",
    "es-l": "Unirse a nuestro canal de Telegram",
    id: "Join our Telegram channel",
    ["pt-br"]: "Participe de nosso canal no Telegram",
  },
  "Оставьте сообщение в нашем Telegram чате": {
    ru: "Подпишитесь на наш Telegram Чат",
    en: "Send a message in our Telegram chat",
    fr: "Envoyez un message dans notre chat Telegram",
    es: "Envia un mensaje en nuestro chat Telegram",
    "es-l": "Envia un mensaje en nuestro chat Telegram",
    id: "Send a message in our Telegram chat",
    ["pt-br"]: "Deixe uma mensagem em nosso bate-papo do Telegram",
  },
  "Проголосовать за канал Pirate Empire в Telegram": {
    ru: "Проголосовать за канал Pirate Empire в Telegram",
    en: "Vote for Pirate Empire channel in Telegram",
    fr: "Votez pour le canal Pirate Empire sur Telegram",
    es: "Vota por el canal Pirate Empire en Telegram",
    "es-l": "Vota por el canal Pirate Empire en Telegram",
    id: "Vote for Pirate Empire channel in Telegram",
    ["pt-br"]: "Vote no canal Pirate Empire no Telegram",
  },
  "Подпишитесь на Pirate Empire в X": {
    ru: "Подпишитесь на Pirate Empire в X",
    en: "Subscribe to Pirate Empire at X",
    fr: "Abonnez-vous à Pirate Empire sur X",
    es: "Suscribirse a Pirate Empire en X",
    "es-l": "Suscribirse a Pirate Empire en X",
    id: "Subscribe to Pirate Empire at X",
    ["pt-br"]: "Assinar o Pirate Empire no X",
  },
  "Подпишитесь на страницу Pirate Empire в Instagram": {
    ru: "Подпишитесь на страницу Pirate Empire в Instagram",
    en: "Subscribe to Pirate Empire's Instagram page",
    fr: "Abonnez-vous à la page Instagram de Pirate Empire",
    es: "Suscribirse a la página de Pirate Empire en Instagram",
    "es-l": "Suscribirse a la página de Pirate Empire en Instagram",
    id: "Subscribe to Pirate Empire's Instagram page",
    ["pt-br"]: "Assinar a página do Instagram do Pirate Empire",
  },
  "Подпишитесь на Pirate Empire в TikTok": {
    ru: "Подпишитесь на Pirate Empire в TikTok",
    en: "Subscribe to Pirate Empire on TikTok",
    fr: "Abonnez-vous à Pirate Empire sur TikTok",
    es: "Suscribirse a Pirate Empire en TikTok",
    "es-l": "Suscribirse a Pirate Empire en TikTok",
    id: "Subscribe to Pirate Empire on TikTok",
    ["pt-br"]: "Assinar o Pirate Empire no TikTok",
  },
  "Подпишитесь на наш YouTube-канал": {
    ru: "Подпишитесь на наш YouTube-канал",
    en: "Subscribe to our YouTube channel",
    fr: "Abonnez-vous à notre chaîne YouTube",
    es: "Suscribirse a nuestro canal de YouTube",
    "es-l": "Suscribirse a nuestro canal de YouTube",
    id: "Subscribe to our YouTube channel",
    ["pt-br"]: "Assinar o nosso canal do YouTube",
  },
  "Пригласите 10 друзей": {
    ru: "Пригласите 10 друзей",
    en: "Invite 10 friends",
    fr: "Invitez 10 amis",
    es: "Invita 10 amigos",
    "es-l": "Invita 10 amigos",
    id: "Undang 10 teman",
    ["pt-br"]: "Convidar 10 amigos",
  },
  "Проголосовать за канал": {
    ru: "Проголосовать за канал",
    en: "Vote for the channel",
    fr: "Votez pour le canal",
    es: "Vota por el canal",
    "es-l": "Vota por el canal",
    id: "Vote for the channel",
    ["pt-br"]: "Votar no canal",
  },
  "Подписка на Х": {
    ru: "Подписка на Х",
    en: "Subscribe on X",
    fr: "Abonnement sur X",
    es: "Suscríbete a X",
    "es-l": "Suscríbete a X",
    id: "Berlangganan ke X",
    ["pt-br"]: "Assinar o X",
  },
  "Подписка на IG": {
    ru: "Подписка на IG",
    en: "Subscribe on IG",
    fr: "Abonnement sur IG",
    es: "Suscríbete a IG",
    "es-l": "Suscríbete a IG",
    id: "Berlangganan ke IG",
    ["pt-br"]: "Assinar o IG",
  },
  "Подписка на тик ток": {
    ru: "Подписка на Тик Ток",
    en: "Subscribe on Tik Tok",
    fr: "Abonnement sur Tik Tok",
    es: "Suscríbete a TikTok",
    "es-l": "Suscríbete a TikTok",
    id: "Berlangganan ke TikTok",
    ["pt-br"]: "Assinar o TikTok",
  },
  "Подписка на тг канал": {
    ru: "Подписка на телеграмм канал",
    en: "Subscribe on telegram channel",
    fr: "Abonnement sur le canal Telegram",
    es: "Suscríbete al canal de Telegram",
    "es-l": "Suscríbete al canal de Telegram",
    id: "Berlangganan ke saluran Telegram",
    ["pt-br"]: "Assinar o canal do Telegram",
  },
  "Подписка на ютуб": {
    ru: "Подписка на ютуб",
    en: "Subscribe on Youtube",
    fr: "Abonnement sur Youtube",
    es: "Suscribete a Youtube",
    "es-l": "Suscribete a Youtube",
    id: "Berlangganan ke Youtube",
    ["pt-br"]: "Assinar o Youtube",
  },
  "Invite friends 10": {
    ru: "Пригласи 10 друзей",
    en: "Invite friends 10",
    fr: "Invitez 10 amis",
    es: "Invita 10 amigos",
    "es-l": "Invita 10 amigos",
    id: "Undang 10 teman",
    ["pt-br"]: "Convidar 10 amigos",
  },
  "Заработай больше денег!": {
    ru: "Получите больше ресурсов!",
    en: "Earn more money!",
    fr: "Gagnez plus d'argent!",
    es: "Ganar dinero!",
    "es-l": "Ganar dinero!",
    id: "Jualkan lebih banyak!",
    ["pt-br"]: "Jogue mais dinheiro!",
  },
  "Скоро будет информация об аирдропе": {
    ru: "Скоро будет информация об аирдропе!",
    en: "Coming Soon Airdrop Information",
    fr: "Informations sur l'airdrop bientôt disponibles",
    es: "¡Pronto habrá un drop!",
    "es-l": "¡En breve llegará un drop!",
    id: "Drop akan segera hadir!",
    ["pt-br"]: "Em breve as informações sobre o airdrop!",
  },
  "Список моих друзей:": {
    ru: "Список моих друзей:",
    en: "My friends list:",
    fr: "Liste de mes amis:",
    es: "Lista de mis amigos:",
    "es-l": "Lista de mis amigos:",
    id: "Daftar teman saya:",
    ["pt-br"]: "Lista de meus amigos:",
  },
  "Список друзей моих друзей:": {
    ru: "Список друзей моих друзей:",
    en: "My friends' friends list:",
    fr: "Liste des amis de mes amis:",
    es: "Lista de los amigos de mis amigos:",
    "es-l": "Lista de los amigos de mis amigos:",
    id: "Daftar teman dari teman saya:",
    ["pt-br"]: "Lista de amigos dos meus amigos:",
  },
  "ПРИГЛАШАЙ ДРУЗЕЙ!": {
    ru: "ПРИГЛАШАЙ ДРУЗЕЙ!",
    en: "INVITE YOUR FRIENDS!",
    fr: "INVITEZ VOS AMIS!",
    es: "¡INVITA A UN AMIGO!",
    "es-l": "¡INVITA A UN AMIGO!",
    id: "UNDANG TEMAN!",
    ["pt-br"]: "CONVIDE SEUS AMIGOS!",
  },
  "Пригласить друга!": {
    ru: "Пригласить друга!",
    en: "Invite friend!",
    fr: "Inviter un ami!",
    es: "Invita a un amigo!",
    "es-l": "Invita a un amigo!",
    id: "Undang Teman!",
    ["pt-br"]: "Convide um amigo!",
  },
  RoadMap: {
    ru: "RoadMap",
    en: "RoadMap",
    fr: "RoadMap",
    es: "RoadMap",
    "es-l": "RoadMap",
    id: "Roadmap",
    ["pt-br"]: "RoadMap",
  },
  Снять: {
    ru: "Снять",
    en: "Withdraw",
    fr: "Retiro",
    es: "Retiro",
    "es-l": "Retiro",
    id: "Tarik",
    ["pt-br"]: "Retirar",
  },
  "Пригласи друзей и зарабатывай TON!": {
    ru: "Приглашайте друзей и зарабатывайте",
    en: "Invite friends and earn TON!",
    fr: "Invitez des amis et gagnez du TON!",
    es: "Invita a tus amigos y gana TON!",
    "es-l": "Invita a tus amigos y gana TON!",
    id: "Undang Teman dan Dapatkan TON!",
    ["pt-br"]: "Convide seus amigos e jogue TON!",
  },
  "Пригласи друзей и зарабатывай": {
    ru: "Пригласи друзей и зарабатывай",
    en: "Invite your friends and earn money",
    fr: "Invitez vos amis et gagnez de l'argent",
    es: "Invita a tus amigos y gana dinero",
    "es-l": "Invita a tus amigos y gana dinero",
    id: "Undang teman dan jualkan uang",
    ["pt-br"]: "Convide seus amigos e jogue dinheiro!",
  },
  "За каждого друга ты получишь": {
    ru: "За каждого друга ты получишь",
    en: "For every friend you make",
    fr: "Pour chaque ami que vous faites",
    es: "Para cada amigo que hagas",
    "es-l": "Para cada amigo que hagas",
    id: "Untuk setiap teman yang kamu buat",
    ["pt-br"]: "Para cada amigo que voce faz!",
  },
  "и 10 Жемчуга": {
    ru: "и 10 Жемчуга",
    en: "and 10 Pearls",
    fr: "et 10 perles",
    es: "y 10 perlas",
    "es-l": "y 10 perlas",
    id: "dan 10 perlas",
    ["pt-br"]: "e 10 perlas",
  },
  "для тебя и друга. А за каждого друга с Telegram Premium ты получишь дополнительно":
    {
      ru: "для тебя и твоего друга, а за каждого друга с Telegram Premium ты получишь дополнительно",
      en: "for you and friend. And for each friend with Telegram Premium, you will get an additional",
      fr: "pour vous et votre ami. Et pour chaque ami avec Telegram Premium, vous obtiendrez un supplément",
      es: "para ti y tu amigo. Y para cada amigo con Telegram Premium, obtendra más",
      "es-l":
        "para ti y tu amigo. Y para cada amigo con Telegram Premium, obtendra más",
      id: "untukmu dan temanmu. Dan untuk setiap teman dengan Telegram Premium, kamu akan mendapat tambahan",
      ["pt-br"]:
        "para vocé e amigo. E para cada amigo com Telegram Premium, vocé receberá um adicional",
    },
  "и 20 Жемчуга": {
    ru: "и 20 Жемчуга",
    en: "and 20 Pearls",
    fr: "et 20 perles",
    es: "y 20 perlas",
    "es-l": "y 20 perlas",
    id: "dan 20 perlas",
    ["pt-br"]: "e 20 perlas",
  },
  "Но это ещё не всё!": {
    ru: "Но это ещё не всё!",
    en: "But that's not all!",
    fr: "Mais ce n'est pas tout!",
    es: "Pero no es todo!",
    "es-l": "Pero no es todo!",
    id: "Tapi bukan semua!",
    ["pt-br"]: "Mas não é tudo!",
  },
  "Каждые 10 новых друзей увеличивает твой доход вплоть до": {
    ru: "Каждые 10 новых друзей увеличивают твой доход вплоть до",
    en: "Every 10 new friends increases your income up to",
    fr: "Tous les 10 nouveaux amis augmentent vos revenus jusqu'à",
    es: "Cada 10 nuevos amigos incrementan tu ingreso hasta",
    "es-l": "Cada 10 nuevos amigos incrementan tu ingreso hasta",
    id: "Setiap 10 teman baru meningkatkan penghasilanmu hingga",
    ["pt-br"]: "Cada 10 novos amigos aumentam o seu rendimento até",
  },
  "Все заработанные тобой TON можно будет увидеть  на главном экране. Вывод TON находится в разработке. Следите за навостями!":
    {
      ru: "Все заработанные тобой TON можно будет увидеть на главном экране. Вывод TON находится в разработке. Следите за навостями!",
      en: "All the TONs you earn will be visible on the main screen. TON withdrawal is in development. Stay tuned!",
      fr: "Tous les TON que vous gagnez seront visibles sur l'écran principal. Le retrait de TON est en développement. Restez à l'écoute!",
      es: "Todos los TONs que ganas serán visibles en la pantalla principal. Retiro de TON en desarrollo. ¡Segúrate!",
      "es-l":
        "Todos los TONs que ganas serán visibles en la pantalla principal. Retiro de TON en desarollo. ¡Segúrate!",
      id: "Kamu dapat melihat semua TON yang telah kamu peroleh di layar utama. Penarikan TON sedang dalam pengembangan—nantikan pembaruannya!",
      ["pt-br"]:
        "Todos os TONs que ganhas serão visíveis na tela principal. Retirada de TON em desenvolvimento. Mantenha-se a vários!",
    },
  "Внимание!": {
    ru: "Внимание!",
    en: "Attention!",
    fr: "Attention!",
    es: "Atencion!",
    "es-l": "Atencion!",
    id: "Perhatian!",
    ["pt-br"]: "Atenção!",
  },
  "Использование ботов для накрутки друзей против правил Pirate Empire! TON начисляются только за реальных друзей. Приведённые боты будут вычитаться из списка приглашённых друзей.":
    {
      ru: "Использование ботов для накрутки друзей против правил Pirate Empire! TON начисляются только за реальных друзей. Приведённые боты будут вычитаться из списка приглашённых друзей.",
      en: "Using bots to make friends is against Pirate Empire rules! TONs are awarded only for real friends. Bots will be deducted from the list of invited friends.",
      fr: "L'utilisation de bots pour se faire des amis est contraire aux règles de Pirate Empire! Les TON ne sont attribués que pour les amis réels. Les bots seront déduits de la liste des amis invités.",
      es: "Usar bots para hacer amigos es contra las reglas de Pirate Empire! Los TON se otorgan solo a amigos reales. Los bots se deducen de la lista de amigos invitados.",
      "es-l":
        "Usar bots para hacer amigos es contra las reglas de Pirate Empire! Los TON se otorgan solo a amigos reales. Los bots se deducen de la lista de amigos invitados.",
      id: "Menggunakan bot untuk menambah jumlah teman-temanmu melanggar aturan Pirate Empire! Hadiah TON hanya diberikan untuk teman sungguhan. Bot yang terdeteksi akan dihapus dari daftar teman yang diundang.",
      ["pt-br"]:
        "Usar bots para se tornar amigos é ilegal! TONs são atribuídos apenas para amigos reais. Os bots serão deduzidos da lista de amigos convidados.",
    },
  "Ваш заказ обрабатывается": {
    ru: "Ваш заказ обрабатывается",
    en: "Your order is being processed",
    fr: "Votre commande est en cours de traitement",
    es: "Su pedido se esta procesando",
    "es-l": "Su pedido se esta procesando",
    id: "Pesanan Anda sedang diproses",
    ["pt-br"]: "Seu pedido está sendo processado",
  },
  "Ваш заказ успешно обработан!": {
    ru: "Ваш заказ успешно обработан!",
    en: "Your order has been processed successfully!",
    fr: "Votre commande a été traitée avec succès!",
    es: "Su pedido ha sido procesado correctamente!",
    "es-l": "Su pedido ha sido procesado correctamente!",
    id: "Pesanan Anda telah diproses!",
    ["pt-br"]: "Seu pedido foi processado com sucesso!",
  },
  "Произошла ошибка при обработке заказа.": {
    ru: "Произошла ошибка при обработке заказа.",
    en: "There was an error while processing the order.",
    fr: "Une erreur s'est produite lors du traitement de la commande.",
    es: "Se produjo un error al procesar el pedido.",
    "es-l": "Se produjo un error al procesar el pedido.",
    id: "Terjadi kesalahan saat memproses pesanan.",
    ["pt-br"]: "Ocorreu um erro ao processar o pedido.",
  },
  "Мы активно следим за всеми рефералами, которых вы приглашаете. Для этого мы используем несколько показателей, которые по объективным причинам мы не раскрываем. Настоятельно не рекомендуем использовать неактивных рефералов или ботов - за таких пользователей награда не будет выплачена.":
    {
      ru: "Мы активно следим за всеми рефералами, которых вы приглашаете. Для этого мы используем несколько показателей, которые по объективным причинам мы не раскрываем. Настоятельно не рекомендуем использовать неактивных рефералов или ботов - за таких пользователей награда не будет выплачена.",
      en: "We actively monitor all referrals you invite. For this purpose we use several indicators, which for objective reasons we do not disclose. We strongly discourage the use of inactive referrals or bots - no reward will be paid for such users.",
      fr: "Nous surveillons activement toutes les références que vous invitez. À cette fin, nous utilisons plusieurs indicateurs que, pour des raisons objectives, nous ne divulguons pas. Nous décourageons fortement l'utilisation de référents inactifs ou de robots - aucune récompense ne sera versée pour ces utilisateurs. ",
      es: "Hacemos un seguimiento activo de todas las referencias que usted invita. Para ello, utilizamos varios indicadores, que por razones objetivas no revelamos. Desaconsejamos encarecidamente el uso de referidos inactivos o bots - no se pagará ninguna recompensa por dichos usuarios.",
      "es-l":
        "Hacemos un seguimiento activo de todas las referencias que usted invita. Para ello, utilizamos varios indicadores, que por razones objetivas no revelamos. Desaconsejamos encarecidamente el uso de referidos inactivos o bots - no se pagará ninguna recompensa por dichos usuarios.",
      id: "Kami memantau semua referral yang kamu undang secara aktif menggunakan beberapa indikator, yang tidak akan kami ungkapkan karena alasan keamanan. Kami sangat tidak menganjurkan penggunaan referral atau bot yang tidak aktif, karena hadiah tidak akan diberikan untuk pengguna tersebut.",
      ["pt-br"]:
        "Monitoramos ativamente todas as indicações que você convida. Para esse fim, usamos vários indicadores que, por razões objetivas, não divulgamos. Desencorajamos fortemente o uso de indicações inativas ou bots - nenhuma recompensa será paga por esses usuários.",
    },
  "При многократном нарушении правил игроки будут забанены.": {
    ru: "При многократном нарушении правил игроки будут забанены.",
    en: "Players will be banned if they violate the rules multiple times.",
    fr: "Les joueurs seront bannis s'ils enfreignent les règles à plusieurs reprises.",
    es: "Los jugadores serán baneados si cometen un error más de una vez.",
    "es-l": "Los jugadores serán baneados si cometen un error más de una vez.",
    id: "Pelanggaran aturan yang berulang akan mengakibatkan pemblokiran terhadap pemain.",
    ["pt-br"]: "Jogadores serão banidos se cometirem erros mais de uma vez.",
  },
  "Не хватает ресурсов!": {
    ru: "Не хватате ресурсов!",
    en: "Not enough resources!",
    fr: "Des ressources insuffisantes!",
    es: "No hay suficientes recursos!",
    "es-l": "No hay suficientes recursos!",
    id: "Kurang bahan!",
    ["pt-br"]: "Não há recursos suficientes!",
  },
  ВКЛ: {
    ru: "ВКЛ",
    en: "ON",
    fr: "ACTIVER",
    es: "ENCENDER",
    "es-l": "ENCENDER",
    id: "NYALAKAN",
    ["pt-br"]: "LIGAR",
  },
  ВЫКЛ: {
    ru: "ВЫКЛ",
    en: "OFF",
    fr: "DÉSACTIVER",
    es: "Apagar",
    "es-l": "Apagar",
    id: "Matikan",
    ["pt-br"]: "DESLIGAR",
  },
  ЗВУК: {
    ru: "ЗВУК",
    en: "SOUND",
    fr: "SON",
    es: "Sonido",
    "es-l": "Sonido",
    id: "Suara",
    ["pt-br"]: "SOM",
  },
  НАСТРОЙКИ: {
    ru: "НАСТРОЙКИ",
    en: "SETTINGS",
    fr: "PARAMÈTRES",
    es: "CONFIGURACIÓN",
    "es-l": "CONFIGURACIÓN",
    id: "PENGATURAN",
    ["pt-br"]: "CONFIGURAÇÃO",
  },
  УЛУЧШЕНИЕ: {
    ru: "УЛУЧШЕНИЕ",
    en: "UPGRADE",
    fr: "MISE À NIVEAU",
    es: "MEJORAR",
    "es-l": "MEJORAR",
    id: "UPGRADE",
    ["pt-br"]: "AMIGLIORAR",
  },
  Улучшить: {
    ru: "Улучшить",
    en: "Upgrade",
    fr: "Améliorer",
    es: "Mejorar",
    "es-l": "Mejorar",
    id: "Upgrade",
    ["pt-br"]: "Amíliorar",
  },
  "УЛУЧШЕНИЕ ЗДАНИЯ ДОБЫЧИ МОНЕТ": {
    ru: "УЛУЧШЕНИЕ ЗДАНИЯ ДОБЫЧИ МОНЕТ",
    en: "UPGRADE COIN PRODUCTION BUILDING",
    fr: "AMÉLIORATION DU BÂTIMENT DE PRODUCTION DE PIÈCES",
    es: "AMÉLIORATION DU BÂTIMENT DE PRODUCTION DE PIÈCES",
    "es-l": "AMÉLIORATION DU BÂTIMENT DE PRODUCTION DE PIÈCES",
    id: "PERKAYAAN PRODUKSI PIKET",
    ["pt-br"]: "AMIGLIAR BATALHA DE PRODUCAO DE MOEDAS",
  },
  "Требуется:": {
    ru: "Требуется:",
    en: "Required:",
    fr: "Requis :",
    es: "Requerido:",
    "es-l": "Requerido:",
    id: "Diperlukan:",
    ["pt-br"]: "Requerido:",
  },
  ПРИВЕТСТВИЕ: {
    ru: "ПРИВЕТСТВИЕ",
    en: "WELCOME",
    fr: "ACCUEIL",
    es: "SALUDO",
    "es-l": "SALUDO",
    id: "SELSALAMAM",
    ["pt-br"]: "BEM-VINDO",
  },
  Забрать: {
    ru: "Забрать",
    en: "Claim",
    fr: "Réclamer",
    es: "Reclamar",
    "es-l": "Reclamar",
    id: "Klaim",
    ["pt-br"]: "Reclamar",
  },
  "ДОБРО ПОЖАЛОВАТЬ В ИГРУ. ВОТ ВАШ ПОДАРОК!": {
    ru: "ДОБРО ПОЖАЛОВАТЬ В ИГРУ. ВОТ ВАШ ПОДАРОК!",
    en: "WELCOME TO THE GAME. HERE'S YOUR GIFT!",
    fr: "BIENVENUE : BIENVENUE DANS LE JEU. VOICI VOTRE CADEAU !",
    es: "BIENVENIDO: BIENVENIDO AL JUEGO. ¡AQUÍ TIENES TU REGALO!",
    "es-l": "BIENVENIDO: BIENVENIDO AL JUEGO. ¡AQUÍ ESTÁ TU REGALO!",
    id: "SELAMAT DATANG: SELAMAT DATANG DI GAME INI. TERIMALAH HADIAH UNTUKMU!",
    ["pt-br"]: "BEM-VINDO AO JOGO. AQUI ESTÁ SEU PRESENTE!",
  },
  "Подарок:": {
    ru: "Подарок:",
    en: "Gift:",
    fr: "Cadeau :",
    es: "Regalo:",
    "es-l": "Regalo:",
    id: "Hadiah:",
    ["pt-br"]: "Presente:",
  },
  "ОШИБКА!": {
    ru: "ОШИБКА!",
    en: "ERROR!",
    fr: "ERREUR!",
    es: "¡ERROR!",
    "es-l": "¡ERROR!",
    id: "ERROR!",
    ["pt-br"]: "ERRO!",
  },
  Переподключится: {
    ru: "Переподключиться",
    en: "Reconnect",
    fr: "Reconnecter",
    es: "Reconectar",
    "es-l": "Reconectar",
    id: "Reconnect",
    ["pt-br"]: "Reconectar",
  },
  "ОШИБКА СОЕДИНЕНИЯ! ПОЖАЛУЙСТА ПЕРЕЗАЙДИТЕ!": {
    ru: "ОШИБКА СОЕДИНЕНИЯ! ПОЖАЛУЙСТА, ПЕРЕЗАЙДИТЕ!",
    en: "CONNECTION ERROR! PLEASE RELOGIN!",
    fr: "ERREUR DE CONNEXION! VEUILLEZ VOUS RECONNECTER!",
    es: "¡ERROR DE CONEXIÓN! ¡REINCORPÓRATE, POR FAVOR!",
    "es-l": "¡ERROR DE CONEXIÓN! ¡REINCORPÓRATE, POR FAVOR!",
    id: "KESALAHAN KONEKSI! SILAKAN BERGABUNG KEMBALI!",
    ["pt-br"]: "ERRO DE CONEXÃO! POR FAVOR, REENTRE!",
  },
  Использовать: {
    ru: "Использовать",
    en: "Use",
    fr: "Utiliser",
    es: "Usar",
    "es-l": "Usar",
    id: "Gunakan",
    ["pt-br"]: "Usar",
  },
  "ВЫ УВЕРЕНЫ, ЧТО ГОТОВЫ ПРИМЕНИТЬ БУСТЕР?": {
    ru: "ВЫ УВЕРЕНЫ, ЧТО ГОТОВЫ ПРИМЕНИТЬ БУСТЕР?",
    en: "ARE YOU SURE YOU WANT TO USE THE BOOSTER?",
    fr: "VOUS ÊTES SÛR D’ÊTRE PRÊT À UTILISER LE BOOSTER ?",
    es: "¿SEGURO QUE ESTÁS LISTO PARA UTILIZAR EL BOOSTER?",
    "es-l": "¿SEGURO QUE ESTÁS LISTO PARA UTILIZAR EL BOOSTER?",
    id: "YAKIN KAMU SIAP MENGGUNAKAN BOOSTER INI?",
    ["pt-br"]: "É VOCE QUE DESEJA USAR O BOOSTER?",
  },
  НАГРАДА: {
    ru: "НАГРАДА",
    en: "REWARD",
    fr: "RÉCLAMATION",
    es: "RECOMPENSA",
    "es-l": "RECOMPENSA",
    id: "HADIAH",
    ["pt-br"]: "PRESENTE",
  },
  "ВЫ ПОЛУЧИЛИ НАГРАДУ ЗА ОТКРЫТИЕ СУНДУКА!": {
    ru: "ВЫ ПОЛУЧИЛИ НАГРАДУ ЗА ОТКРЫТИЕ СУНДУКА!",
    en: "YOU RECEIVED A REWARD FOR OPENING THE CHEST!",
    fr: "VOUS AVEZ REÇU UNE RÉCOMPENSE POUR AVOIR OUVERT LE COFFRE!",
    es: "¡HAS RECIBIDO UNA RECOMPENSA POR ABRIR EL COFRE!",
    "es-l": "¡RECIBISTE UNA RECOMPENSA POR ABRIR EL COFRE!",
    id: "KAMU MENERIMA HADIAH KARENA TELAH MEMBUKA PETI!",
    ["pt-br"]: "VOCE RECEBEU UMA PRESENTE POR ABRIR O COFRE!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ",
    en: "YOU SUCCESSFULLY PURCHASED",
    fr: "VOUS AVEZ ACHETÉ AVEC SUCCÈS",
    es: "HAS COMPRADO CON ÉXITO",
    "es-l": "HAS REALIZADO LA COMPRA CON ÉXITO",
    id: "KAMU SUDAH BERHASIL MEMBELI",
    ["pt-br"]: "VOCE COMPREU CON SUCESSO",
  },
  "КОРАБЛЬ УСПЕШНО ВЕРНУЛСЯ И ЗАРАБОТАЛ СЛЕДУЮЩУЮ НАГРАДУ!": {
    ru: "КОРАБЛЬ ВЕРНУЛСЯ С ЗАРАБОТАННОЙ ДЛЯ ВАС НАГРАДОЙ!",
    en: "THE SHIP SUCCESSFULLY RETURNED AND EARNED THE FOLLOWING REWARD!",
    fr: "LE VAISSEAU EST REVENU AVEC SUCCÈS ET A REMPORTÉ LA NOUVELLE RÉCOMPENSE !",
    es: "¡EL BARCO HA REGRESADO CON ÉXITO Y HA OBTENIDO LA SIGUIENTE RECOMPENSA!",
    "es-l": "¡EL BARCO REGRESÓ CON ÉXITO Y OBTUVO LA SIGUIENTE RECOMPENSA!",
    id: "KAPAL BERHASIL KEMBALI DAN MENDAPATKAN HADIAH BERIKUTNYA!",
    ["pt-br"]: "O NAVIO RETORNOU COM SUCESSO E RECEBEU A RECOMPENSA A SEGUIR!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 2Х БУСТЕР НА 3 ЧАСА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 2Х БУСТЕР НА 3 ЧАСА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED A 2X BOOSTER FOR 3 HOURS!",
    fr: "VOUS AVEZ RÉUSSI À ACHETER UN BOOSTER 2X POUR 3 HEURES!",
    es: "2X BOOSTER PARA 3 HORAS",
    "es-l": "2X POTENCIADORES PARA 3 HORAS",
    id: "2X BOOSTER SELAMA 3 JAM",
    ["pt-br"]: "2X BOOSTER PARA 3 HORAS",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 2Х БУСТЕР НА 6 ЧАСА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 2Х БУСТЕР НА 6 ЧАСОВ!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED A 2X BOOSTER FOR 6 HOURS!",
    fr: "VOUS AVEZ RÉUSSI À ACHETER UN BOOSTER 2X POUR 6 HEURES!",
    es: "¡HAS COMPRADO CON ÉXITO 2X POTENCIADORES PARA 6 HORAS!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 2X POTENCIADORES PARA 6 HORAS!",
    id: "ANDA TELAH BERHASIL MEMBELI BOOSTER 2X SELAMA 6 JAM!",
    ["pt-br"]: "2X BOOSTER PARA 6 HORAS",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 2Х БУСТЕР НА 9 ЧАСА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 2Х БУСТЕР НА 9 ЧАСОВ!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED A 2X BOOSTER FOR 9 HOURS!",
    fr: "VOUS AVEZ RÉUSSI À ACHETER UN BOOSTER 2X POUR 9 HEURES!",
    es: "¡HAS COMPRADO CON ÉXITO 2X POTENCIADORES PARA 9 HORAS!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 2X POTENCIADORES PARA 9 HORAS!",
    id: "ANDA TELAH BERHASIL MEMBELI BOOSTER 2X SELAMA 9 JAM!",
    ["pt-br"]: "2X BOOSTER PARA 9 HORAS",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 2Х БУСТЕР НА 12 ЧАСА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 2Х БУСТЕР НА 12 ЧАСОВ!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED A 2X BOOSTER FOR 12 HOURS!",
    fr: "VOUS AVEZ RÉUSSI À ACHETER UN BOOSTER 2X POUR 12 HEURES!",
    es: "¡HAS COMPRADO CON ÉXITO 2X POTENCIADORES PARA 12 HORAS!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 2X POTENCIADORES PARA 12 HORAS!",
    id: "ANDA TELAH BERHASIL MEMBELI BOOSTER 2X SELAMA 12 JAM!",
    ["pt-br"]: "2X BOOSTER PARA 12 HORAS",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 3 БУСТЕРОВ АВТОСБОРА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 3 БУСТЕРА АВТОСБОРА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED 3 AUTO BOOSTERS!",
    fr: "VOUS AVEZ RÉUSSIÀ ACHETER 3 BOOSTER AUTOMATIQUE!",
    es: "¡HAS COMPRADO CON ÉXITO 3 BOOSTER AUTOCOLECCIÓN!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 3 BOOSTER AUTOCOLECCIÓN!",
    id: "ANDA TELAH BERHASIL MEMBELI BOOSTER KOLEKSI OTOMATIS 3!",
    ["pt-br"]: "VOCÊ ADQUIRIU COM SUCESSO 3 BOOSTERS DE COLETA AUTOMÁTICA!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 9 БУСТЕРОВ АВТОСБОРА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 9 БУСТЕРОВ АВТОСБОРА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED 9 AUTO BOOSTERS!",
    fr: "VOUS AVEZ RÉUSSIÀ ACHETER 9 BOOSTER AUTO!",
    es: "¡HAS COMPRADO CON ÉXITO 9 BOOSTER AUTOCOLECCIÓN!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 9 BOOSTER AUTOCOLECCIÓN!",
    id: "ANDA TELAH BERHASIL MEMBELI BOOSTER KOLEKSI OTOMATIS 9!",
    ["pt-br"]: "VOCÊ ADQUIRIU COM SUCESSO 9 BOOSTERS DE COLETA AUTOMÁTICA!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 15 БУСТЕРОВ АВТОСБОРА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 15 БУСТЕРОВ АВТОСБОРА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED 15 AUTO BOOSTERS!",
    fr: "VOUS AVEZ RÉUSSIÀ ACHETER 15 BOOSTER AUTO!",
    es: "¡HAS COMPRADO CON ÉXITO 15 BOOSTER AUTOCOLECCIÓN!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 15 BOOSTER AUTOCOLECCIÓN!",
    id: "ANDA TELAH BERHASIL MEMBELI BOOSTER KOLEKSI OTOMATIS 15!",
    ["pt-br"]: "VOCÊ ADQUIRIU COM SUCESSO 15 BOOSTERS DE COLETA AUTOMÁTICA!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 20 БУСТЕРОВ АВТОСБОРА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 20 БУСТЕРОВ АВТОСБОРА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED 20 AUTO BOOSTERS!",
    fr: "VOUS AVEZ RÉUSSIÀ ACHETER 20 BOOSTER AUTO!",
    es: "¡HAS COMPRADO CON ÉXITO 20 BOOSTER AUTOCOLECCIÓN!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 20 BOOSTER AUTOCOLECCIÓN!",
    id: "ANDA TELAH BERHASIL MEMBELI BOOSTER KOLEKSI OTOMATIS 20!",
    ["pt-br"]: "VOCÊ ADQUIRIU COM SUCESSO 20 BOOSTERS DE COLETA AUTOMÁTICA!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 1200 ЖЕМЧУГА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 1200 ЖЕМЧУГА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED 1200 PEARLS!",
    fr: "VOUS AVEZ RÉUSSIÀ ACHETER 1200 PERLES!",
    es: "¡HAS COMPRADO CON ÉXITO 1200 PERLAS!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 1200 PERLAS!",
    id: "ANDA TELAH BERHASIL MEMBELI MUTIARA 1200!",
    ["pt-br"]: "VOCÊ ADQUIRIU COM SUCESSO 1200 PÉROLAS!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 3999 ЖЕМЧУГА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 3999 ЖЕМЧУГА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED 3999 PEARLS!",
    fr: "VOUS AVEZ RÉUSSIÀ ACHETER 3999 PERLES!",
    es: "¡HAS COMPRADO CON ÉXITO 3999 PERLAS!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 3999 PERLAS!",
    id: "ANDA TELAH BERHASIL MEMBELI MUTIARA 3999!",
    ["pt-br"]: "VOCÊ ADQUIRIU COM SUCESSO 3999 PÉROLAS!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 8999 ЖЕМЧУГА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 8999 ЖЕМЧУГА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED 8999 PEARLS!",
    fr: "VOUS AVEZ RÉUSSIÀ ACHETER 8999 PERLES!",
    es: "¡HAS COMPRADO CON ÉXITO 8999 PERLAS!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 8999 PERLAS!",
    id: "ANDA TELAH BERHASIL MEMBELI MUTIARA 8999!",
    ["pt-br"]: "VOCÊ ADQUIRIU COM SUCESSO 8999 PÉROLAS!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ 15999 ЖЕМЧУГА!": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ 15999 ЖЕМЧУГА!",
    en: "YOU HAVE SUCCESSFULLY PURCHASED 15999 PEARLS!",
    fr: "VOUS AVEZ RÉUSSIÀ ACHETER 15999 PERLES!",
    es: "¡HAS COMPRADO CON ÉXITO 15999 PERLAS!",
    "es-l": "¡HAS COMPRADO CON ÉXITO 15999 PERLAS!",
    id: "ANDA TELAH BERHASIL MEMBELI MUTIARA 15999!",
    ["pt-br"]: "VOCÊ ADQUIRIU COM SUCESSO 15999 PÉROLAS!",
  },
  "Награда:": {
    ru: "Награда:",
    en: "Reward:",
    fr: "Récompense :",
    es: "Recompensa:",
    "es-l": "Recompensa:",
    id: "Hadiah:",
    ["pt-br"]: "Recompensa:",
  },
  ПОСТРОЙКА: {
    ru: "ПОСТРОЙКА",
    en: "CONSTRUCTION",
    fr: "CONSTRUCTION",
    es: "CONSTRUCCIÓN",
    "es-l": "CONSTRUCCIÓN",
    id: "PEMBANGUNAN",
    ["pt-br"]: "CONSTRUÇÃO",
  },
  Построить: {
    ru: "Построить",
    en: "Build",
    fr: "Construire",
    es: "Construir",
    "es-l": "Construir",
    id: "Bangun",
    ["pt-br"]: "Construir",
  },
  "ПОСТРОЙКА ЗДАНИЯ ДОБЫЧИ МОНЕТ": {
    ru: "ПОСТРОЙКА ЗДАНИЯ ДОБЫЧИ МОНЕТ",
    en: "BUILDING COIN PRODUCTION STRUCTURE",
    fr: "CONSTRUIRE LE BATIMENT DE PRODUCTION DE PIÈCES",
    es: "EDIFICIO DE PRODUCCIÓN DE MONEDAS",
    "es-l": "EDIFICIO DE PRODUCCIÓN DE MONEDAS",
    id: "MEMBANGUN BANGUNAN PRODUKSI KOIN",
    ["pt-br"]: "CONSTRUINDO UM PRÉDIO DE PRODUÇÃO DE MOEDAS",
  },
  Вы: {
    ru: "Вы",
    en: "You",
    fr: "Vous",
    es: "Usted",
    "es-l": "Usted",
    id: "Anda",
    ["pt-br"]: "VOCÊ",
  },
  ЗОЛОТО: {
    ru: "ЗОЛОТО",
    en: "GOLD",
    fr: "OR",
    es: "ORO",
    "es-l": "ORO",
    id: "EMAS",
    ["pt-br"]: "OURO",
  },
  СЕРЕБРО: {
    ru: "СЕРЕБРО",
    en: "SILVER",
    fr: "ARGENT",
    es: "PLATA",
    "es-l": "PLATA",
    id: "PERAK",
    ["pt-br"]: "PRATA",
  },
  БРОНЗА: {
    ru: "БРОНЗА",
    en: "BRONZE",
    fr: "BRONZE",
    es: "BRONCE",
    "es-l": "BRONCE",
    id: "PERUNGGU",
    ["pt-br"]: "BRONZE",
  },
  "ОТКРОЙ СУНДУК": {
    ru: "ОТКРОЙ СУНДУК",
    en: "OPEN CHEST",
    fr: "OUVRIR LE COFFRE",
    es: "ABRIR CESTA",
    "es-l": "ABRIR CESTA",
    id: "BUKA TERBUKA",
    ["pt-br"]: "ABRIR CAIXA",
  },
  "Нужен ключ!": {
    ru: "Нужен ключ!",
    en: "No Key!",
    fr: "Pas de clés !",
    es: "¡Sin llaves!",
    "es-l": "¡Sin llaves!",
    id: "Tidak ada kunci!",
    ["pt-br"]: "Não tem Chave!",
  },
  "Купить ключ за 200 Жемчуга": {
    ru: "Купить ключ за 200 жемчуга",
    en: "Buy a key for 200 pearls",
    fr: "Acheter une clé pour 100 perles",
    es: "Comprar una llave por 100 Perlas",
    "es-l": "Comprar una llave por 100 Perlas",
    id: "Beli kunci seharga 100 Mutiara",
    ["pt-br"]: "Comprar uma chave para 200 pérolas",
  },
  на: {
    ru: "на",
    en: "for",
    fr: "pour",
    es: "para",
    "es-l": "para",
    id: "untuk",
    ["pt-br"]: "para",
  },
  " час": {
    ru: " час",
    en: " hour",
    fr: " heure",
    es: "hora",
    "es-l": "hora",
    id: "jam",
    ["pt-br"]: "hora",
  },
  " часа": {
    ru: " часа",
    en: " hours",
    fr: " heures",
    es: "horas",
    "es-l": "horas",
    id: "jams",
    ["pt-br"]: "horas",
  },
  " часов": {
    ru: " часов",
    en: " hours",
    fr: " heures",
    es: "horas",
    "es-l": "horas",
    id: "jams",
    ["pt-br"]: "horas",
  },
  "Сбросить аккаунт": {
    ru: "Сбросить аккаунт",
    en: "Reset account",
    fr: "Réinitialiser le compte",
    es: "Reiniciar cuenta",
    "es-l": "Reiniciar cuenta",
    id: "Reset akun",
    ["pt-br"]: "Redefinir conta",
  },
  "Terms and conditions": {
    ru: "Terms and Conditions",
    en: "Terms and Conditions",
    fr: "Conditions générales",
    es: "Términos y condiciones",
    "es-l": "Términos y condiciones",
    id: "Syarat dan Ketentuan",
    ["pt-br"]: "Termos e condições",
  },
  "Privacy Policy": {
    ru: "Privacy Policy",
    en: "Privacy Policy",
    fr: "Politique de confidentialité",
    es: "Política de privacidad",
    "es-l": "Política de privacidad",
    id: "Kebijakan Privasi",
    ["pt-br"]: "Política de privacidade",
  },
  "Привязать кошелёк": {
    ru: "Привязать кошелёк",
    en: "Connect wallet",
    fr: "Portefeuille de liens",
    es: "Enlace al Monedero",
    "es-l": "Enlace a la Billetera",
    id: "Dompet Tautan",
    ["pt-br"]: "Conectar carteira",
  },
  "Справка об игре": {
    ru: "Справка об игре",
    en: "Game Synopsis",
    fr: "Aide du jeu",
    es: "Ayuda del juego",
    "es-l": "Ayuda del juego",
    id: "Bantuan Game",
    ["pt-br"]: "Sinopse do jogo",
  },
  "За всё время заработано:": {
    ru: "За всё время заработано:",
    en: "All time earned:",
    fr: "Tous les temps gagnés:",
    es: "Ganancias totales:",
    "es-l": "Ingresos totales:",
    id: "Total pendapatan:",
    ["pt-br"]: "Total ganho:",
  },
  Далее: {
    ru: "Далее",
    en: "Next",
    fr: "Suivant",
    es: "Continuar",
    "es-l": "Continuar",
    id: "Lanjut",
    ["pt-br"]: "Continuar",
  },
  Пропустить: {
    ru: "Пропустить",
    en: "Skip",
    fr: "Sauter",
    es: "Saltar",
    "es-l": "Saltar",
    id: "Lewati",
    ["pt-br"]: "Pular",
  },
  "Добро пожаловать на ваш остров, капитан!": {
    ru: "Добро пожаловать на ваш остров, капитан!",
    en: "Welcome to your island, captain!",
    fr: "Bienvenue sur votre île, capitaine!",
    es: "¡Bienvenido a tu isla, capitán!",
    "es-l": "¡Bienvenido a tu isla, capitán!",
    id: "Selamat datang di pulaumu, kapten!",
    ["pt-br"]: "Bem vindo ao seu ilhão, capitaão!",
  },
  "Стройте и улучшайте здания, чтобы получать больше прибыли": {
    ru: "Стройте и улучшайте здания, чтобы получать больше прибыли",
    en: "Build and upgrade buildings to earn more profit",
    fr: "Construisez et améliorez des bâtiments pour gagner plus de profits",
    es: "Construye y mejora tus edificios para obtener más ganancias",
    "es-l": "Construye y mejora los edificios para obtener más beneficios",
    id: "Bangun dan upgrade bangunan untuk menghasilkan keuntungan lebih besar",
    ["pt-br"]: "Construa e melhore os edifiços para obter mais lucros",
  },
  "Постройте здание": {
    ru: "Постройте здание",
    en: "Build a building",
    fr: "Construire un bâtiment",
    es: "Construye un edificio",
    "es-l": "Construye un edificio",
    id: "Bangun sebuah bangunan",
    ["pt-br"]: "Construa um edifiço",
  },
  "Нажмите на постройку для взаимодействия с ней": {
    ru: "Нажмите на постройку для взаимодействия с ней",
    en: "Click on the building to interact with it",
    fr: "Cliquez sur la maison pour interagir",
    es: "Haz clic en el edificio para interactuar con él",
    "es-l": "Haz clic en el edificio para interactuar con él",
    id: "Klik bangunan untuk berinteraksi dengannya",
    ["pt-br"]: "Clique no edifiço para interagir com ele",
  },
  'Нажмите кнопку "Построить"': {
    ru: 'Нажмите кнопку "Построить"',
    en: "Click the 'Build' button",
    fr: "Cliquez sur le bouton «Construire»",
    es: "Haz clic en el botón 'Construir'",
    "es-l": "Haz clic en el botón 'Construir'",
    id: "Klik tombol 'Bangun'",
    ["pt-br"]: "Clique no botão 'Construir'",
  },
  "Поздравляем, вы построили своё первое здание!": {
    ru: "Поздравляем, вы построили своё первое здание!",
    en: "Congratulations, you've built your first building!",
    fr: "Félicitations, vous avez construit votre premier bâtiment!",
    es: "Felicidades, has construido tu primer edificio!",
    "es-l": "Felicidades, has construido tu primer edificio!",
    id: "Selamat, Anda telah membangun bangunan pertama Anda!",
    ["pt-br"]: "Parabéns, construimos o seu primeiro edifiço!",
  },
  "Оно уже начало зарабатывать вам золото! Каждые 3 часа вам нужно забирать заработанное  золото, иначе здание перестанет зарабатывать":
    {
      ru: "Оно уже начало приносить вам золото! Каждые 3 часа вам нужно забирать золото, иначе здание переполнится",
      en: "It has started earning you gold! Every 3 hours, you need to collect the earned gold, or the building will stop earning",
      fr: "Il a déjà commencé à vous faire gagner de l'or! Toutes les 3 heures, vous devez ramasser l'or que vous avez gagné, sinon le bâtiment cessera de vous rapporter de l'or",
      es: "¡Ya has empezado a ganar oro! Cada 3 horas tienes que recoger el oro ganado, de lo contrario, el edificio dejará de ganar",
      "es-l":
        "¡Ya empezaste a ganar oro! Cada 3 horas tienes que recolectar el oro ganado, de lo contrario, el edificio dejará de ganar",
      id: "Bangunan ini sudah mulai menghasilkan emas untukmu! Setiap 3 jam kamu perlu mengumpulkan emas yang diperoleh, jika tidak, bangunan akan berhenti menghasilkan emas.",
      ["pt-br"]:
        "Ele já começou a lhe render ouro! A cada 3 horas você precisa pegar o ouro que ganhou, ou o prédio deixará de ganhar",
    },
  "Заберите собранное золото!": {
    ru: "Заберите собранное золото!",
    en: "Collect the gathered gold!",
    fr: "Récupérez l'or collecté!",
    es: "Recoge el oro recolectado!",
    "es-l": "Recoge el oro recolectado!",
    id: "Kumpulkan emas yang sudah diperoleh!",
    ["pt-br"]: "Coleta o ouro coletado!",
  },
  Настройки: {
    ru: "Настройки",
    en: "Settings",
    fr: "Paramètres",
    es: "Ajustes",
    "es-l": "Ajustes",
    id: "Pengaturan",
    ["pt-br"]: "Configurações",
  },
  "Каждые 3 часа здание полностью заполняется золотом, не забывайте забирать его":
    {
      ru: "Каждые 3 часа здание полностью забивается золотом, не забывайте забирать его",
      en: "Every 3 hours the building is filled with gold, don't forget to collect it",
      fr: "Toutes les 3 heures, le bâtiment est entièrement rempli d'or, n'oubliez pas de le ramasser",
      es: "Cada 3 horas el edificio se llena completamente con oro, no olvide recogerlo",
      "es-l":
        "Cada 3 horas el edificio se llena completamente con oro, no olvide recogerlo",
      id: "Setiap 3 jam sekali, bangunan akan terisi penuh dengan emas, jangan lupa untuk mengumpulkannya",
      ["pt-br"]:
        "A cada 3 horas, o prédio é completamente preenchido com ouro, não se esqueça de pegá-lo",
    },
  "Здания можно улучшить": {
    ru: "Здания нужно улучшать",
    en: "Buildings can be upgraded",
    fr: "Les bâtiments peuvent être améliorés",
    es: "Los edificios se pueden mejorar",
    "es-l": "Los edificios se pueden mejorar",
    id: "Bangunan bisa di-upgrade",
    ["pt-br"]: "Os prédios podem ser melhorados",
  },
  'Нажмите на кнопку "Улучшить", чтобы узнать сколько ресурсов нужно для улучшения здания':
    {
      ru: 'Нажмите на кнопку "УЛУЧШИТЬ", чтобы узнать сколько ресурсов необходимо для улучшения здания',
      en: "Click the 'Upgrade' button to see how many resources are needed to upgrade the building",
      fr: "Cliquez sur le bouton Mettre à niveau pour savoir combien de ressources sont nécessaires pour améliorer le bâtiment",
      es: "Haz clic en el botón Mejorar para saber cuántos recursos se necesitan para mejorar el edificio",
      "es-l":
        "Haz clic en el botón Mejorar para saber cuántos recursos se necesitan para mejorar el edificio",
      id: "Klik tombol Upgrade untuk mengetahui berapa banyak sumber daya yang dibutuhkan untuk mengupgrade bangunan",
      ["pt-br"]:
        " Clique no botão Improve (Melhorar) para saber quantos recursos você precisa para melhorar o edifício",
    },
  Порт: {
    ru: "Порт",
    en: "Harbour",
    fr: "Port",
    es: "Puerto",
    "es-l": "Puerto",
    id: "Pelabuhan",
    ["pt-br"]: "Porto",
  },
  "1. Название и контактные данные разработчиков": {
    ru: "1. Название и контактные данные разработчиков",
    en: "1. Name and Contact Information of the Developers",
    fr: "1. Nom et coordonnées des développeurs",
    es: "1. Nombre y información de los Desarrolladores",
    "es-l": "1. Nombre y información de los Desarrolladores",
    id: "1. Nama dan Informasi Kontak Pengembang",
    ["pt-br"]: "1. Nome e Informação de Desenvolvedores",
  },
  "2. Типы собираемых данных": {
    ru: "2. Типы собираемых данных",
    en: "2. Types of Data Collected",
    fr: "2. Types de données collectées",
    es: "2. Tipos de datos recogidos",
    "es-l": "2. Tipos de datos recogidos",
    id: "2. Jenis data yang dikumpulkan",
    ["pt-br"]: "2. Tipos de dados coletados",
  },
  "3. Цели сбора данных": {
    ru: "3. Цели сбора данных",
    en: "3. Purposes of Data Collection",
    fr: "3. Objectifs de la collecte de données",
    es: "3. Propositos de recogida de datos",
    "es-l": "3. Propositos de recogida de datos",
    id: "3. Tujuan pengumpulan data",
    ["pt-br"]: "3. Propositos de coleta de dados",
  },
  "4. Передача данных третьим лицам": {
    ru: "4. Передача данных третьим лицам",
    en: "4. Data Sharing with Third Parties",
    fr: "4. Partage des données avec des tiers",
    es: "4. Compartir datos con terceros",
    "es-l": "4. Compartir datos con terceros",
    id: "4. Penggunaan data dengan pihak ketiga",
    ["pt-br"]: "4. Compartilhamento de dados com terceiros",
  },
  "5. Безопасность данных": {
    ru: "5. Безопасность данных",
    en: "5. Data Security",
    fr: "5. Sécurité des données",
    es: "5. Seguridad de los datos",
    "es-l": "5. Seguridad de los datos",
    id: "5. Keamanan data",
    ["pt-br"]: "5. Segurança de dados",
  },
  "6. Срок хранения данных": {
    ru: "6. Срок хранения данных",
    en: "6. Data Retention Period",
    fr: "6. Période de conservation des données",
    es: "6. Período de retenciòn de los datos",
    "es-l": "6. Período de retenciòn de los datos",
    id: "6. Periode pengelolaan data",
    ["pt-br"]: "6. Período de retenciòn de dados",
  },
  "7. Права пользователей": {
    ru: "7. Права пользователей",
    en: "7. User Rights",
    fr: "7. Droits de l'utilisateur",
    es: "7. Derechos de usuario",
    "es-l": "7. Derechos de usuario",
    id: "7. Hak pengguna",
    ["pt-br"]: "7. Direitos de usuário",
  },
  "8. Изменения в политике конфиденциальности": {
    ru: "8. Изменения в политике конфиденциальности",
    en: "8. Changes to the Privacy Policy",
    fr: "8. Modifications de la politique de confidentialité",
    es: "8. Cambios en la política de privacidad",
    "es-l": "8. Cambios en la política de privacidad",
    id: "8. Perubahan pada kebijakan privasi",
    ["pt-br"]: "8. Alterações na POLITICA DE PRIVACIDADE",
  },
  "Вы можете отправить корабль на добычу золота и жемчуга": {
    ru: "Отправляйте корабль на добычу золота и жемчуга",
    en: "You can send a ship to gather gold and pearls",
    fr: "Vous pouvez envoyer le navire collecter de l’or et des perles",
    es: "Puedes enviar el barco a recoger el oro y las perlas",
    "es-l": "Puedes enviar el barco a recoger el oro y las perlas",
    id: "Kamu dapat mengirim kapal untuk mengumpulkan emas dan mutiara",
    ["pt-br"]: "Pode enviar um navio para coletar ouro e perlas",
  },
  "Порт. Постройте здание": {
    ru: "Порт. Постройте здание",
    en: "Harbour. Build a building",
    fr: "Port. Construire un bâtiment",
    es: "Puerto. Construye un edificio",
    "es-l": "Puerto. Construye un edificio",
    id: "Pelabuhan. Bangun sebuah bangunan",
    ["pt-br"]: "Port. Construa um edifiço",
  },
  'Для начала, давайте построим здание "Порт"': {
    ru: 'Для начала, давайте построим здание "Порт"',
    en: "First, let's build the 'Harbour' building",
    fr: "Pour commencer, construisez le bâtiment 'Port'",
    es: "Para comenzar, construir el edificio 'Puerto'",
    "es-l": "Para comenzar, construir el edificio 'Puerto'",
    id: "Pertama, mari buat bangunan 'Pelabuhan'",
    ["pt-br"]: "Primeiro, construa o edifiço 'Port'",
  },
  Путешествие: {
    ru: "Путешествие",
    en: "Journey",
    fr: "Voyage",
    es: "Viaje",
    "es-l": "Viaje",
    id: "Penjelajahan",
    ["pt-br"]: "Viagem",
  },
  "Давайте отправим корабль в путешествие, нажмите на свиток": {
    ru: "Давайте отправим корабль в путешествие, нажмите на свиток",
    en: "Let's send the ship on a journey, click on the scroll",
    fr: "Envoyons le navire en voyage, cliquez sur le scroll",
    es: "Enviemos el barco de viaje, haz clic en el desplazamiento",
    "es-l": "Enviemos el barco de viaje, haz clic en el desplazamiento",
    id: "Ayo kirim kapal dalam penjelajahan, klik pada gulungan",
    ["pt-br"]: "Envie o navio para uma viagem, clique no rolagem",
  },
  "Удачного путешествия капитан!": {
    ru: "Удачного путешествия, капитан!",
    en: "Safe travels, captain!",
    fr: "Bon voyage, capitaine!",
    es: "¡Buen viaje, capitán!",
    "es-l": "¡Buen viaje, capitán!",
    id: "Selamat jalan, kapten!",
    ["pt-br"]: "Viagem segura, capitaão!",
  },
  "Развивайте базу, чтобы получить больше золота и жемчуга": {
    ru: "Развивайте базу, чтобы получить больше золота и жемчуга",
    en: "Develop your base to get more gold and pearls",
    fr: "Developpez votre base pour obtenir plus d'or et de perles",
    es: "Desarrolla la base para ganar más oro y perlas",
    "es-l": "Desarrolla la base para ganar más oro y perlas",
    id: "Kembangkan markas untuk mendapatkan lebih banyak emas dan mutiara",
    ["pt-br"]: "Desenvolva sua base para obter mais ouro e perlas",
  },
  'Это бустер "Множитель" - при использовании он увеличивает добычу золота на указанный множитель в течение определенного времени.':
    {
      ru: 'Это бустер "Множитель" - при использовании он увеличивает добычу золота на указанный множитель в течение определенного времени.',
      en: "This is a 'Multiplier' booster - when used, it increases gold production by the specified multiplier for a certain time.",
      fr: "Il s’agit du booster multiplicateur ; Lorsqu’il est utilisé, il augmente l’extraction d’or du multiplicateur spécifié pendant un certain temps.",
      es: "Este es el booster multiplicador; al utilizarlo, aumenta la minería de oro en el multiplicador especificado durante un tiempo determinado.",
      "es-l":
        "Este es el potenciador multiplicador; al utilizarlo, aumenta la extracción de oro en el multiplicador especificado durante un tiempo determinado.",
      id: "Ini adalah booster Pengganda; bila digunakan, ini akan meningkatkan penambangan emas dengan pengganda yang ditentukan selama waktu tertentu.",
      ["pt-br"]:
        "  Esse é o intensificador “Multiplicador” - quando usado, aumenta a produção de ouro em um multiplicador específico por um período de tempo específico.",
    },
  "Важно знать! Одновременно может работать только один множитель, если будет использован любой другой, то он увеличит таймер текущего!":
    {
      ru: "Важно знать! Одновременно может работать только один множитель, если будет использован любой другой, то он увеличит таймер текущего!",
      en: "Important! Only one multiplier can work at a time, if another one is used, it will extend the timer of the current one!",
      fr: "Important! Seul un multiplicateur peut fonctionner en même temps, si un autre est utilise, il sera extends le timer de celui-ci!",
      es: "¡Importante! Solo un multiplicador puede trabajar en el mismo tiempo, si otro se usa, se extiende el tiempo del actual!",
      "es-l":
        "¡Importante! Solo un multiplicador puede trabajar en el mismo tiempo, si otro se usa, se extiende el tiempo del actual!",
      id: "Perlu diketahui! Hanya satu pengganda dapat bekerja secara bersamaan, jika digunakan oleh yang lain, maka akan memperpanjang timer sekarang!",
      ["pt-br"]:
        "Importante saber! Apenas um multiplicador pode ser executado por vez. Se qualquer outro multiplicador for usado, ele aumentará o cronômetro do multiplicador atual!",
    },
  'Это бустер "Автосбор", он автоматически собирает 2 раза добытое золото с зданий и корабля':
    {
      ru: 'Это бустер "Автосбор", он автоматически собирает 2 раза добытое золото с зданий и корабля',
      en: "This is an 'Auto-collect' booster, it automatically collects twice the amount of gold from buildings and ships",
      fr: "Il s’agit du booster multiplicateur ; Lorsqu’il est utilisé, il augmente l’extraction d’or du multiplicateur spécifié pendant un certain temps.",
      es: "Este es el booster multiplicador; al utilizarlo, aumenta la minería de oro en el multiplicador específico durante un tiempo determinado.",
      "es-l":
        "Este es el potenciador multiplicador; al utilizarlo, aumenta la extracción de oro en el multiplicador específico durante un tiempo determinado.",
      id: "Ini adalah booster Pengganda; bila digunakan, ini akan meningkatkan penambangan emas dengan pengganda yang ditentukan selama waktu tertentu.",
      ["pt-br"]:
        "Esse é o booster “Autocollect”, que coleta automaticamente 2x o ouro saqueado de edifícios e navios",
    },
  "Активация бустера происходит при взаимодействии с зданием!": {
    ru: "Активация бустера происходит при взаимодействии со зданием!",
    en: "The booster activates when you interact with the building!",
    fr: "L'activation du booster se produit lors de l'interaction avec le bateau!",
    es: "El booster activa cuando interactuas con el edificio!",
    "es-l": "El booster activa cuando interactuas con el edificio!",
    id: "Aktivasi booster dilakukan ketika berinteraksi dengan bangunan!",
    ["pt-br"]: "O booster activa quando vocé interagir com o edificio!",
  },
  "Приглашай друзей и зарабатывай Тон!": {
    ru: "Приглашайте друзей и получайте награды",
    en: "Invite friends and earn TON!",
    fr: "Invitez des amis et gagnez TON !",
    es: "¡Invita a tus amigos y gana TON!",
    "es-l": "¡Invita a tus amigos y gana TON!",
    id: "Undang teman dan dapatkan TON!",
    ["pt-br"]: "Convide seus amigos e ganhe TON!",
  },
  "Приглашай друзей и получай ключи!": {
    ru: "Приглашай друзей и получай ключи!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  'Откройте сундук и получите бонус "множитель"!': {
    ru: 'Откройте сундук и получите бустер "Множитель"!',
    en: "Open the chest and get a 'Multiplier' bonus!",
    fr: "Ouvrez le coffre et gagnez un 'booster' 'Multiplicateur'!",
    es: "¡Abre el cofre y recibe la bonificación 'Multiplicador'!",
    "es-l": "¡Abre el cofre y recibe el bono 'Multiplicador'!",
    id: "Buka peti dan dapatkan bonus 'Pengganda'!",
    ["pt-br"]: "Abra o cofre e receba o boleto 'Multiplicador'!",
  },
  "Данный бонус увеличивает добычу золота. Помните, что если у вас активный бонус, то новый продлит его действие, а не заменит его!":
    {
      ru: "Данный бустер увеличивает добычу золота. Помните, что если у вас активен этот бустер, то новый продлит его действие, а не заменит его!",
      en: "This bonus increases gold production. Remember, if you already have an active bonus, the new one will extend its duration, not replace it!",
      fr: "Ce bonus augmente l’extraction d’or. N’oubliez pas que si vous avez un bonus actif, le nouveau bonus prolongera sa durée sans le remplacer !",
      es: "Esta bonificación aumenta la minería de oro. Recuerda, si tienes una bonificación activa, la nueva bonificación prolongará su duración y no la reemplazará.",
      "es-l":
        "Este bono aumenta la extracción de oro. Recuerda, si tienes un bono activo, el nuevo bono prolongará su duración y no lo reemplazará.",
      id: "Bonus ini meningkatkan penambangan emas. Ingat, jika kamu memiliki bonus yang aktif, bonus baru akan memperpanjang durasinya, bukan menggantikannya!",
      ["pt-br"]:
        "Esse bônus aumenta a produção de ouro. Lembre-se de que, se você tiver um bônus ativo, o novo bônus estenderá seu efeito, não o substituirá!",
    },
  "Заполнить дома ресурсами": {
    ru: "Заполнить дома ресурсами",
    en: "Fill the houses with resources",
    fr: "Remplissez les maisons avec des ressources",
    es: "Rellene las casas con recursos",
    "es-l": "Rellene las casas con recursos",
    id: "Isi rumah dengan bahan bangunan",
    ["pt-br"]: "Encher as casas com recursos",
  },
  "Перейти в магазин": {
    ru: "Перейти в магазин",
    en: "Go to the shop",
    fr: "Aller au magasin",
    es: "Ir al tienda",
    "es-l": "Ir al tienda",
    id: "Pergi ke toko",
    ["pt-br"]: "Ir para o loja",
  },
  "Призовой фонд": {
    ru: "Призовой фонд",
    en: "Prize fund",
    fr: "Cagnotte",
    es: "Fondo de premios",
    "es-l": "Fondo de premios",
    id: "Total hadiah",
    ["pt-br"]: "Premiação",
  },
  "Политика конфиденциальности": {
    ru: "Политика конфиденциальности",
    en: "Privacy Policy",
    fr: "Politique de confidentialité",
    es: "Política de privacidad",
    "es-l": "Política de privacidad",
    id: "Kebijakan Privasi",
    ["pt-br"]: "Politica de privacidade",
  },
  "Название и контактные данные разработчиков": {
    ru: "Название и контактные данные разработчиков",
    en: "Developer's Name and Contact Information",
    fr: "Nom et informations de contact des developeurs",
    es: "Nombre y información de contacto de los desarrolladores",
    "es-l": "Nombre y información de contacto de los desarrolladores",
    id: "Namaja dan informasi kontak pengembang",
    ["pt-br"]: "Nome e informação de contato dos desenvolvedores",
  },
  "Типы собираемых данных": {
    ru: "Типы собираемых данных",
    en: "Types of Data Collected",
    fr: "Types de données collectées",
    es: "Tipos de datos recogidos",
    "es-l": "Tipos de datos recogidos",
    id: "Tipe data yang diambil",
    ["pt-br"]: "Tipos de dados coletados",
  },
  "Мы собираем и обрабатываем следующую информацию, предоставляемую пользователями через их аккаунты в Telegram:":
    {
      ru: "Мы собираем и обрабатываем следующую информацию, предоставляемую пользователями через их аккаунты в Telegram:",
      en: "We collect and process the following information provided by users through their Telegram accounts:",
      fr: "Nous collectons et traitons les informations suivantes fournies par les utilisateurs via leurs comptes Telegram :",
      es: "Recopilamos y procesamos la siguiente información proporcionada por los usuarios a traveés de sus cuentas de Telegram:",
      "es-l":
        "Recopilamos y procesamos la siguiente información proporcionada por los usuarios a traveés de sus cuentas de Telegram:",
      id: "Kami mengumpulkan dan memproses informasi berikut yang diberikan oleh pengguna melalui akun Telegram mereka:",
      ["pt-br"]:
        "Coletamos e processamos as seguintes informações fornecidas pelos usuários por meio de suas contas do Telegram:",
    },
  "Имя пользователя": {
    ru: "Имя пользователя",
    en: "Username",
    fr: "Nom d'utilisateur",
    es: "Nombre de usuario",
    "es-l": "Nombre de usuario",
    id: "Username",
    ["pt-br"]: "Nome de usuário",
  },
  "Отображаемое имя": {
    ru: "Отображаемое имя",
    en: "Display Name",
    fr: "Nom d'affichage",
    es: "Nombre de visualización",
    "es-l": "Nombre de visualización",
    id: "Namaam",
    ["pt-br"]: "Nome de exibição",
  },
  "Аватар (если установлен)": {
    ru: "Аватар (если установлен)",
    en: "Avatar (if set)",
    fr: "Avatar (s'il est mis)",
    es: "Avatar (si establecido)",
    "es-l": "Avatar (si establecido)",
    id: "Avatar (jika ditetapkan)",
    ["pt-br"]: "Avatar (se estabelecido)",
  },
  "Номер телефона (если предоставлен пользователем)": {
    ru: "Номер телефона (если предоставлен пользователем)",
    en: "Phone Number (if provided by user)",
    fr: "Numéro de téléphone (si fourni par l'utilisateur)",
    es: "Número de telefono (si proporcionado por el usuario)",
    "es-l": "Número de telefono (si proporcionado por el usuario)",
    id: "Nomor telepon (jika diberikan oleh pengguna)",
    ["pt-br"]: "Telefone (se fornecido pelo usuário)",
  },
  "Данные о внутриигровых действиях и прогрессе": {
    ru: "Данные о внутриигровых действиях и прогрессе",
    en: "In-game actions and progress data",
    fr: "Données d'action et de progression en jeu",
    es: "Datos de acciones y progreso en el juego",
    "es-l": "Datos de acciones y progreso en el juego",
    id: "Data aktifitas dan progres di game",
    ["pt-br"]: "Dados de ações e progresso no jogo",
  },
  "Информация о взаимодействиях пользователей с игрой": {
    ru: "Информация о взаимодействиях пользователей с игрой",
    en: "Information about user interactions with the game",
    fr: "Information sur les interactions des utilisateurs avec le jeu",
    es: "Información sobre las interacciones de los usuarios con el juego",
    "es-l": "Información sobre las interacciones de los usuarios con el juego",
    id: "Informasi tentang interaksi pengguna dengan game",
    ["pt-br"]: "Informação sobre as interações dos usuários com o jogo",
  },
  "Цели сбора данных": {
    ru: "Цели сбора данных",
    en: "Purpose of Data Collection",
    fr: "But de la collecte des données",
    es: "Objetivo de recopilación de datos",
    "es-l": "Objetivo de recopilación de datos",
    id: "Tujuan pengumpulan data",
    ["pt-br"]: "Objetivo da coleta de dados",
  },
  "Собранные данные используются для следующих целей:": {
    ru: "Собранные данные используются для следующих целей:",
    en: "Collected data is used for the following purposes:",
    fr: "Les données collectées sont utilisees pour les besoins suivants :",
    es: "Los datos recopilados se utilizan para los siguientes objetivos:",
    "es-l": "Los datos recopilados se utilizan para los siguientes objetivos:",
    id: "Data yang didapat digunakan untuk tujuan berikut:",
    ["pt-br"]: "Os dados coletados são utilizados para os seguintes fins:",
  },
  "Регистрация и идентификация пользователей": {
    ru: "Регистрация и идентификация пользователей",
    en: "User registration and identification",
    fr: "Enregistrement et identification des utilisateurs",
    es: "Registro y identificación de usuarios",
    "es-l": "Registro y identificación de usuarios",
    id: "Pendaftaran dan identifikasi pengguna",
    ["pt-br"]: "Registro e identificação de usuários",
  },
  "Улучшение качества игрового опыта": {
    ru: "Улучшение качества игрового опыта",
    en: "Improvement of gameplay experience",
    fr: "Amélioration de l'expérience de jeu",
    es: "Mejora de la experiencia de juego",
    "es-l": "Mejora de la experiencia de juego",
    id: "Peningkatan kinerja game",
    ["pt-br"]: "Melhoria da experiência do jogo",
  },
  "Отправка уведомлений и обновлений": {
    ru: "Отправка уведомлений и обновлений",
    en: "Sending notifications and updates",
    fr: "Envoi de notifications et mises à jour",
    es: "Enviando notificaciones y actualizaciones",
    "es-l": "Enviando notificaciones y actualizaciones",
    id: "Pengiriman pemberitahuan dan pembaruan",
    ["pt-br"]: "Envio de notificação e atualizações",
  },
  "Анализ внутриигровых действий": {
    ru: "Анализ внутриигровых действий",
    en: "In-game actions analysis",
    fr: "Analyse des actions en jeu",
    es: "Analisis de acciones en el juego",
    "es-l": "Analisis de acciones en el juego",
    id: "Analisis aktifitas game",
    ["pt-br"]: "Análise das ações dentro do jogo",
  },
  "Предоставление технической поддержки": {
    ru: "Предоставление технической поддержки",
    en: "Providing technical support",
    fr: "Fourniture de soutien technique",
    es: "Proveedores de soporte tecnológico",
    "es-l": "Proveedores de soporte tecnológico",
    id: "Penyediaan dukungan teknis",
    ["pt-br"]: "Provedor de suporte tecnológico",
  },
  "Передача данных третьим лицам": {
    ru: "Передача данных третьим лицам",
    en: "Data sharing with third parties",
    fr: "Partage des données avec les tiers",
    es: "Compartiendo datos con terceros",
    "es-l": "Compartiendo datos con terceros",
    id: "Pengiriman data ke pihak ketiga",
    ["pt-br"]: "Transferência de dados para terceiros",
  },
  "Мы передаем личные данные третьим лицам исключительно на основании добровольных действий пользователей.":
    {
      ru: "Мы передаем личные данные третьим лицам исключительно на основании добровольных действий пользователей.",
      en: "We share personal data with third parties only based on voluntary actions of users.",
      fr: "Nous partageons les données personnelles avec les tiers uniquement en fonction des actions volontaires des utilisateurs.",
      es: "Nos compartimos datos personales solo con terceros basados en las acciones voluntarias de los usuarios.",
      "es-l":
        "Nos compartimos datos personales solo con terceros basados en las acciones voluntarias de los usuarios.",
      id: "Kami berbagi data pribadi dengan pihak ketiga hanya berdasarkan aksi pribadi yang dilakukan oleh pengguna.",
      ["pt-br"]:
        "Nos transferimos os dados pessoais apenas para terceiros baseados em acoes voluntarias dos usuarios.",
    },
  "Безопасность данных": {
    ru: "Безопасность данных",
    en: "Data security",
    fr: "Sécurité des données",
    es: "Seguridad de los datos",
    "es-l": "Seguridad de los datos",
    id: "Keamanan data",
    ["pt-br"]: "Segurança dos dados",
  },
  "Мы принимаем все необходимые меры для защиты данных, используя шифрование AES-256.":
    {
      ru: "Мы принимаем все необходимые меры для защиты данных, используя шифрование AES-256.",
      en: "We take all necessary measures to protect data using AES-256 encryption.",
      fr: "Nous effectuons toutes les mesures requises pour proteger les données en utilisant l'encryptage AES-256.",
      es: "Tomamos todas las medidas indispensables para proteger los datos usando la encriptación AES-256.",
      "es-l":
        "Tomamos todas las medidas indispensables para proteger los datos usando la encriptación AES-256.",
      id: "Kami mengambil semua tindakan yang diperlukan untuk melindungi data menggunakan enkripsi AES-256.",
      ["pt-br"]:
        "Tomamos todas as medidas necessárias para proteger os dados usando a criptografia AES-256",
    },
  "Срок хранения данных": {
    ru: "Срок хранения данных",
    en: "Data retention period",
    fr: "Duree de conservation des données",
    es: "Período de conservación de los datos",
    "es-l": "Período de conservación de los datos",
    id: "Periode penyimpanan data",
    ["pt-br"]: "Período de conservação dos dados",
  },
  "Данные пользователей хранятся столько, сколько необходимо для достижения целей или в соответствии с законодательством.":
    {
      ru: "Данные пользователей хранятся столько, сколько необходимо для достижения целей или в соответствии с законодательством.",
      en: "User data is stored for as long as necessary to achieve the purposes or as required by law.",
      fr: "Les données des utilisateurs sont stockées tant que necessaire pour atteindre les objectifs ou conforme aux lois.",
      es: "Los datos de los usuarios se almacenan durante un tiempo que es necesario para lograr los objetivos o conforme a la ley.",
      "es-l":
        "Los datos de los usuarios se almacenan durante un tiempo que es necesario para lograr los objetivos o conforme a la ley.",
      id: "Data pengguna disimpan selama yang diperlukan untuk mencapai tujuan atau sesuai dengan hukum.",
      ["pt-br"]:
        "Os dados do usuário são mantidos pelo tempo necessário para cumprir as finalidades ou conforme exigido por lei.",
    },
  "Права пользователей": {
    ru: "Права пользователей",
    en: "User rights",
    fr: "Droits des utilisateurs",
    es: "Derechos de los usuarios",
    "es-l": "Derechos de los usuarios",
    id: "Hak pengguna",
    ["pt-br"]: "Direitos do usuário",
  },
  "Пользователи имеют право на доступ, исправление, удаление и ограничение обработки данных. Связаться с нами можно по email:":
    {
      ru: "Пользователи имеют право на доступ, исправление, удаление и ограничение обработки данных. Связаться с нами можно по email:",
      en: "Users have the right to access, correct, delete, and restrict the processing of data. You can contact us via email:",
      fr: "Les utilisateurs ont le droit d'accès, de corriger, de supprimer et de limiter le traitement des données. Vous pouvez nous contacter par email :",
      es: "Los usuarios tienen el derecho de acceder, corregir, eliminar y restringir el procesamiento de datos. Puedes contactarnos por correo electrónico:",
      "es-l":
        "Los usuarios tienen el derecho de acceder, corregir, de suprimir y de restringir el procesamiento de datos. Puedes contactarnos por correo electrónico:",
      id: "Pengguna memiliki hak untuk mengakses, memperbaiki, menghapus, dan membatasi pemrosesan data. Anda dapat menghubungi kami melalui email:",
      ["pt-br"]:
        "Os usuários têm o direito de acessar, retificar, apagar e restringir o processamento de dados. Você pode entrar em contato conosco pelo e-mail:",
    },
  "Изменения в политике конфиденциальности": {
    ru: "Изменения в политике конфиденциальности",
    en: "Changes to the privacy policy",
    fr: "Modifications de la politique de confidentialité",
    es: "Cambios en la política de privacidad",
    "es-l": "Cambios en la política de privacidad",
    id: "Perubahan kebijakan privasi",
    ["pt-br"]: "Alterações na POLITICA DE PRIVACIDADE",
  },
  "Мы оставляем за собой право вносить изменения в политику конфиденциальности. Пользователи будут уведомлены через приложение.":
    {
      ru: "Мы оставляем за собой право вносить изменения в политику конфиденциальности. Пользователи будут уведомлены через приложение.",
      en: "We reserve the right to make changes to the privacy policy. Users will be notified through the application.",
      fr: "Nous conservons le droit de modifier la politique de confidentialité. Les utilisateurs seront notifiés par l'application.",
      es: "Nos reservamos el derecho de hacer cambios en la POLITICA DE PRIVACIDAD. Los usuarios serán notificados por la aplicación.",
      "es-l":
        "Nos reservamos el derecho de hacer cambios en la POLITICA DE PRIVACIDAD. Los usuarios serán notificados por la aplicación.",
      id: "Kami menjamin hak untuk memperbarui kebijakan privasi. Pengguna akan diterima melalui aplikasi.",
      ["pt-br"]:
        "Nós nos reservamos o direito de fazer alterações na política de privacidade. Os usuários serão notificados por meio do aplicativo.",
    },
  "arrow back on click return to friends page": {
    ru: "стрелка назад при клике вернёт на страницу друзей",
    en: "arrow back on click returns to friends page",
    fr: "fleche de retour sur le clic retourne à la page des amis",
    es: "flecha de retorno en el clic devuelve a la pagina de amigos",
    "es-l": "flecha de retorno en el clic devuelve a la pagina de amigos",
    id: "klik tombol kembali untuk kembali ke halaman teman",
    ["pt-br"]: "flecha de retorno no clique devolve para a pagina de amigos",
  },
  "Условия использования": {
    ru: "Условия использования",
    en: "Terms of Use",
    fr: "Conditions d'utilisation",
    es: "Términos de uso",
    "es-l": "Términos de uso",
    id: "Ketentuan Penggunaan",
    ["pt-br"]: "Termos de uso",
  },
  "1. Описание продукта": {
    ru: "1. Описание продукта",
    en: "1. Product Description",
    fr: "1. Description du produit",
    es: "1. Descripción del producto",
    "es-l": "1. Descripción del producto",
    id: "1. Deskripsi produk",
    ["pt-br"]: "1. Descrição do Projeto",
  },
  "Pirate Empire — это стратегическая игра с элементами менеджмента, в которой пользователи управляют островами и развивают свои территории. Главной целью является строительство и улучшение зданий на нескольких островах, которые становятся доступны по мере прохождения игры.":
    {
      ru: "Pirate Empire — это стратегическая игра с элементами менеджмента, в которой пользователи управляют островами и развивают свои территории. Главной целью является строительство и улучшение зданий на нескольких островах, которые становятся доступны по мере прохождения игры.",
      en: "Pirate Empire is a strategy game with management elements where users manage islands and develop their territories. The main goal is to build and upgrade buildings on several islands that become available as the game progresses.",
      fr: "Pirate Empire est un jeu de stratégie avec des éléments de gestion dans lequel les utilisateurs gèrent des îles et développent leurs territoires. L'objectif principal est de construire et d'améliorer des bâtiments sur plusieurs îles, qui deviennent disponibles au fur et à mesure que vous progressez dans le jeu.",
      es: "Pirate Empire es un juego de estrategia con elementos de gestión en el que los usuarios administran islas y expanden sus territorios. El objetivo principal es construir y mejorar los edificios de varias islas, que estarán disponibles a medida que los jugadores avancen en el juego",
      "es-l":
        "Pirate Empire es un juego de estrategia con elementos de gestión en el que los usuarios administran islas y expanden sus territorios. El objetivo principal es construir y mejorar los edificios de varias islas, que estarán disponibles a medida que los jugadores avancen en el juego",
      id: "Pirate Empire adalah game strategi dengan elemen manajemen di mana pengguna mengelola pulau dan memperluas wilayah mereka. Tujuan utamanya adalah membangun dan mengupgrade bangunan di sejumlah pulau, yang menjadi tersedia seiring semakin bertambahnya progres pemain dalam game.",
      ["pt-br"]:
        "Pirate Empire é um jogo de estratégia com elementos de gerenciamento no qual os usuários administram ilhas e desenvolvem seus territórios. O objetivo principal é construir e melhorar os edifícios em várias ilhas, que se tornam disponíveis à medida que você avança no jogo.",
    },
  "2. Возрастные ограничения": {
    ru: "2. Возрастные ограничения",
    en: "2. Age Restrictions",
    fr: "2. Restrictions d'âge",
    es: "2. Restricciones de edad",
    "es-l": "2. Restricciones de edad",
    id: "2. Batasan Usia",
    ["pt-br"]: "2. Restricções de Idade",
  },
  "Игра предназначена для пользователей, достигших возраста 18 лет и старше.": {
    ru: "Игра предназначена для пользователей, достигших возраста 18 лет и старше.",
    en: "The game is intended for users aged 18 and older.",
    fr: "La jeu est destiné aux utilisateurs ageants de 18 ans et plus.",
    es: "El juego es destinado a los usuarios de 18 a mayores.",
    "es-l": "El juego es destinado a los usuarios de 18 a mayores.",
    id: "Game ini ditujukan untuk pengguna berusia 18 tahun ke atas",
    ["pt-br"]: "O jogo é destinado a usuários com 18 anos de idade ou mais.",
  },
  "3. Регистрация и аккаунты": {
    ru: "3. Регистрация и аккаунты",
    en: "3. Registration and Accounts",
    fr: "3. Inscription et comptes",
    es: "3. Registro y Cuentas",
    "es-l": "3. Registro y Cuentas",
    id: "3. Pendaftaran dan Akun",
    ["pt-br"]: "3. Registração e Contas",
  },
  "Регистрация в игре не требуется. Создание аккаунта происходит автоматически при первом запуске приложения.":
    {
      ru: "Регистрация в игре не требуется. Создание аккаунта происходит автоматически при первом запуске приложения.",
      en: "Registration in the game is not required. An account is created automatically upon the first launch of the application.",
      fr: "L'enregistrement en jeu n'est pas requis. Un compte est créé automatiquement lors du premier lancement de l'application.",
      es: "La inscripción en el juego no es obligatoria. Una cuenta es creada automáticamente al primer inicio de la aplicación.",
      "es-l":
        "La inscripción en el juego no es obligatoria. Una cuenta es creada aquí al primer inicio de la aplicación.",
      id: "Pendaftaran tidak diperlukan. Akun akan dibuat secara otomatis saat aplikasi pertama kali diluncurkan.",
      ["pt-br"]:
        "O registro no jogo não é necessário. A criação da conta é automática quando o aplicativo é iniciado pela primeira vez.",
    },
  "4. Использование контента": {
    ru: "4. Использование контента",
    en: "4. Use of Content",
    fr: "4. Utilisation du contenu",
    es: "4. Uso de Contenido",
    "es-l": "4. Uso de Contenido",
    id: "4. Penggunaan Konten",
    ["pt-br"]: "4. Uso de Conteúdo",
  },
  "Все материалы и контент, представленные в игре Pirate Empire, являются интеллектуальной собственностью разработчиков и защищены законом об авторском праве. Пользователи не имеют права копировать, распространять, изменять или использовать контент игры без письменного разрешения разработчиков.":
    {
      ru: "Все материалы и контент, представленные в игре Pirate Empire, являются интеллектуальной собственностью разработчиков и защищены законом об авторском праве. Пользователи не имеют права копировать, распространять, изменять или использовать контент игры без письменного разрешения разработчиков.",
      en: "All materials and content presented in the game Pirate Empire are the intellectual property of the developers and are protected by copyright law. Users may not copy, distribute, modify, or use the game's content without the written permission of the developers.",
      fr: " Tous les documents et le contenu présentés dans le jeu Pirate Empire sont la propriété intellectuelle des développeurs et sont protégés par la loi sur les droits d'auteur. Les utilisateurs ne peuvent pas copier, distribuer, modifier ou utiliser le contenu du jeu sans l'autorisation écrite des développeurs.",
      es: "Todos los materiales y contenidos presentados en el juego Pirate Empire son propiedad intelectual de los desarrolladores y están protegidos por la ley de derechos de autor. Los usuarios no pueden copiar, distribuir, modificar o utilizar el contenido del juego sin el permiso por escrito de los desarrolladores.",
      "es-l":
        "Todos los materiales y contenidos presentados en el juego Pirate Empire son propiedad intelectual de los desarrolladores y están protegidos por la ley de derechos de autor. Los usuarios no pueden copiar, distribuir, modificar o utilizar el contenido del juego sin el permiso por escrito de los desarrolladores.",
      id: "Semua material dan konten yang disajikan dalam Pirate Empire adalah hak milik intelektual pengembang dan dilindungi oleh undang-undang hak cipta. Pengguna tidak diizinkan untuk menyalin, mendistribusikan, memodifikasi, atau menggunakan konten game tanpa izin tertulis dari pengembang",
      ["pt-br"]:
        "Todos os materiais e conteúdos apresentados no jogo Pirate Empire são de propriedade intelectual dos desenvolvedores e estão protegidos pela lei de direitos autorais. Os usuários não podem copiar, distribuir, modificar ou usar o conteúdo do jogo sem a permissão por escrito dos desenvolvedores.",
    },
  "5. Платежи и возвраты": {
    ru: "5. Платежи и возвраты",
    en: "5. Payments and Refunds",
    fr: "5. Paiements et retours",
    es: "5. Pagos y reembolsos",
    "es-l": "5. Pagos y reembolsos",
    id: "5. Pembayaran dan Pengembalian Dana",
    ["pt-br"]: "5. Pagamentos e Reembolsos",
  },
  "Игра включает в себя платный контент, например, покупку виртуальных валют (жемчуг, бустеры, автосбор). Все транзакции в игре являются окончательными и не подлежат возврату.":
    {
      ru: "Игра включает в себя платный контент, например, покупку виртуальных валют (жемчуг, бустеры, автосбор). Все транзакции в игре являются окончательными и не подлежат возврату.",
      en: "The game includes paid content, such as the purchase of virtual currencies (pearls, boosters, auto-collection). All transactions in the game are final and non-refundable.",
      fr: "Le jeu comprend du contenu payant, tel que l'achat de monnaies virtuelles (perles, boosters, autoshares). Toutes les transactions dans le jeu sont définitives et non remboursables.",
      es: "El juego incluye contenidos de pago, como la compra de monedas virtuales (perlas, potenciadores, autoshares). Todas las transacciones del juego son definitivas y no reembolsables.",
      "es-l":
        "El juego incluye contenidos de pago, como la compra de monedas virtuales (perlas, potenciadores, autoshares). Todas las transacciones del juego son definitivas y no reembolsables.",
      id: "Game ini menyertakan konten berbayar, seperti pembelian mata uang virtual (mutiara, booster, pengumpulan otomatis). Semua transaksi dalam game bersifat final dan dana yang digunakan tidak dapat dikembalikan.",
      ["pt-br"]:
        "O jogo inclui conteúdo pago, como a compra de moedas virtuais (pérolas, boosters, autoshares). Todas as transações no jogo são finais e não reembolsáveis.",
    },
  "6. Поведение пользователей": {
    ru: "6. Поведение пользователей",
    en: "6. User Conduct",
    fr: "6. Conducte des utilisateurs",
    es: "6. Conducta de los usuarios",
    "es-l": "6. Conducta de los usuarios",
    id: "6. Perilaku Pengguna",
    ["pt-br"]: "6. Conduta do Usuário",
  },
  "Пользователи обязаны соблюдать правила поведения в игре, включая запрет на мошенничество, злоупотребление ошибками (багами) и оскорбительное поведение по отношению к другим игрокам. Нарушители этих правил могут быть заблокированы без предварительного предупреждения.":
    {
      ru: "Пользователи обязаны соблюдать правила поведения в игре, включая запрет на мошенничество, злоупотребление ошибками (багами) и оскорбительное поведение по отношению к другим игрокам. Нарушители этих правил могут быть заблокированы без предварительного предупреждения.",
      en: "Users are required to adhere to the rules of conduct in the game, including prohibitions on cheating, exploiting bugs, and offensive behavior towards other players. Violators of these rules may be banned without prior warning.",
      fr: "Les utilisateurs sont tenus de respecter les règles de comportement dans le jeu, y compris l'interdiction de la tricherie, de l'utilisation abusive des bugs et des comportements abusifs à l'égard des autres joueurs. Les contrevenants à ces règles peuvent être bannis sans avertissement préalable",
      es: "Los usuarios deben respetar las normas de comportamiento en el juego, incluida la prohibición de hacer trampas, abusar de los bugs (errores) y tener un comportamiento abusivo con otros jugadores. Los infractores de estas normas podrán ser expulsados sin previo aviso",
      "es-l":
        "Los usuarios deben respetar las normas de comportamiento en el juego, incluida la prohibición de hacer trampas, abusar de los bugs (errores) y tener un comportamiento abusivo con otros jugadores. Los infractores de estas normas podrán ser expulsados sin previo aviso",
      id: "Pengguna harus mematuhi aturan perilaku dalam game, yang melarang perilaku curang, penyalahgunaan bug, dan perilaku yang menyinggung dan menyerang terhadap pemain lain. Pelanggar dapat diblokir tanpa peringatan sebelumnya.",
      ["pt-br"]:
        "Os usuários devem cumprir as regras de comportamento no jogo, incluindo a proibição de trapaça, abuso de bugs (bugs) e comportamento abusivo em relação a outros jogadores. Os infratores dessas regras podem ser bloqueados sem aviso prévio.",
    },
  "7. Ответственность": {
    ru: "7. Ответственность",
    en: "7. Liability",
    fr: "7. Responsabilités",
    es: "7. Responsabilidad",
    "es-l": "7. Responsabilidad",
    id: "7. Pertanggungjawaban",
    ["pt-br"]: "7. Responsabilidade",
  },
  "Разработчики Pirate Empire не несут ответственности за действия пользователей в игре, а также за возможные убытки, связанные с использованием контента игры. Мы не гарантируем бесперебойную работу игры и отсутствие ошибок.":
    {
      ru: "Разработчики Pirate Empire не несут ответственности за действия пользователей в игре, а также за возможные убытки, связанные с использованием контента игры. Мы не гарантируем бесперебойную работу игры и отсутствие ошибок.",
      en: "The developers of Pirate Empire are not responsible for users' actions in the game, as well as for any losses related to the use of game content. We do not guarantee uninterrupted operation of the game or the absence of errors.",
      fr: " Les développeurs de Pirate Empire ne sont pas responsables des actions des utilisateurs dans le jeu, ni des pertes éventuelles liées à l'utilisation du contenu du jeu. Nous ne garantissons pas le bon fonctionnement du jeu et l'absence d'erreurs",
      es: "Los desarrolladores de Pirate Empire no se hacen responsables de las acciones de los usuarios en el juego, así como de las posibles pérdidas asociadas al uso del contenido del juego. No garantizamos el buen funcionamiento del juego ni la ausencia de errores",
      "es-l":
        "Los desarrolladores de Pirate Empire no se hacen responsables de las acciones de los usuarios en el juego, así como de las posibles pérdidas asociadas al uso del contenido del juego. No garantizamos el buen funcionamiento del juego ni la ausencia de errores",
      id: "Pengembang Pirate Empire tidak bertanggung jawab atas tindakan pengguna dalam game atau atas kerugian yang terkait dengan penggunaan konten game. Kami tidak menjamin gameplay bebas dari gangguan atau kesalahan.",
      ["pt-br"]:
        "Os desenvolvedores do Pirate Empire não são responsáveis pelas ações dos usuários no jogo, bem como por possíveis perdas associadas ao uso do conteúdo do jogo. Não garantimos o bom funcionamento do jogo e a ausência de erros.",
    },
  "8. Изменения в условиях использования": {
    ru: "8. Изменения в условиях использования",
    en: "8. Changes to the Terms of Use",
    fr: "8. Modifications aux conditions d'utilisation",
    es: "8. Cambios en los Terminos de Uso",
    "es-l": "8. Cambios en los Terminos de Uso",
    id: "8. Perubahan pada Ketentuan Penggunaan",
    ["pt-br"]: "8. Alterações nos Termos de Uso",
  },
  " Мы оставляем за собой право вносить изменения в настоящие условия использования в любое время. О любых изменениях пользователи будут уведомлены через приложение Pirate Empire. Рекомендуем периодически проверять обновления условий использования.":
    {
      ru: "Мы оставляем за собой право вносить изменения в настоящие условия использования в любое время. О любых изменениях пользователи будут уведомлены через приложение Pirate Empire. Рекомендуем периодически проверять обновления условий использования.",
      en: "We reserve the right to make changes to these Terms of Use at any time. Users will be notified of any changes through the Pirate Empire application. We recommend periodically checking for updates to the Terms of Use.",
      fr: "Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les utilisateurs seront informés de toute modification via l'application Pirate Empire. Nous vous recommandons de vérifier régulièrement les mises à jour des conditions d'utilisation.",
      es: "Nos reservamos el derecho a realizar cambios en estas condiciones de uso en cualquier momento. Los usuarios serán notificados de cualquier cambio a través de la app Pirate Empire. Te recomendamos que compruebes periódicamente las actualizaciones de las condiciones de uso.",
      "es-l":
        "Nos reservamos el derecho a realizar cambios en estas condiciones de uso en cualquier momento. Los usuarios serán notificados de cualquier cambio a través de la app Pirate Empire. Te recomendamos que compruebes periódicamente las actualizaciones de las condiciones de uso.",
      id: "Kami berhak untuk membuat perubahan pada Ketentuan Penggunaan ini kapan saja. Pengguna akan diberi tahu tentang perubahan apa pun melalui aplikasi Pirate Empire. Kami sarankan untuk meninjau pembaruan Ketentuan Penggunaan secara berkala.",
      ["pt-br"]:
        "Reservamo-nos o direito de fazer alterações nestes termos de uso a qualquer momento. Os usuários serão notificados de quaisquer alterações por meio do aplicativo Pirate Empire. Recomendamos que você verifique periodicamente se há atualizações nos termos de uso.",
    },
  "9. Заключительные положения": {
    ru: "9. Заключительные положения",
    en: "9. Final Provisions",
    fr: "9. Conclusion",
    es: "9. Principios finales",
    "es-l": "9. Principios finales",
    id: "9. Ketentuan Akhir",
    ["pt-br"]: "9. Conclusão",
  },
  "В случае возникновения споров, стороны обязуются решать их путем переговоров. Если спор не удастся урегулировать, он подлежит рассмотрению в компетентном суде.":
    {
      ru: "В случае возникновения споров, стороны обязуются решать их путем переговоров. Если спор не удастся урегулировать, он подлежит рассмотрению в компетентном суде.",
      en: "In the event of disputes, the parties agree to resolve them through negotiations. If the dispute cannot be resolved, it will be subject to review in a competent court.",
      fr: " Dans le cas de conflits, les parties doivent satisfaire aux contrats. Si le conflit ne peut être résolu, il sera soumis à examen devant un tribunal efficace.",
      es: "En caso de litigio, las partes se comprometen a resolverlo mediante negociación. Si el litigio no pudiera resolverse, se someterá al examen del tribunal competente",
      "es-l":
        "En caso de litigio, las partes se comprometen a resolverlo mediante negociación. Si el litigio no pudiera resolverse, se someterá al examen del tribunal competente",
      id: "Ini jika ada permasalahan, pihak pihak harus menyelesaikannya melalui perundingan. Jika permasalahan tidak dapat diselesaikan, permasalahan akan diselenggarakan di daerah yang kompeten.",
      ["pt-br"]:
        "Em caso de controvérsias, as partes se comprometem a resolvê-las por meio de negociação. Se a controvérsia não puder ser resolvida, ela estará sujeita à análise do tribunal competente.",
    },
  "Об игре": {
    ru: "Об игре",
    en: "About the Game",
    fr: "A propos du jeu",
    es: "Sobre el Juego",
    "es-l": "Sobre el Juego",
    id: "Tentang Permainan",
    ["pt-br"]: "Sobre o Jogo",
  },
  Здания: {
    ru: "Здания",
    en: "Buildings",
    fr: "Maisons",
    es: "Edificaciones",
    "es-l": "Edificaciones",
    id: "Bangunan",
    ["pt-br"]: "Edifiços",
  },
  "Стройте и улучшайте здания, отправляйте корабль в исследовательскую миссию, чтобы получать больше прибыли. Здание собирают золото автоматически, но для того чтобы больше зарабатывать, нужно каждые 3 часа собирать накопленное золото. Корабль приносит вам золото и жемчуг, каждый 1 час.":
    {
      ru: "Стройте и улучшайте здания, отправляйте корабль в исследовательскую миссию, чтобы получать больше прибыли. Здание собирают золото автоматически, но для того чтобы больше зарабатывать, нужно каждые 3 часа собирать накопленное золото. Корабль приносит вам золото и жемчуг, каждый 1 час.",
      en: "Build and upgrade buildings, send the ship on an exploration mission to earn more profit. The building automatically collects gold, but to earn more, you need to collect the accumulated gold every 3 hours. The ship brings you gold and pearls every hour.",
      fr: "Construisez et améliorez les bâtiments, envoyez votre vaisseau en mission de recherche pour gagner plus d'argent. Les bâtiments collectent de l'or automatiquement, mais pour gagner plus, vous devez collecter l'or accumulé toutes les 3 heures. Le navire vous apporte de l'or et des perles toutes les heures",
      es: "Construye y mejora edificios, envía tu nave en misión de investigación para obtener más beneficios. Los edificios recogen oro automáticamente, pero para ganar más, tienes que recoger el oro acumulado cada 3 horas. El barco te trae oro y perlas, cada 1 hora",
      "es-l":
        "Construye y mejora edificios, envía tu nave en misión de investigación para obtener más beneficios. Los edificios recogen oro automáticamente, pero para ganar más, tienes que recoger el oro acumulado cada 3 horas. El barco te trae oro y perlas, cada 1 hora",
      id: "Bangun dan tingkatkan bangunan, kirimkan kapal Anda dalam misi penelitian untuk mendapatkan lebih banyak keuntungan. Bangunan mengumpulkan emas secara otomatis, tetapi untuk mendapatkan lebih banyak, Anda harus mengumpulkan emas yang terkumpul setiap 3 jam. Kapal membawakan Anda emas dan mutiara, setiap 1 jam.",
      ["pt-br"]:
        "Construa e melhore edifícios, envie sua nave em uma missão de pesquisa para obter mais lucro. Os edifícios coletam ouro automaticamente, mas, para ganhar mais, você precisa coletar o ouro acumulado a cada 3 horas. O navio lhe traz ouro e pérolas a cada 1 hora.",
    },
  "Вы можете использовать бустер “Автосбор”, для автоматического сбора ресурсов с любого построенного здания.":
    {
      ru: "Вы можете использовать бустер “Автосбор”, для автоматического сбора ресурсов с любого построенного здания.",
      en: "You can use the “Auto-Collect” booster for automatic resource collection from any built building.",
      fr: "Vous pouvez utiliser le boster « Collecteur automatique » pour collecter de l'or automatiquement depuis n'importe quelle maison construite.",
      es: "Puedes usar el boster “Auto-collector” para recopilar recursos automaticamente de cualquier edificio construido.",
      "es-l":
        "Puedes usar el boster “Auto-collector” para recopilar recursos automaticamente de cualquier edificio construido.",
      id: "Anda dapat menggunakan booster “Auto-Collect” untuk mengumpulkan bahan mentah secara otomatis dari setiap bangunan yang dibangun.",
      ["pt-br"]:
        "Voce pode usar o boster “Auto-Collect” para coletar recursos automaticamente de qualquer edifício construído.",
    },
  "Вы можете использовать бустер “Множитель”, для увеличения добычи золота. Бустер можно купить в магазине или найти в сундуке. В сундуке вы можете найти бустер с более высоким коэффициентом. Важно знать, что если у вас уже есть один активный бустер, то все следующие не изменят коэффициент текущего, а только увеличат время работы действующего.":
    {
      ru: "Вы можете использовать бустер “Множитель”, для увеличения добычи золота. Бустер можно купить в магазине или найти в сундуке. В сундуке вы можете найти бустер с более высоким коэффициентом. Важно знать, что если у вас уже есть один активный бустер, то все следующие не изменят коэффициент текущего, а только увеличат время работы действующего.",
      en: "You can use the “Multiplier” booster to increase gold earnings. The booster can be purchased in the shop or found in a chest. In the chest, you may find a booster with a higher coefficient. It is important to know that if you already have one active booster, all subsequent boosters will not change the current coefficient but will only increase the duration of the active one.",
      fr: "Vous pouvez utiliser le booster « Multiplier » pour augmenter la production d'or. Vous pouvez acheter le booster dans la boutique ou le trouver dans un coffre. Vous pouvez trouver un booster avec un multiplicateur plus élevé dans le coffre. Il est important de savoir que si vous avez déjà un booster actif, tous les suivants ne changeront pas le coefficient du booster actuel, mais augmenteront seulement le temps de travail du booster actuel",
      es: "Puedes usar el potenciador «Multiplicador» para aumentar la producción de oro. Puedes comprar el potenciador en la tienda o encontrarlo en un cofre. Puedes encontrar un potenciador con un multiplicador mayor en el cofre. Es importante saber que si ya tienes un booster activo, todos los siguientes no cambiarán el coeficiente del actual, sino que sólo aumentarán el tiempo de trabajo del actual",
      "es-l":
        "Puedes usar el potenciador «Multiplicador» para aumentar la producción de oro. Puedes comprar el potenciador en la tienda o encontrarlo en un cofre. Puedes encontrar un potenciador con un multiplicador mayor en el cofre. Es importante saber que si ya tienes un booster activo, todos los siguientes no cambiarán el coeficiente del actual, sino que sólo aumentarán el tiempo de trabajo del actual",
      id: "Anda bisa menggunakan booster “Multiplier” untuk meningkatkan produksi emas. Anda dapat membeli booster di toko atau menemukannya di dalam peti. Anda dapat menemukan booster dengan pengganda yang lebih tinggi di dalam peti. Penting untuk diketahui bahwa jika Anda sudah memiliki satu booster yang aktif, semua booster berikutnya tidak akan mengubah koefisien booster yang sedang aktif, tetapi hanya akan meningkatkan waktu kerja booster yang sedang aktif.",
      ["pt-br"]:
        "Você pode usar o booster “Multiplier” para aumentar a produção de ouro. Você pode comprar o reforço na loja ou encontrá-lo em um baú. Você pode encontrar um booster com um multiplicador maior no baú. É importante saber que, se você já tiver um intensificador ativo, todos os seguintes não alterarão o coeficiente do atual, mas apenas aumentarão o tempo de funcionamento do atual.",
    },
  Карта: {
    ru: "Карта",
    en: "Map",
    fr: "Carte",
    es: "Mapa",
    "es-l": "Mapa",
    id: "Kartu",
    ["pt-br"]: "Mapa",
  },
  "В скором времени появятся новые острова со своими секретами!": {
    ru: "В скором времени появятся новые острова со своими секретами!",
    en: "New islands with their secrets will be coming soon!",
    fr: "Il y aura bientôt de nouvelles îles avec leurs propres secrets!",
    es: "Los islas nuevos con sus secretos aparecerán pronto!",
    "es-l": "Los islas nuevos con sus secretos aparecerán pronto!",
    id: "Isolah baru dengan rahasia mereka akan datang segera!",
    ["pt-br"]: "Novas ilhas com seus segredos estarão chegando em breve!",
  },
  Друзья: {
    ru: "Друзья",
    en: "Friends",
    fr: "Amis",
    es: "Amigos",
    "es-l": "Amigos",
    id: "Keluarga",
    ["pt-br"]: "Amigos",
  },
  "Приглашайте друзей, чтобы получать ценную награду.": {
    ru: "Приглашайте друзей, чтобы получать ценные награды.",
    en: "Invite friends to receive valuable rewards.",
    fr: "Invitez des amis pour obtenir des recompenses importantes.",
    es: "Invita a tus amigos para obtener recompensas valiosas.",
    "es-l": "Invita a tus amigos para obtener recompensas valiosas.",
    id: "Undang temanmu untuk mendapatkan hadiah menarik.",
    ["pt-br"]: "Convide seus amigos para receber recompensas valiosas.",
  },
  Рейтинг: {
    ru: "Рейтинг",
    en: "Leaderboard",
    fr: "Classement",
    es: "Clasificación",
    "es-l": "Clasificación",
    id: "Peringkat",
    ["pt-br"]: "Classificação",
  },
  "Только самые успешные пираты занимают топ места в рейтинге!": {
    ru: "Только самые успешные пираты занимают ТОП-места в рейтинге!",
    en: "Only the most successful pirates occupy top places in the leaderboard!",
    fr: "Seuls les pirates les plus performants s'emparent des premières places du classement!",
    es: "Solo los piratas más exitosos se ocupan de las primeras posiciones de la clasificación!",
    "es-l":
      "Solo los piratas más exitosos se ocupan de las primeras posiciones de la clasificación!",
    id: "Hanya para pahlawan yang paling sukses mengisi posisi top di leaderboard!",
    ["pt-br"]:
      "Somente os piratas mais bem sucedidos ocupam as primeiras posições na classificação!",
  },
  "В Pirate Empire вам предстоит зарабатывать золото и строить свою пиратскую империю! Стройте здания, отправляйте корабли за золотом и расширяйте влияние. Помните, чем больше активности и золота, тем успешнее пройдет будущий Airdrop — ваш шанс получить ценные награды!":
    {
      ru: "В Pirate Empire вам предстоит зарабатывать золото и строить свою пиратскую империю! Стройте здания, отправляйте корабль за золотом и жемчугом и расширяйте влияние!",
      en: "",
      fr: "",
      es: "",
      "es-l":
        "",
      id: "",
      ["pt-br"]:
        "",
    },
  "Стройте и улучшайте здания, отправляйте корабль в исследовательскую миссию, чтобы получать больше прибыли. Здания собирают золото автоматически, но для того чтобы больше зарабатывать, нужно каждые 3 часа собирать накопленное золото.":
    {
      ru: "Стройте и улучшайте здания, отправляйте корабль в исследовательскую миссию, чтобы получать больше прибыли. Здания собирают золото автоматически, но для того чтобы больше зарабатывать, нужно каждые 3 часа собирать накопленное золото.",
      en: "Construct and upgrade buildings, send your ship on exploration missions to increase profits. Buildings collect gold automatically, but to maximize earnings, collect accumulated gold every 3 hours.",
      fr: "Construisez et améliorez les bâtiments, envoyez votre navire en mission de recherche pour gagner plus de récompenses. Les bâtiments collectent automatiquement de l'or, mais pour en gagner davantage, vous devez collecter l'or accumulé toutes les 3 heures.",
      es: "Construye y mejora edificios, envía tu nave a una misión de investigación para obtener más beneficios. Los edificios recogen oro automáticamente, pero tienes que recoger el oro acumulado cada 3 horas para ganar más.",
      "es-l":
        "Construye y mejora edificios, envía tu nave a una misión de investigación para obtener más beneficios. Los edificios recogen oro automáticamente, pero tienes que recoger el oro acumulado cada 3 horas para ganar más.",
      id: "Bangun dan tingkatkan bangunan, kirimkan kapal Anda dalam misi penelitian untuk mendapatkan lebih banyak keuntungan. Bangunan mengumpulkan emas secara otomatis, tetapi Anda harus mengumpulkan emas yang terkumpul setiap 3 jam untuk mendapatkan lebih banyak.",
      ["pt-br"]:
        "Construa e melhore edifícios, envie sua nave em uma missão de pesquisa para obter mais lucro. Os edifícios coletam ouro automaticamente, mas você precisa coletar o ouro acumulado a cada 3 horas para ganhar mais.",
    },
  "Корабль приносит вам золото и жемчуг каждый 1 час.": {
    ru: "Корабль приносит вам золото и жемчуг каждый 1 час.",
    en: "Your ship brings gold and pearls every hour.",
    fr: "Votre navire donne de l'or et de la perle tous les heures.",
    es: "Tu nave brinda oro y perlas cada hora.",
    "es-l": "Tu nave brinda oro y perlas cada hora.",
    id: "Kapalmu menghasilkan emas dan peralatan setiap jam.",
    ["pt-br"]: "Seu navio traz ouro e perlas a cada hora.",
  },
  "Вы можете использовать бустер “Автосбор” для автоматического сбора ресурсов с любого построенного здания.":
    {
      ru: "Вы можете использовать бустер “Автосбор” для автоматического сбора ресурсов с любого построенного здания.",
      en: "You can use the 'Auto-collect' booster to automatically collect resources from any constructed building.",
      fr: "Vous pouvez utiliser le booster 'Auto-collect' pour collecter automatiquement les ressources d'un bateau.",
      es: "Puedes usar el booster 'Auto-collect' para recolectar recursos de cualquier edificio construido.",
      "es-l":
        "Puedes usar el booster 'Auto-collect' para recolectar recursos de cualquier edificio construido.",
      id: "Anda bisa menggunakan booster 'Auto-collect' untuk mengumpulkan sumber daya dari bangunan yang dibangun.",
      ["pt-br"]:
        "Você pode usar o impulsionador Autocollect para coletar automaticamente recursos de qualquer edifício construído.",
    },
  "Вы можете использовать бустер “Множитель” для увеличения добычи золота. Бустер можно купить в магазине или найти в сундуке. В сундуке вы можете найти бустер с более высоким коэффициентом. Важно знать, что если у вас уже есть один активный бустер, то все следующие не изменят коэффициент текущего, а только увеличат время работы действующего.":
    {
      ru: "Вы можете использовать бустер “Автосбор” для автоматического сбора ресурсов с любого построенного здания.",
      en: "You can use the 'Multiplier' booster to increase gold production. This booster can be bought in the store or found in a chest. In chests, you may find a booster with a higher multiplier. Note: if you already have an active booster, additional boosters will not change the current multiplier; they will only extend its duration.",
      fr: "Vous pouvez utiliser le booster « Multiplier » pour augmenter la production d'or. Vous pouvez acheter le booster dans la boutique ou le trouver dans un coffre. Vous pouvez trouver un booster avec un multiplicateur plus élevé dans le coffre. Il est important de savoir que si vous avez déjà un booster actif, tous les suivants ne changeront pas le coefficient du booster actuel, mais augmenteront seulement le temps de travail du booster actuel:",
      es: "Puedes usar el potenciador «Multiplicador» para aumentar la producción de oro. Puedes comprar el potenciador en la tienda o encontrarlo en un cofre. Puedes encontrar un potenciador con un multiplicador mayor en el cofre. Es importante saber que si ya tienes un booster activo, todos los siguientes no cambiarán el coeficiente del actual, sino que sólo aumentarán el tiempo de trabajo del actual",
      "es-l":
        "Puedes usar el potenciador «Multiplicador» para aumentar la producción de oro. Puedes comprar el potenciador en la tienda o encontrarlo en un cofre. Puedes encontrar un potenciador con un multiplicador mayor en el cofre. Es importante saber que si ya tienes un booster activo, todos los siguientes no cambiarán el coeficiente del actual, sino que sólo aumentarán el tiempo de trabajo del actual",
      id: "Anda bisa menggunakan booster “Multiplier” untuk meningkatkan produksi emas. Anda dapat membeli booster di toko atau menemukannya di dalam peti. Anda bisa menemukan booster dengan pengganda yang lebih tinggi di dalam peti. Penting untuk diketahui bahwa jika Anda sudah memiliki satu booster yang aktif, semua booster berikutnya tidak akan mengubah koefisien booster yang sedang aktif, tetapi hanya meningkatkan waktu kerja booster yang sedang aktif.",
      ["pt-br"]:
        "Você pode usar o booster “Multiplier” para aumentar a produção de ouro. Você pode comprar o reforço na loja ou encontrá-lo em um baú. Você pode encontrar um booster com um multiplicador maior no baú. É importante saber que, se você já tiver um booster ativo, todos os seguintes não alterarão o coeficiente do atual, mas apenas aumentarão o tempo de trabalho do atual.",
    },
  "Зовите друзей в Pirate Empire и получайте щедрые награды! За каждого приглашенного друга вы заработаете по 0.001 TON и 10 жемчуга для вас обоих. Если ваш друг — пользователь Telegram Premium, награда возрастает до 0.002 TON и 20 жемчуга! Помимо этого, вы будете получать 20% от золота, добытого вашими друзьями, и еще 10% от друзей, которых пригласили они. Бонусы начисляются ежедневно, а TON можно вывести, превращая активных друзей в реальный доход!":
    {
      ru: "Зовите друзей в Pirate Empire и получайте щедрые награды! За каждого приглашенного друга вы заработаете по 30 жемчуга для вас обоих. Если ваш друг — пользователь Telegram Premium, награда возрастает до 50 жемчуга! Помимо этого, вы будете получать 20% от золота, добытого вашими друзьями, и еще 10% от друзей, которых пригласили они. Бонусы начисляются ежедневно.",
      en: "Invite friends to Pirate Empire and receive generous rewards! For each invited friend, you will earn 0.001 TON and 10 pearls for both of you. If your friend is a Telegram Premium user, the reward increases to 0.002 TON and 20 pearls! Additionally, you'll receive 20% of the gold collected by your friends and 10% from the friends they invite. Bonuses are awarded daily, and TON can be withdrawn, turning active friends into real income!",
      fr: " Appelez vos amis dans Pirate Empire et obtenez de généreuses récompenses! Pour chaque ami que vous invitez, vous gagnerez 0,001 tonne et 10 perles pour vous deux. Si votre ami est un utilisateur Telegram Premium, la récompense passe à 0,002 tonne et 20 perles! En outre, vous gagnerez 20 % de l'or extrait par vos amis et 10 % de l'or extrait par les amis qu'ils ont invités. Les bonus sont attribués quotidiennement et les tonnes peuvent être retirées, transformant les amis actifs en véritables revenus!",
      es: "¡Llama a tus amigos en Pirate Empire y consigue generosas recompensas! Por cada amigo que invites, ganarás 0,001 TON y 10 perlas para los dos. Si tu amigo es usuario Premium de Telegram, ¡la recompensa aumenta a 0,002 TON y 20 perlas! Además, ganarás el 20% del oro minado por tus amigos y otro 10% de los amigos que hayan invitado. Las bonificaciones se conceden a diario y se pueden retirar TONs, ¡convirtiendo a los amigos activos en ingresos reales!",
      "es-l":
        "¡Llama a tus amigos en Pirate Empire y consigue generosas recompensas! Por cada amigo que invites, ganarás 0,001 TON y 10 perlas para los dos. Si tu amigo es usuario Premium de Telegram, ¡la recompensa aumenta a 0,002 TON y 20 perlas! Además, ganarás el 20% del oro minado por tus amigos y otro 10% de los amigos que hayan invitado. Las bonificaciones se conceden a diario y se pueden retirar TONs, ¡convirtiendo a los amigos activos en ingresos reales!",
      id: "Panggil teman Anda di Pirate Empire dan dapatkan hadiah yang berlimpah! Untuk setiap teman yang Anda undang, Anda akan mendapatkan 0,001 TON dan 10 mutiara untuk Anda berdua. Jika teman Anda adalah pengguna Telegram Premium, hadiahnya meningkat menjadi 0,002 TON dan 20 mutiara! Selain itu, Anda akan mendapatkan 20% dari emas yang ditambang oleh teman Anda dan 10% lainnya dari teman yang mereka undang. Bonus diberikan setiap hari dan TON dapat ditarik, mengubah teman yang aktif menjadi penghasilan nyata!",
      ["pt-br"]:
        "Chame seus amigos no Pirate Empire e ganhe recompensas generosas! Para cada amigo que você convidar, você ganhará 0,001 TON e 10 pérolas para vocês dois. Se o seu amigo for um usuário do Telegram Premium, a recompensa aumenta para 0,002 TON e 20 pérolas! Além disso, você ganhará 20% do ouro extraído por seus amigos e outros 10% dos amigos que eles convidarem. Os bônus são concedidos diariamente e os TONs podem ser retirados, transformando amigos ativos em renda real!",
    },
  "Только самые успешные пираты пробиваются в топ рейтинга, завоевывая уважение и признание среди других морских волков. Кто знает, возможно, впереди их ждут невероятные призы и славные награды!":
    {
      ru: "Только самые успешные пираты пробиваются в топ рейтинга, завоевывая уважение и признание среди других морских волков. Кто знает, возможно, впереди их ждут невероятные призы и славные награды!",
      en: "Only the most successful pirates reach the top of the leaderboard, earning respect and recognition from other seafarers. Who knows, perhaps incredible prizes and glorious rewards await them ahead!",
      fr: "Seuls les pirates les plus_succèsifs arrivent au top du classement, gagnant l'accent et la reconnaissance de la mer des navires. Peu importe, peut étre des priz",
      es: "Sólo los piratas más exitosos se abren camino hasta lo más alto de la clasificación, ganándose el respeto y el reconocimiento de los demás lobos de mar. Quién sabe, ¡puede que les esperen premios increíbles y recompensas gloriosas!",
      "es-l":
        "Sólo los piratas más exitosos se abren camino hasta lo más alto de la clasificación, ganándose el respeto y el reconocimiento de los demás lobos de mar. Quién sabe, ¡puede que les esperen premios increíbles y recompensas gloriosas!",
      id: "Hanya bajak laut yang paling sukses yang berhasil mencapai peringkat teratas, mendapatkan rasa hormat dan pengakuan di antara serigala laut lainnya. Siapa tahu, mungkin ada hadiah yang luar biasa dan penghargaan yang gemilang di depan mereka!",
      ["pt-br"]:
        "Somente os piratas mais bem-sucedidos chegam ao topo da classificação, ganhando respeito e reconhecimento entre outros lobos do mar. Quem sabe, pode haver prêmios incríveis e recompensas gloriosas à sua frente!",
    },
  Задания: {
    ru: "Задания",
    en: "Quests",
    fr: "Quêtes",
    es: "Tareas",
    "es-l": "Tareas",
    id: "Tugas",
    ["pt-br"]: "Tarefas",
  },
  "В нашей игре доступны простые задания, выполняя которые вы сможете зарабатывать жемчуг и золото, пополняя свои сокровищницы и приближаясь к вершинам пиратской славы!":
    {
      ru: "В нашей игре доступны простые задания, выполняя которые вы сможете зарабатывать жемчуг и золото, пополняя свои сокровищницы и приближаясь к вершинам пиратской славы!",
      en: "Simple quests are available in our game, allowing you to earn pearls and gold, filling your treasures and climbing to the heights of pirate glory!",
      fr: "Des quizz simples sont disponibles dans notre jeu, vous permettant de gagner de la perles et d'or, de remplir vos treasures et de monter aux hauteurs de la gloire pirate!",
      es: "Las misteriosas misiones son disponibles en nuestro juego, permitiendo que usted gane perlas y oro, rellenando sus tesoros y escalando hacia los altos de la gloria pirata!",
      "es-l":
        "Las misteriosas misiones son disponibles en nuestro juego, permitiendo que usted gane perlas y oro, rellenando sus tesoros y escalando hacia los altos de la gloria pirata!",
      id: "Tugas sederhana tersedia di game kami, memungkinkan Anda untuk mendapatkan perunggu dan emas, mengisi tajukmu dan mencapai tingkat teratas dari gloria pirata!",
      ["pt-br"]:
        "Nosso jogo apresenta tarefas simples que você pode concluir para ganhar pérolas e ouro, reabastecer seus tesouros e se aproximar das alturas da glória pirata!",
    },
  Салун: {
    ru: "Салун",
    en: "Saloon",
    fr: "Salon",
    es: "Salon",
    "es-l": "Salon",
    id: "Salon",
    ["pt-br"]: "Salão",
  },
  Мастерскую: {
    ru: "Мастерскую",
    en: "Workshop",
    fr: "Atelier",
    es: "Taller",
    "es-l": "Taller",
    id: "Toko",
    ["pt-br"]: "Oficina",
  },
  Форт: {
    ru: "Форт",
    en: "Fort",
    fr: "Fort",
    es: "Fuerza",
    "es-l": "Fuerza",
    id: "Peta",
    ["pt-br"]: "Fortaleza",
  },
  Магазин: {
    ru: "Магазин",
    en: "Shop",
    fr: "Magasin",
    es: "Tienda",
    "es-l": "Tienda",
    id: "Toko",
    ["pt-br"]: "Loja",
  },
  "Опубликуйте историю в Telegram": {
    ru: "Опубликуйте историю в Telegram",
    en: "Publish a story on Telegram",
    fr: "Publiez une histoire sur Telegram",
    es: "Publica una historia en Telegram",
    "es-l": "Publica una historia en Telegram",
    id: "Publikasikan cerita di Telegram",
    ["pt-br"]: "Publicar uma história no Telegram",
  },
  "Зарабатывайте золото": {
    ru: "Зарабатывайте золото",
    en: "Earn gold",
    fr: "Gagnez de l'or",
    es: "Ganar oro",
    "es-l": "Ganar oro",
    id: "Dapatkan emas",
    ["pt-br"]: "Ganhe ouro",
  },
  "от ваших приглашённых друзей": {
    ru: "от ваших приглашённых друзей",
    en: "from your invited friends",
    fr: "de vos amis invités",
    es: "de sus amigos invitados",
    "es-l": "de sus amigos invitados",
    id: "dari teman teman yang diundang",
    ["pt-br"]: "de seus amigos que foram convidados",
  },
  "от их приглашённых друзей": {
    ru: "от их приглашённых друзей",
    en: "from their invited friends",
    fr: "de leurs amis invites",
    es: "de sus amigos invitados",
    "es-l": "de sus amigos invitados",
    id: "dari teman teman yang diundang",
    ["pt-br"]: "de seus amigos que foram convidados",
  },
  "Отправляйся в Airdrop-приключение! 💰 Зарабатывай золото и развивай свою империю. Получи бонус за вход в игру по ссылке":
    {
      ru: "Отправляйся в Airdrop-приключение! 💰 Зарабатывай золото и развивай свою империю. Получи бонус за вход в игру по ссылке",
      en: "Embark on an Airdrop adventure! 💰 Earn gold and expand your empire. Get a bonus by joining the game through the link",
      fr: "Partez à l'aventure de l'Airdrop ! 💰 Gagnez de l'or et développez votre empire. Obtenez un bonus en entrant dans le jeu via ce lien",
      es: "¡Embárcate en una aventura de Airdrop! 💰 Gana oro y expande tu imperio. Obtén un bono al ingresar al juego a través del enlace",
      "es-l":
        "¡Embárcate en una aventura de Airdrop! 💰 Gana oro y expande tu imperio. Obtén un bono al ingresar al juego a través del enlace",
      id: "Mulailah petualangan Airdrop! 💰 Dapatkan emas dan kembangkan kerajaanmu. Dapatkan bonus dengan masuk ke dalam permainan melalui tautan",
      ["pt-br"]:
        "Embarque em uma aventura de Airdrop! 💰 Ganhe ouro e expanda seu império. Receba um bônus ao entrar no jogo através do link",
    },
  "Играй и получи Airdrop и жемчужины!💰 А за каждого приглашенного друга получай Ton, % золота от их заработка и жемчужины!":
    {
      ru: "Играй и получи Airdrop и жемчужины!💰 А за каждого приглашенного друга получай Ton, % золота от их заработка и жемчужины!",
      en: "Play and receive Airdrop and pearls!💰 For each friend you invite, earn Ton, a percentage of gold from their earnings, and pearls!",
      fr: "Jouez et recevez un Airdrop et des perles !💰 Pour chaque ami invité, gagnez du Ton, un pourcentage de l'or de leurs gains, et des perles !",
      es: "¡Juega y recibe Airdrop y perlas!💰 Por cada amigo que invites, gana Ton, un porcentaje de oro de sus ganancias y perlas",
      "es-l":
        "¡Juega y recibe Airdrop y perlas!💰 Por cada amigo que invites, gana Ton, un porcentaje de oro de sus ganancias y perlas",
      id: "Mainkan dan dapatkan Airdrop dan mutiara!💰 Untuk setiap teman yang Anda undang, dapatkan Ton, persentase emas dari penghasilan mereka, dan mutiara!",
      ["pt-br"]:
        "Jogue e receba Airdrop e pérolas!💰 Para cada amigo que você convidar, ganhe Ton, uma porcentagem do ouro de seus ganhos e pérolas",
    },
  "Специальное предложение": {
    ru: "Специальное предложение",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "5 БУСТЕРОВ АвтоСбора": {
    ru: "5 БУСТЕРОВ АвтоСбора",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "25 БУСТЕРОВ АвтоСбора": {
    ru: "25 БУСТЕРОВ АвтоСбора",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "68 БУСТЕРОВ АвтоСбора": {
    ru: "68 БУСТЕРОВ АвтоСбора",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "135 БУСТЕРОВ АвтоСбора": {
    ru: "135 БУСТЕРОВ АвтоСбора",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "345 БУСТЕРОВ АвтоСбора": {
    ru: "345 БУСТЕРОВ АвтоСбора",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "650 БУСТЕРОВ АвтоСбора": {
    ru: "650 БУСТЕРОВ АвтоСбора",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "1 КЛЮЧ": {
    ru: "1 КЛЮЧ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "5 КЛЮЧЕЙ": {
    ru: "5 КЛЮЧЕЙ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "14 КЛЮЧЕЙ": {
    ru: "14 КЛЮЧЕЙ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "70 КЛЮЧЕЙ": {
    ru: "70 КЛЮЧЕЙ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "135 КЛЮЧЕЙ": {
    ru: "135 КЛЮЧЕЙ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "250 ЖЕМЧУГА": {
    ru: "250 ЖЕМЧУГА",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "1250 ЖЕМЧУГА": {
    ru: "1250 ЖЕМЧУГА",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "3406 ЖЕМЧУГА": {
    ru: "3406 ЖЕМЧУГА",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "6816 ЖЕМЧУГА": {
    ru: "6816 ЖЕМЧУГА",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "17420 ЖЕМЧУГА": {
    ru: "17420 ЖЕМЧУГА",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "32825 ЖЕМЧУГА": {
    ru: "32825 ЖЕМЧУГА",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  Удвоение: {
    ru: "Удвоение",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  скидка: {
    ru: "Скидка",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Пригласи друга": {
    ru: "Пригласи друга",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "и получи": {
    ru: "и получи",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "для вас и вашего": {
    ru: "для вас и вашего",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "друга! А за каждого 10-го приглашенного": {
    ru: "друга! А за каждого 10-го приглашенного",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  " друга": {
    ru: " друга",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "для сундука с множителем!": {
    ru: "для сундука с множителем!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Пригласи друга с Telegram Premium": {
    ru: "Пригласи друга с Telegram Premium",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  Ключи: {
    ru: "Ключи",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "30 Жемчуга": {
    ru: "30 Жемчуга",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "50 Жемчуга": {
    ru: "50 Жемчуга",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "для тебя и друга. А за каждого друга с Telegram Premium ты получишь": {
    ru: "для тебя и друга. А за каждого друга с Telegram Premium ты получишь",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Все заработанные тобой жемчужин и ключей можно будет увидеть на странице друзей.":
    {
      ru: "Все заработанные тобой жемчужины и ключи можно будет увидеть на странице друзей.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Использование ботов для накрутки друзей против правил Pirate Empire! ключ начисляются только за реальных друзей. Приведённые боты будут вычитаться из списка приглашённых друзей.":
    {
      ru: "Использование ботов для накрутки друзей против правил Pirate Empire! Ключи начисляются только за реальных друзей. Приведённые боты будут вычитаться из списка приглашённых друзей.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Пригласи друзей и зарабатывай ключ!": {
    ru: "Приглашай друзей и получай ключи!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Специальное предложение!": {
    ru: "Специальное предложение!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Забирайте всё автоматически": {
    ru: "Забирайте всё автоматически",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Ключ позволит открыть сундук и получить множитель х2-х5 для сбора золота и жемчуга!":
    {
      ru: "Ключ позволит открыть сундук и получить множитель х2-х5 для сбора золота и жемчуга!",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "⚡️ Спешите, предложение исчезнет через: ": {
    ru: "⚡️ Спешите, предложение исчезнет через: ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "🏝 Ваши здания переполнены золотом!": {
    ru: "🏝 Ваши здания переполнены золотом!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  " АвтоСбор — это удобство, которое вы заслужили!": {
    ru: " АвтоСбор — это удобство, которое избавит от рутинных действий!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "⚡️ Это ваш последний шанс! После этого предложение исчезнет навсегда.": {
    ru: "⚡️ Это ваш последний шанс! После этого предложение исчезнет навсегда.",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Попробуйте АвтоСбор бесплатно.": {
    ru: "Попробуйте АвтоСбор бесплатно.",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "АвтоСбор автоматически соберет всё золото с ваших зданий 2 раза подряд, экономя время и ресурсы.":
    {
      ru: "АвтоСбор автоматически соберет всё золото с ваших зданий 2 раза подряд, экономя ваше время.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Позвольте игре работать за вас!": {
    ru: " Позвольте игре работать за вас!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "⏳ Ваш множитель закончился!": {
    ru: "⏳ Ваш множитель закончился!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Ключ открывает сундук, из которого вы получите множитель x2-x5": {
    ru: "Ключ открывает сундук, из которого вы получите множитель x2-x5",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Успейте воспользоваться скидкой!": {
    ru: "Успейте воспользоваться скидкой!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "🔥 Последний шанс!": {
    ru: "🔥 Последний шанс!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "2 ключа + 300 жемчуга по цене $0.49!": {
    ru: "2 ключа + 300 жемчуга по цене $0.49!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "🕒 Успейте купить": {
    ru: "🕒 Успейте купить",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "🎁 Подарок для вас!": {
    ru: "🎁 Подарок для вас!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Заберите 1 ключ бесплатно!": {
    ru: "Заберите 1 ключ бесплатно!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Почувствуйте, как удобно открывать сундуки.": {
    ru: "Посмотрите, какую выгоду приносят сундуки.",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "💎 Невероятно! Вы выбили максимальный множитель х5!": {
    ru: "💎 Невероятно! Вы выбили максимальный множитель х5!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Сегодня ваш день, капитан!": {
    ru: "Сегодня ваш день, капитан!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "🔥 Осталось:": {
    ru: "🔥 Осталось:",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "из 3 продлений": {
    ru: "из 3 продлений",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Следующее продление будет стоить ": {
    ru: "Следующее продление будет стоить ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Зовите друзей в Pirate Empire и получайте щедрые награды! За каждого приглашенного друга вы заработаете по 10 жемчуга для вас обоих. Если ваш друг — пользователь Telegram Premium, награда возрастает до 20 жемчуга! Помимо этого, вы будете получать 20% от золота, добытого вашими друзьями, и еще 10% от друзей, которых пригласили они. Бонусы начисляются ежедневно.":
    {
      ru: "Зовите друзей в Pirate Empire и получайте щедрые награды! За каждого приглашенного друга вы заработаете по 30 жемчуга для вас обоих. Если ваш друг — пользователь Telegram Premium, награда возрастает до 50 жемчуга! Помимо этого, вы будете получать 20% от золота, добытого вашими друзьями, и еще 10% от друзей, которых пригласили они. Бонусы начисляются ежедневно.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  'Это бустер "Ключ", он позволяет открывать сундуки, из которых можно получить бустер "Множитель" ':
    {
      ru: 'Это "Ключ", он позволяет открывать сундуки, из которых можно получить бустер "Множитель" ',
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  'Важно знать! Бустеры "Множитель" доступны только в сундуках, они увеличивают добываемое золото и жемчуг':
    {
      ru: 'Важно знать! Бустеры "Множитель" доступны только в сундуках, они увеличивают добываемое золото и жемчуг',
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "🎁 Только сейчас: 2 ключа всего за $0.49 + 300 жемчуга в подарок! 🔑 Ключ открывает сундук, из которого вы получите множитель x2-x5 для сбора золота и жемчуга.":
    {
      ru: "🎁 Только сейчас: 2 ключа всего за $0.49 + 300 жемчуга в подарок! 🔑 Ключ открывает сундук, из которого вы получите множитель x2-x5 для сбора золота и жемчуга.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "⚡ Спешите, предложение исчезнет через:": {
    ru: "⚡ Спешите, предложение исчезнет через:",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "с помощью 10 АвтоСборов и получите 1 ключ для сундука множителя в подарок": {
    ru: "с помощью 10 АвтоСборов и получите 1 ключ для сундука множителя в подарок",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "🎁 Только сейчас:": {
    ru: "🎁 Только сейчас:",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "2 ключа всего за $0.49 + 300 жемчуга в подарок!": {
    ru: "2 ключа всего за $0.49 + 300 жемчуга в подарок!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "🔑 Ключ открывает сундук, из которого вы получите множитель x2-x5 для сбора золота и жемчуга.":
    {
      ru: "🔑 Ключ открывает сундук, из которого вы получите множитель x2-x5 для сбора золота и жемчуга.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "скидка 400%": {
    ru: "скидка 400%",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  Активировать: {
    ru: "Активировать",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "(бесплатно)": {
    ru: "(бесплатно)",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "ПОДАРОК!": {
    ru: "ПОДАРОК!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "🏝️ Ваши здания переполнены золотом!": {
    ru: "🏝️ Ваши здания переполнены золотом!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "⚡ Это ваш последний шанс! После этого предложение исчезнет навсегда.": {
    ru: "⚡ Это ваш последний шанс! После этого предложение исчезнет навсегда.",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Работа автобустеров закончена!": {
    ru: "Работа синхронного автосбора закончена! Было получено на 30% больше ресурсов. Поздравляем!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Вы получили:": {
    ru: "Вы получили:",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  и: {
    ru: "и",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Работает синхронный автосбор": {
    ru: "Работает синхронный автосбор",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Синхронный сбор":{
    ru:"Синхронный сбор",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  КЛЮЧЕЙ: {
    ru: "КЛЮЧЕЙ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "У вас нет автосборов для активации": {
    ru: "У вас нет автосборов для активации",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Автосбор — это инструмент, который экономит ваше время. Активировав его, вы можете быть уверены, что не пропустите момент, когда ваши здания заполнились ресурсами, даже если не успели зайти в игру вовремя.":
    {
      ru: "Автосбор — это инструмент, который экономит ваше время. Активировав его, вы можете быть уверены, что не пропустите момент, когда ваши здания заполнились ресурсами, даже если не успели зайти в игру вовремя.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Для порта автосбор работает в течение 3 часов. Но если вы активируете автосбор на 4 зданиях и порту, сбор ресурсов будет синхронизирован, и вы сможете не переживать за сбор ресурсов в течение целых 9 часов.":
    {
      ru: "Для зданий автосбор работает в течении 6 часов, а для порта в течение 2 часов. Но если вы активируете автосбор на 4 зданиях и порту, сбор ресурсов будет синхронизирован, и вы сможете не переживать за сбор ресурсов в течение целых 9 часов.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Автосбор автоматически соберёт ресурсы и отправит корабль за новыми!": {
    ru: "Автосбор автоматически соберёт ресурсы и отправит корабль за новыми!\n\nТакже при синхронном сборе вы получите на 30% больше ресурсов!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Также при синхронном сборе вы получите на 30% больше ресурсов!":{
    ru:"Также при синхронном сборе вы получите на 30% больше ресурсов!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "В магазин": {
    ru: "В магазин",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Дорогой игрок, вы были заблокированы за многократное нарушение правил": {
    ru: "Дорогой игрок, вы были заблокированы за многократное нарушение правил",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "За опубликованную сторис в Telegram вы получите 50 жемчуга": {
    ru: "За опубликованную сторис в Telegram вы получите 50 жемчуга",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "При публикации не удаляйте ссылку с картинки и из описания, иначе задание не будет засчитано.":
    {
      ru: "При публикации не удаляйте ссылку с картинки и из описания, иначе задание не будет засчитано.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "История должна оставаться активной в течение 24 часов.": {
    ru: "История должна оставаться активной в течение 24 часов.",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Проверка выполнения задания осуществляется ежедневно. За многократное нарушение мы можем заблокировать аккаунт. Выполняйте задание честно!":
    {
      ru: "Проверка выполнения задания осуществляется ежедневно. За многократное нарушение мы можем заблокировать аккаунт. Выполняйте задание честно!",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  ПОДЕЛИТЬСЯ: {
    ru: "ПОДЕЛИТЬСЯ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "* После этого вернитесь в приложение и отметьте задание как выполненное": {
    ru: "* После этого вернитесь в приложение и отметьте задание как выполненное",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Если вы опубликовали историю без удаления ссылок, вы можете подтвердить выполнение задания, и вам начислится награда. Через 24 часа можно повторить задание!":
    {
      ru: "Если вы опубликовали историю без удаления ссылок, вы можете подтвердить выполнение задания, и вам начислится награда. Через 24 часа можно повторить задание!",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Подвердите выполнение задание и вам начислиться награда!": {
    ru: "Подвердите выполнение задание и вам начислиться награда!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  Подтверждение: {
    ru: "Подтверждение",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  Подтвердить: {
    ru: "Подтвердить",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Приглашайте друзей и зарабатывайте!": {
    ru: "Приглашайте друзей и получайте награды",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "За каждого приглашенного друга вы получите 30 жемчуга — как для себя, так и для друга. За каждого друга с Telegram Premium вы получите 50 жемчуга.":
    {
      ru: "За каждого приглашенного друга вы получите 30 жемчуга — как для себя, так и для друга. За каждого друга с Telegram Premium вы получите 50 жемчуга.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "За каждого 10-го друга вы будете награждены ключом для сундука с множителем, который увеличит добычу ресурсов в 2-5 раз!":
    {
      ru: "За каждого 10-го друга вы будете награждены ключом для сундука с множителем, который увеличит добычу ресурсов в 2-5 раз!",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Весь заработанный жемчуг и ключи будут отображаться на странице друзей. Чтобы перевести их на основной баланс, необходимо нажать кнопку для снятия.":
    {
      ru: "Весь заработанный жемчуг и ключи будут отображаться на странице друзей. Чтобы перевести их на основной баланс, необходимо нажать кнопку для снятия.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Использование ботов для накрутки друзей противоречит правилам Pirate Empire! Ключи и жемчуг начисляются только за реальных друзей. Боты будут исключены из списка приглашенных друзей.":
    {
      ru: "Использование ботов для накрутки друзей противоречит правилам Pirate Empire! Ключи и жемчуг начисляются только за реальных друзей. Боты будут исключены из списка приглашенных друзей.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "Мы внимательно следим за рефералами, которых вы приглашаете, используя несколько объективных показателей, которые мы не раскрываем. Мы настоятельно рекомендуем не использовать неактивных рефералов или ботов, так как за таких пользователей награды не будут начисляться.":
    {
      ru: "Мы внимательно следим за рефералами, которых вы приглашаете, используя несколько объективных показателей, которые мы не раскрываем. Мы настоятельно рекомендуем не использовать неактивных рефералов или ботов, так как за таких пользователей награды не будут начисляться.",
      en: "",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      ["pt-br"]: "",
    },
  "При многократном нарушении правил мы имеем право заблокировать аккаунт!": {
    ru: "При многократном нарушении правил мы имеем право заблокировать аккаунт!",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "10 БУСТЕРОВ + КЛЮЧ": {
    ru: "10 бустеров + ключ",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "За опубликованную сторис в Telegram вы":{
    ru:"За опубликованную сторис в Telegram вы",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "получите 50 жемчуга":{
    ru:"получите 50",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Сундук Юнги": {
    ru: "Сундук Юнги",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Сундук Капитана": {
    ru: "Сундук Капитана",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Сундук Матроса": {
    ru: "Сундук Матроса",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
  "Сундук Адмирала": {
    ru: "Сундук Адмирала",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    ["pt-br"]: "",
  },
};

export const LANGUAGE = "Language";

export const getTranslation = (
  key: string,
  language?: keyof typeof LANGUAGES
): string => {
  const lang =
    language || (getLocalStorageItem(LANGUAGE) as keyof typeof LANGUAGES);
  return translations[key]?.[lang] || key;
};

export const getTranslationPhoto = (
  image: StaticImageData
): StaticImageData => {
  const lang = getLocalStorageItem(LANGUAGE);

  switch (image) {
    case tg:
      switch (lang) {
        case LANGUAGES.ru:
          return tg;
        case LANGUAGES.en:
          return tg_en;
        case LANGUAGES.fr:
          return tg_fr;
        case LANGUAGES.es:
          return tg_es;
        case LANGUAGES["es-l"]:
          return tg_es;
        case LANGUAGES.id:
          return tg_id;
        case LANGUAGES["pt-br"]:
          return tg_pt;
        default:
          return tg;
      }
    case tgPremium:
      switch (lang) {
        case LANGUAGES.ru:
          return tgPremium;
        case LANGUAGES.en:
          return tgPremium_en;
        case LANGUAGES.fr:
          return tgPremium_fr;
        case LANGUAGES.es:
          return tgPremium_es;
        case LANGUAGES["es-l"]:
          return tgPremium_es;
        case LANGUAGES.id:
          return tgPremium_id;
        case LANGUAGES["pt-br"]:
          return tgPremium_pt;
        default:
          return tgPremium;
      }
    case islandSoon:
      switch (lang) {
        case LANGUAGES.ru:
          return islandSoon;
        case LANGUAGES.en:
          return islandSoon_en;
        case LANGUAGES.fr:
          return islandSoon_fr;
        case LANGUAGES.es:
          return islandSoon_es;
        case LANGUAGES["es-l"]:
          return islandSoon_es;
        case LANGUAGES.id:
          return islandSoon_id;
        case LANGUAGES["pt-br"]:
          return islandSoon_pt;
        default:
          return islandSoon;
      }
    case prizeFond:
      switch (lang) {
        case LANGUAGES.ru:
          return prizeFond;
        case LANGUAGES.en:
          return prizeFond_en;
        case LANGUAGES.fr:
          return prizeFond;
        case LANGUAGES.es:
          return prizeFond;
        case LANGUAGES["es-l"]:
          return prizeFond;
        case LANGUAGES.id:
          return prizeFond;
        case LANGUAGES["pt-br"]:
          return prizeFond;
        default:
          return prizeFond;
      }
    default:
      return tg;
  }
};

export const getLanguageBySlug = (lang: string): string => {
  switch (lang) {
    case LANGUAGES.en:
      return "EN";
    case LANGUAGES.ru:
      return "РУ";
    case LANGUAGES.fr:
      return "FR";
    case LANGUAGES.es:
      return "ES";
    case LANGUAGES["es-l"]:
      return "ES-L";
    case LANGUAGES.id:
      return "ID";
    case LANGUAGES["pt-br"]:
      return "PT";
    default:
      return "РУ";
  }
};
