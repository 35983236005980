export const HOUSES = [
  {
    id: 1,
    house_type_name: "dock",
    level: 0,
    resource_amount_gold: 20,
    is_building: false,
    boost_time_remaining: 0,
    max_level: 5,
    images: {
      default: require("@/assets/buildings/dock/default/dock_lvl_5.png")
        .default,
      select: require("@/assets/buildings/dock/select/dock_lvl_5_select.png")
        .default,
    },
    coordinates: {
      x: 1,
      y: 2,
    },
  },
  {
    id: 2,
    house_type_name: "fort",
    level: 0,
    resource_amount_gold: 20,
    is_building: false,
    boost_time_remaining: 0,
    max_level: 5,
    images: {
      default: require("@/assets/buildings/fort/default/fort_lvl_3.png")
        .default,
      select: require("@/assets/buildings/fort/select/fort_lvl_3_select.png")
        .default,
    },
    coordinates: {
      x: 1,
      y: 2,
    },
  },
  {
    id: 3,
    house_type_name: "pearl",
    level: 0,
    resource_amount_gold: 20,
    is_building: false,
    boost_time_remaining: 0,
    max_level: 5,
    images: {
      default: require("@/assets/buildings/pearl/default/pearl_lvl_5.png")
        .default,
      select: require("@/assets/buildings/pearl/select/pearl_lvl_5_select.png")
        .default,
    },
    coordinates: {
      x: 1,
      y: 2,
    },
  },
  {
    id: 4,
    house_type_name: "sale",
    level: 0,
    resource_amount_gold: 20,
    is_building: false,
    boost_time_remaining: 0,
    max_level: 5,
    images: {
      default: require("@/assets/buildings/sale/default/sale_lvl_5.png")
        .default,
      select: require("@/assets/buildings/sale/select/sale_lvl_5_select.png")
        .default,
    },
    coordinates: {
      x: 1,
      y: 2,
    },
  },
  {
    id: 5,
    house_type_name: "saloon",
    level: 0,
    resource_amount_gold: 20,
    is_building: false,
    boost_time_remaining: 0,
    max_level: 5,
    images: {
      default: require("@/assets/buildings/saloon/default/saloon_lvl_5.png")
        .default,
      select:
        require("@/assets/buildings/saloon/select/saloon_lvl_5_select.png")
          .default,
    },
    coordinates: {
      x: 1,
      y: 2,
    },
  },
];


