import { RootState } from "@/store";
import Image from "next/image";
import { getLanguageBySlug, getTranslation, LANGUAGE } from "@/utils/location";
import { setLanguage } from "@/store/app/appSlice";
import { setLocalStorageItem } from "@/utils/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { LANGUAGES } from "@/constants/types";
import { useState } from "react";
import arrowDown from "@/assets/icons/arrow_down.png";

import styles from "./styles.module.scss";

export default function Selector() {
  const [show, setShow] = useState(false);
  const language = useSelector((state: RootState) => state.app.language);

  const handleShowOptions = () => {
    setShow((prev) => !prev);
  };

  return (
    <div className={styles.selector__wrapper}>
      <span className={styles.selector__title}>{getTranslation("ЯЗЫК")}</span>
      <div className={styles.selector__container}>
        <div
          onClick={handleShowOptions}
          className={styles.selector__choose__container}
        >
          <span className={styles.selector__text}>
            {getLanguageBySlug(language)}
          </span>
          <Image
            src={arrowDown}
            className={show ? styles.rotate : ""}
            alt="arrow down"
            width={18}
          />
        </div>
      </div>
      {show && (
        <div className={styles.selector__container__options}>
          {Object.keys(LANGUAGES).map((lang) => {
            return <OptionCard lang={lang} />;
          })}
        </div>
      )}
    </div>
  );
}

const OptionCard = ({ lang }: { lang: string }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setLanguage(lang));
    setLocalStorageItem(LANGUAGE, lang);
  };

  return (
    <div onClick={handleClick} className={styles.card__wrapper}>
      <span>{getLanguageBySlug(lang)}</span>
    </div>
  );
};
