import { createSlice } from "@reduxjs/toolkit";
import { IHudState } from "./interfaces";

const initialState: IHudState = {
  currentItemMenu: "Меню",
};

export const hudSlice = createSlice({
  name: "hud",
  initialState,
  reducers: {
    setCurrentItemMenu: (state, action) => {
      state.currentItemMenu = action.payload;
    },
  },
});

export const { setCurrentItemMenu } = hudSlice.actions;
export default hudSlice.reducer;
