import { COST_TO_UPGRADE } from "@/constants/buildings";
import { ICONS } from "@/constants/icons";
import { POP_UP_TEXT } from "@/constants/popUp";

export const ACTIONS = {
  ERROR: "error",
  PRIZE_FIRST_IN: "prize_first_in",
  PRIZE_CHEST: "prize_chest",
  BUY: "buy",
  SHIP_TRAVELED: "ship_traveled",
  BUILD_HOUSE: "build_house",
  SETTINGS:'settings'
};

export const getPopUpDataByNames = (name: string) => {
  switch (name) {
    case ICONS.UPGRADE:
      return POP_UP_TEXT[0];
    case ICONS.PRIZE:
      return POP_UP_TEXT[2];
    case ACTIONS.ERROR:
      return POP_UP_TEXT[2];
    case ACTIONS.PRIZE_FIRST_IN:
      return POP_UP_TEXT[1];
    case ACTIONS.PRIZE_CHEST:
      return POP_UP_TEXT[4];
    case ACTIONS.BUY:
      return POP_UP_TEXT[5];
    case ACTIONS.SHIP_TRAVELED:
      return POP_UP_TEXT[6];
    case ACTIONS.BUILD_HOUSE:
      return POP_UP_TEXT[7];
    case ACTIONS.SETTINGS:
      return POP_UP_TEXT[8]
    default:
      return POP_UP_TEXT[0];
  }
};

export const getCostToUpgrade = (level?: number) => {
  if (!level) return { coins: 0, pearls: 0 };

  if (level == 0) {
    return { coins: 0, pearls: 0 };
  }
  return COST_TO_UPGRADE[level + 1] || { coins: 0, pearls: 0 };
};
