export const LOCAL_STORAGE = {
  USER_ID: "user_id",
  TOKEN: "token",
  REF: "ref",
  CHAT_ID: "chat_id",
  USERNAME: "username",
  PREMIUM: "is_premium",
  IS_TRAVELING: "isTraveling",
  REMAINING_TIME: "remainingTime",
  LEAGUE: "league",
  WALLET: "wallet",
};

export const TUTORIALS = {
  MAIN: "main",
  SHOP: "shop",
  FRIENDS: "friends",
  CHESTS: "chests",
};
