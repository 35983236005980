import upgrade from "@/assets/icons/upgrade.png";
import warning from "@/assets/icons/warning.png";
import buster from "@/assets/icons/buster.png";

export const POP_UP_TEXT = [
  {
    title: "УЛУЧШЕНИЕ",
    textButton: "Улучшить",
    text: `УЛУЧШЕНИЕ ЗДАНИЯ ДОБЫЧИ МОНЕТ`,
    icon: upgrade,
    values: {
      textValue: "Требуется:",
      values: {
        gold: 240000,
        pearl: 100,
      },
    },
  },
  {
    title: "ПРИВЕТСТВИЕ",
    textButton: "Забрать",
    text: `ДОБРО ПОЖАЛОВАТЬ В ИГРУ. ВОТ ВАШ ПОДАРОК!`,
    values: {
      textValue: "Подарок:",
      values: {
        gold: 140000,
        pearl: 100,
      },
    },
  },
  {
    title: "ОШИБКА!",
    textButton: "Переподключится",
    text: `ОШИБКА СОЕДИНЕНИЯ! ПОЖАЛУЙСТА ПЕРЕЗАЙДИТЕ!`,
    icon: warning,
  },
  {
    title: "БУСТЕРЫ",
    textButton: "Использовать",
    text: `ВЫ УВЕРЕНЫ, ЧТО ГОТОВЫ ПРИМЕНИТЬ БУСТЕР?`,
    icon: upgrade,
  },
  {
    title: "НАГРАДА",
    textButton: "Забрать",
    text: "ВЫ ПОЛУЧИЛИ НАГРАДУ ЗА ОТКРЫТИЕ СУНДУКА!",
    icon: buster,
  },
  {
    title: "ПОКУПКА",
    textButton: "Забрать",
    text: "ВЫ УСПЕШНО ПРИОБРЕЛИ ",
    icon: buster,
  },
  {
    title: "НАГРАДА",
    textButton: "Забрать",
    text: `КОРАБЛЬ УСПЕШНО ВЕРНУЛСЯ И ЗАРАБОТАЛ СЛЕДУЮЩУЮ НАГРАДУ!`,
    values: {
      textValue: "Награда:",
      values: {
        gold: 140000,
        pearl: 100,
      },
    },
  },
  {
    title: "ПОСТРОЙКА",
    textButton: "Построить",
    text: `ПОСТРОЙКА ЗДАНИЯ ДОБЫЧИ МОНЕТ`,
    icon: upgrade,
    values: {
      textValue: "Требуется:",
      values: {
        gold: 0,
        pearl: 0,
      },
    },
  },
  {
    title: "НАСТРОЙКИ",
    textButton: "",
    text: ``,
    icon: upgrade,
    values: {
      textValue: "",
      values: {
        gold: 0,
        pearl: 0,
      },
    },
  },
];
