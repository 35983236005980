import { parseCookies, setCookie, destroyCookie } from 'nookies';

export const getCookie = (name: string) => {
  const cookies = parseCookies();
  return cookies[name];
};

export const setCookieValue = (name: string, value: string , options = {}) => {
  setCookie(null, name, value, options);
};

export const deleteCookie = (name: string) => {
  destroyCookie(null, name);
};
