export const setLocalStorageItem = (key: string, value: any) => {
  try {
    const jsonString = JSON.stringify(value);
    localStorage.setItem(key, jsonString);
  } catch (error) {
    console.error(`Ошибка при сохранении в localStorage: ${error}`);
  }
};

export const getLocalStorageItem = (key: string) => {
  try {
    const jsonString = localStorage.getItem(key);
    return jsonString ? JSON.parse(jsonString) : null;
  } catch (error) {
    console.error(`Ошибка при получении из localStorage: ${error}`);
    return null;
  }
};

export const removeLocalStorageItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Ошибка при удалении из localStorage: ${error}`);
  }
};

export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error(`Ошибка при очистке localStorage: ${error}`);
  }
};

export const hasLocalStorageItem = (key: string) => {
  return localStorage.getItem(key) !== null;
};
